@charset "UTF-8";
/* ------------------------------------------ */
/* Style naming basically follows BEM: http://getbem.com/ */
/* ------------------------------------------ */
/* Preload Images */
/* ------------------------------------------ */
body:after {
  /*
    display:none;
    content:
        url(../images/common/icons/svg-icons/blue/icon-loc.svg)
        url(../images/common/icons/svg-icons/blue/icon-social-fb.svg)
        url(../images/common/icons/svg-icons/blue/icon-social-ig.svg)
        url(../images/common/icons/svg-icons/blue/icon-social-wb.svg)
        url(../images/common/icons/svg-icons/blue/icon-social-yt.svg)
        
        url(../images/common/icons/svg-icons/gold/icon-loc.svg)
        url(../images/common/icons/svg-icons/gold/icon-social-fb.svg)
        url(../images/common/icons/svg-icons/gold/icon-social-ig.svg)
        url(../images/common/icons/svg-icons/gold/icon-social-wb.svg)
        url(../images/common/icons/svg-icons/gold/icon-social-yt.svg)
        ;
    */ }

/* END Preload Images */
/* ------------------------------------------ */
/* BEGIN Light */
@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Light/Roboto-Light.woff2?v=2.137") format("woff2"), url("./assets/fonts/Roboto/Light/Roboto-Light.woff?v=2.137") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Light/Roboto-Light.woff2?v=2.137") format("woff2"), url("./assets/fonts/Roboto/Light/Roboto-Light.woff?v=2.137") format("woff");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Light/Roboto-Light.woff2?v=2.137") format("woff2"), url("./assets/fonts/Roboto/Light/Roboto-Light.woff?v=2.137") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Light/Roboto-Light.woff2?v=2.137") format("woff2"), url("./assets/fonts/Roboto/Light/Roboto-Light.woff?v=2.137") format("woff");
  font-weight: light;
  font-style: normal; }

/* END Light */
/* BEGIN Regular */
@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Regular/Roboto-Regular.woff2?v=2.137") format("woff2"), url("./assets/fonts/Roboto/Regular/Roboto-Regular.woff?v=2.137") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Regular/Roboto-Regular.woff2?v=2.137") format("woff2"), url("./assets/fonts/Roboto/Regular/Roboto-Regular.woff?v=2.137") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Regular/Roboto-Regular.woff2?v=2.137") format("woff2"), url("./assets/fonts/Roboto/Regular/Roboto-Regular.woff?v=2.137") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Regular/Roboto-Regular.woff2?v=2.137") format("woff2"), url("./assets/fonts/Roboto/Regular/Roboto-Regular.woff?v=2.137") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Regular/Roboto-Regular.woff2?v=2.137") format("woff2"), url("./assets/fonts/Roboto/Regular/Roboto-Regular.woff?v=2.137") format("woff");
  font-weight: normal;
  font-style: normal; }

/* END Regular */
/* BEGIN Black */
@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Black/Roboto-Black.woff2?v=2.137") format("woff2"), url("./assets/fonts/Roboto/Black/Roboto-Black.woff?v=2.137") format("woff");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Black/Roboto-Black.woff2?v=2.137") format("woff2"), url("./assets/fonts/Roboto/Black/Roboto-Black.woff?v=2.137") format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Black/Roboto-Black.woff2?v=2.137") format("woff2"), url("./assets/fonts/Roboto/Black/Roboto-Black.woff?v=2.137") format("woff");
  font-weight: bold;
  font-style: normal; }

/* END Black */
/* Style commonly used  */
/* ------------------------------------------ */
body,
body * {
  max-height: 1000000px; }

html {
  font-size: 16px;
  /*
    @media screen and (max-width:1580px) {
        font-size:15px;
    }
    @media screen and (max-width:991px) {
        font-size:14px;
    }
    @media screen and (max-width:767px) {
        font-size:13px;
    }
    */ }

body {
  font-size: 16px;
  /*
    @media screen and (max-width:1580px) {
        font-size:15px;
    }
    @media screen and (max-width:991px) {
        font-size:14px;
    }
    @media screen and (max-width:767px) {
        font-size:13px;
    }
    */
  font-family: "Roboto", 微軟雅黑體, "Microsoft JhengHei", 微軟正黑體, Arial, sans-serif;
  margin: 0 !important;
  padding: 0;
  background: #FFF;
  color: #1a1a1a;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  min-width: 300px; }
  body:lang(zh-hk) {
    font-family: "Roboto", 微軟雅黑體, "Microsoft JhengHei", 微軟正黑體, Arial, sans-serif; }
  body:lang(zh-cn) {
    font-family: "Roboto", 微軟雅黑體, "Microsoft JhengHei", 微軟正黑體, Arial, sans-serif; }
  body.dt-only {
    min-width: 992px; }
  body.search-panel-open, body.mob-menu-open, body.scroll-locked {
    overflow: hidden !important; }
    body.search-panel-open #root, body.mob-menu-open #root, body.scroll-locked #root {
      overflow: scroll; }
  @media screen and (max-width: 991px) {
    body.mob-menu-open {
      overflow: hidden; } }
  @media screen and (max-width: 991px) {
    body {
      overflow-x: auto; } }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

a {
  color: #1a1a1a;
  text-decoration: none; }
  a:hover, a:active {
    color: #1a1a1a;
    opacity: 0.8;
    text-decoration: none; }
  a:focus {
    text-decoration: none;
    outline: none; }

img {
  image-rendering: -webkit-optimize-contrast;
  max-width: 100%;
  height: auto; }

br {
  display: block;
  opacity: 0;
  font-family: "Arial", sans-serif !important;
  font-size: 0;
  color: transparent;
  line-height: 1em; }

p {
  font-size: 16px;
  /*
    @media screen and (max-width:1580px) {
        font-size:15px;
    }
    @media screen and (max-width:991px) {
        font-size:14px;
    }
    @media screen and (max-width:767px) {
        font-size:13px;
    }
    */
  margin-top: 0;
  margin-bottom: 20px; }
  p:last-child {
    margin-bottom: 0; }

ul,
ol {
  padding-left: 30px;
  margin-top: 0;
  margin-bottom: 20px; }
  ul:last-child,
  ol:last-child {
    margin-bottom: 0; }
  ul:last-child,
  ol:last-child {
    margin-bottom: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", 微軟雅黑體, "Microsoft JhengHei", 微軟正黑體, Arial, sans-serif;
  font-weight: bold;
  margin-top: 0; }

.title-xxl {
  font-weight: bold;
  font-family: "Roboto", 微軟雅黑體, "Microsoft JhengHei", 微軟正黑體, Arial, sans-serif;
  line-height: 1em;
  font-size: 78px; }
  @media screen and (max-width: 1580px) {
    .title-xxl {
      font-size: 64px; } }
  @media screen and (max-width: 991px) {
    .title-xxl {
      font-size: 56px; } }
  @media screen and (max-width: 640px) {
    .title-xxl {
      font-size: 40px; } }

.title-xl, .main-banner__title {
  font-weight: bold;
  font-family: "Roboto", 微軟雅黑體, "Microsoft JhengHei", 微軟正黑體, Arial, sans-serif;
  line-height: 1em;
  font-size: 73px; }
  @media screen and (max-width: 1580px) {
    .title-xl, .main-banner__title {
      font-size: 64px; } }
  @media screen and (max-width: 991px) {
    .title-xl, .main-banner__title {
      font-size: 52px; } }
  @media screen and (max-width: 640px) {
    .title-xl, .main-banner__title {
      font-size: 36px; } }

.title-lv1, .header-search__title, .mf-detail__intro-title {
  font-weight: bold;
  font-family: "Roboto", 微軟雅黑體, "Microsoft JhengHei", 微軟正黑體, Arial, sans-serif;
  font-size: 60px; }
  @media screen and (max-width: 1580px) {
    .title-lv1, .header-search__title, .mf-detail__intro-title {
      font-size: 44px; } }
  @media screen and (max-width: 991px) {
    .title-lv1, .header-search__title, .mf-detail__intro-title {
      font-size: 42px; } }
  @media screen and (max-width: 767px) {
    .title-lv1, .header-search__title, .mf-detail__intro-title {
      font-size: 32px; } }
  @media screen and (max-width: 350px) {
    .title-lv1, .header-search__title, .mf-detail__intro-title {
      font-size: 28px; } }

.title-lv2, .app-sec__big-title, .market-focus__intro-title, .sec-intro__title, .side-tabs__title, .success-stories__title, .home-mission-container h4, .home-pledge-content .right-panel h4, .home-network-container .left-panel .left-content h4, .home-what-we-do .right-panel h4, .home-corporate-information .top-panel h4, .our-mission-container h4, .ourwork-head__items__title, .corporate-information .top-panel h4, .company-whatwedo-container h4 {
  font-family: "Roboto", 微軟雅黑體, "Microsoft JhengHei", 微軟正黑體, Arial, sans-serif;
  font-weight: bold;
  font-size: 48px; }
  @media screen and (max-width: 1580px) {
    .title-lv2, .app-sec__big-title, .market-focus__intro-title, .sec-intro__title, .side-tabs__title, .success-stories__title, .home-mission-container h4, .home-pledge-content .right-panel h4, .home-network-container .left-panel .left-content h4, .home-what-we-do .right-panel h4, .home-corporate-information .top-panel h4, .our-mission-container h4, .ourwork-head__items__title, .corporate-information .top-panel h4, .company-whatwedo-container h4 {
      font-size: 42px; } }
  @media screen and (max-width: 991px) {
    .title-lv2, .app-sec__big-title, .market-focus__intro-title, .sec-intro__title, .side-tabs__title, .success-stories__title, .home-mission-container h4, .home-pledge-content .right-panel h4, .home-network-container .left-panel .left-content h4, .home-what-we-do .right-panel h4, .home-corporate-information .top-panel h4, .our-mission-container h4, .ourwork-head__items__title, .corporate-information .top-panel h4, .company-whatwedo-container h4 {
      font-size: 36px; } }
  @media screen and (max-width: 640px) {
    .title-lv2, .app-sec__big-title, .market-focus__intro-title, .sec-intro__title, .side-tabs__title, .success-stories__title, .home-mission-container h4, .home-pledge-content .right-panel h4, .home-network-container .left-panel .left-content h4, .home-what-we-do .right-panel h4, .home-corporate-information .top-panel h4, .our-mission-container h4, .ourwork-head__items__title, .corporate-information .top-panel h4, .company-whatwedo-container h4 {
      font-size: 32px; } }

.title-lv3, .hktdc-events__title, .mob-lang__headlink, .nav-link__subholder#mob-share .button-share__title, .search-panel__title, .home-business-container .home-mean-business h4, .home-business-container .home-career h4, .ourwork-head__item__title, .ourwork-content__title, .company-banner-container .left-panel h4, .item-content .item-content__title {
  font-family: "Roboto", 微軟雅黑體, "Microsoft JhengHei", 微軟正黑體, Arial, sans-serif;
  font-weight: bold;
  font-size: 42px; }
  @media screen and (max-width: 1580px) {
    .title-lv3, .hktdc-events__title, .mob-lang__headlink, .nav-link__subholder#mob-share .button-share__title, .search-panel__title, .home-business-container .home-mean-business h4, .home-business-container .home-career h4, .ourwork-head__item__title, .ourwork-content__title, .company-banner-container .left-panel h4, .item-content .item-content__title {
      font-size: 32px; } }
  @media screen and (max-width: 991px) {
    .title-lv3, .hktdc-events__title, .mob-lang__headlink, .nav-link__subholder#mob-share .button-share__title, .search-panel__title, .home-business-container .home-mean-business h4, .home-business-container .home-career h4, .ourwork-head__item__title, .ourwork-content__title, .company-banner-container .left-panel h4, .item-content .item-content__title {
      font-size: 28px; } }
  @media screen and (max-width: 640px) {
    .title-lv3, .hktdc-events__title, .mob-lang__headlink, .nav-link__subholder#mob-share .button-share__title, .search-panel__title, .home-business-container .home-mean-business h4, .home-business-container .home-career h4, .ourwork-head__item__title, .ourwork-content__title, .company-banner-container .left-panel h4, .item-content .item-content__title {
      font-size: 24px; } }

.title-lv4, .app-sec__title, .footer-bar__title, .hktdc-events__tag, .hktdc-events__list-title, .market-focus__title, .sec-intro__detail-title, .side-tabs__item-title, .submenu__intro-title, .home-what-we-do .right-panel .subTitle, .home-corporate-information .bottom-panel .left-panel .annualreport-item-content .title,
.home-corporate-information .bottom-panel .right-panel .annualreport-item-content .title, .ourwork-item__title, .corporate-information .bottom-panel .left-panel .annualreport-item-content .title,
.corporate-information .bottom-panel .right-panel .annualreport-item-content .title, .page-hktdc-events__title, .events-list-title, .more-events-list-title {
  font-family: "Roboto", 微軟雅黑體, "Microsoft JhengHei", 微軟正黑體, Arial, sans-serif;
  font-weight: bold;
  font-size: 30px; }
  @media screen and (max-width: 1580px) {
    .title-lv4, .app-sec__title, .footer-bar__title, .hktdc-events__tag, .hktdc-events__list-title, .market-focus__title, .sec-intro__detail-title, .side-tabs__item-title, .submenu__intro-title, .home-what-we-do .right-panel .subTitle, .home-corporate-information .bottom-panel .left-panel .annualreport-item-content .title,
    .home-corporate-information .bottom-panel .right-panel .annualreport-item-content .title, .ourwork-item__title, .corporate-information .bottom-panel .left-panel .annualreport-item-content .title,
    .corporate-information .bottom-panel .right-panel .annualreport-item-content .title, .page-hktdc-events__title, .events-list-title, .more-events-list-title {
      font-size: 24px; } }
  @media screen and (max-width: 991px) {
    .title-lv4, .app-sec__title, .footer-bar__title, .hktdc-events__tag, .hktdc-events__list-title, .market-focus__title, .sec-intro__detail-title, .side-tabs__item-title, .submenu__intro-title, .home-what-we-do .right-panel .subTitle, .home-corporate-information .bottom-panel .left-panel .annualreport-item-content .title,
    .home-corporate-information .bottom-panel .right-panel .annualreport-item-content .title, .ourwork-item__title, .corporate-information .bottom-panel .left-panel .annualreport-item-content .title,
    .corporate-information .bottom-panel .right-panel .annualreport-item-content .title, .page-hktdc-events__title, .events-list-title, .more-events-list-title {
      font-size: 20px; } }
  @media screen and (max-width: 640px) {
    .title-lv4, .app-sec__title, .footer-bar__title, .hktdc-events__tag, .hktdc-events__list-title, .market-focus__title, .sec-intro__detail-title, .side-tabs__item-title, .submenu__intro-title, .home-what-we-do .right-panel .subTitle, .home-corporate-information .bottom-panel .left-panel .annualreport-item-content .title,
    .home-corporate-information .bottom-panel .right-panel .annualreport-item-content .title, .ourwork-item__title, .corporate-information .bottom-panel .left-panel .annualreport-item-content .title,
    .corporate-information .bottom-panel .right-panel .annualreport-item-content .title, .page-hktdc-events__title, .events-list-title, .more-events-list-title {
      font-size: 20px; } }

.title-lv5, .app-intro__title, .feature-listing__item-big-title, .feature-listing__popcap-title, .mf-detail__quicklinks-title, .organic-listing__item-big-title, .organic-listing__popcap-title, .organic-listing__more-link, .search-nores__txt, .sec-intro__detail-subtitle, .submenu__event-title, .item-content .item-tips .item-tips__title, .item-links .item-links__title, .child-item-container .child-item__title, .item-sliders .item-slider-container .item-slider-text .item-slider__title, .story-filter__title, .story-list .story-title, .events-filter .events-filter__title {
  font-family: "Roboto", 微軟雅黑體, "Microsoft JhengHei", 微軟正黑體, Arial, sans-serif;
  font-weight: bold;
  font-size: 20px; }
  @media screen and (max-width: 1580px) {
    .title-lv5, .app-intro__title, .feature-listing__item-big-title, .feature-listing__popcap-title, .mf-detail__quicklinks-title, .organic-listing__item-big-title, .organic-listing__popcap-title, .organic-listing__more-link, .search-nores__txt, .sec-intro__detail-subtitle, .submenu__event-title, .item-content .item-tips .item-tips__title, .item-links .item-links__title, .child-item-container .child-item__title, .item-sliders .item-slider-container .item-slider-text .item-slider__title, .story-filter__title, .story-list .story-title, .events-filter .events-filter__title {
      font-size: 18px; } }
  @media screen and (max-width: 991px) {
    .title-lv5, .app-intro__title, .feature-listing__item-big-title, .feature-listing__popcap-title, .mf-detail__quicklinks-title, .organic-listing__item-big-title, .organic-listing__popcap-title, .organic-listing__more-link, .search-nores__txt, .sec-intro__detail-subtitle, .submenu__event-title, .item-content .item-tips .item-tips__title, .item-links .item-links__title, .child-item-container .child-item__title, .item-sliders .item-slider-container .item-slider-text .item-slider__title, .story-filter__title, .story-list .story-title, .events-filter .events-filter__title {
      font-size: 16px; } }
  @media screen and (max-width: 640px) {
    .title-lv5, .app-intro__title, .feature-listing__item-big-title, .feature-listing__popcap-title, .mf-detail__quicklinks-title, .organic-listing__item-big-title, .organic-listing__popcap-title, .organic-listing__more-link, .search-nores__txt, .sec-intro__detail-subtitle, .submenu__event-title, .item-content .item-tips .item-tips__title, .item-links .item-links__title, .child-item-container .child-item__title, .item-sliders .item-slider-container .item-slider-text .item-slider__title, .story-filter__title, .story-list .story-title, .events-filter .events-filter__title {
      font-size: 16px; } }

.title-lv6, .story-filter-cat-container .filter-cat__title, .events-filter-cat-container .filter-cat__title {
  font-family: "Roboto", 微軟雅黑體, "Microsoft JhengHei", 微軟正黑體, Arial, sans-serif;
  font-weight: bold;
  font-size: 16px; }
  @media screen and (max-width: 1580px) {
    .title-lv6, .story-filter-cat-container .filter-cat__title, .events-filter-cat-container .filter-cat__title {
      font-size: 12px; } }
  @media screen and (max-width: 991px) {
    .title-lv6, .story-filter-cat-container .filter-cat__title, .events-filter-cat-container .filter-cat__title {
      font-size: 12px; } }
  @media screen and (max-width: 640px) {
    .title-lv6, .story-filter-cat-container .filter-cat__title, .events-filter-cat-container .filter-cat__title {
      font-size: 12px; } }

.gb-fontsize-xl, .button-share__holder--big .button-share__title, .success-stories__info-title {
  font-size: 20px; }
  @media screen and (max-width: 640px) {
    .gb-fontsize-xl, .button-share__holder--big .button-share__title, .success-stories__info-title {
      font-size: 20px; } }

.gb-fontsize-l, .footer-bar__desc, .counil-committee p, .mf-detail__box-label {
  font-size: 18px; }
  @media screen and (max-width: 640px) {
    .gb-fontsize-l, .footer-bar__desc, .counil-committee p, .mf-detail__box-label {
      font-size: 16px; } }

.gb-fontsize-m, .accordion__content, .accordion__content p, .alert-template__msg p, .app-intro__intro, .app-intro__desc, .app-intro__btn, .globalNetworkWrapper p.committeDesc, .globalNetworkWrapper span.committeDesc, .addressWrapper p.addressTitle, .addressWrapper p.addressBody, .corporateStructureOverview .wrapper p, .hktdc-events__intro, .management-onemen p.desc, .counil-committee a span, .market-focus__intro-desc, .nav-link__link, .sec-intro__desc, .sec-intro__detail-desc, .side-tabs, .side-title, .sitemap__head, .socialMedia p.platform, .success-stories__info-desc, .submenu__headlink, .submenu__head, .home-mission-container .home-mission-content p, .home-mission-container p, .home-mission-container .mission-item-container .mission-item .right-content p, .home-mission-container .mission-structure-container .structure-item a span, .home-pages-container .home-page-item .page-content p, .home-network-container .left-panel .left-content p, .home-network-container .left-panel .left-content a, .home-network-container .left-panel .left-content .committees-item-nourl, .home-corporate-information .bottom-panel .left-panel .annualreport-item-content .period,
.home-corporate-information .bottom-panel .right-panel .annualreport-item-content .period, .home-corporate-information .bottom-panel .left-panel .annualreport-item-content .content,
.home-corporate-information .bottom-panel .right-panel .annualreport-item-content .content, .home-corporate-information .bottom-panel .left-panel .annualreport-item-content .report-download-btn,
.home-corporate-information .bottom-panel .right-panel .annualreport-item-content .report-download-btn, .home-corporate-information .bottom-panel .more-btn, .home-business-container .home-mean-business p, .our-mission-container .mission-item-container .mission-item .right-content p, .our-mission-container .mission-structure-container .structure-item a span, .corporate-information .bottom-panel .left-panel .annualreport-item-content .period,
.corporate-information .bottom-panel .right-panel .annualreport-item-content .period, .corporate-information .bottom-panel .left-panel .annualreport-item-content .period-dropdown,
.corporate-information .bottom-panel .right-panel .annualreport-item-content .period-dropdown, .corporate-information .bottom-panel .left-panel .annualreport-item-content .content,
.corporate-information .bottom-panel .right-panel .annualreport-item-content .content, .corporate-information .bottom-panel .left-panel .annualreport-item-content .more-btn,
.corporate-information .bottom-panel .right-panel .annualreport-item-content .more-btn, .company-banner-container .left-panel h5, .company-banner-container .left-panel p, .company-whatwedo-container p, .thumb-item-container p {
  font-size: 16px; }
  @media screen and (max-width: 640px) {
    .gb-fontsize-m, .accordion__content, .accordion__content p, .alert-template__msg p, .app-intro__intro, .app-intro__desc, .app-intro__btn, .globalNetworkWrapper p.committeDesc, .globalNetworkWrapper span.committeDesc, .addressWrapper p.addressTitle, .addressWrapper p.addressBody, .corporateStructureOverview .wrapper p, .hktdc-events__intro, .management-onemen p.desc, .counil-committee a span, .market-focus__intro-desc, .nav-link__link, .sec-intro__desc, .sec-intro__detail-desc, .side-tabs, .side-title, .sitemap__head, .socialMedia p.platform, .success-stories__info-desc, .submenu__headlink, .submenu__head, .home-mission-container .home-mission-content p, .home-mission-container p, .home-mission-container .mission-item-container .mission-item .right-content p, .home-mission-container .mission-structure-container .structure-item a span, .home-pages-container .home-page-item .page-content p, .home-network-container .left-panel .left-content p, .home-network-container .left-panel .left-content a, .home-network-container .left-panel .left-content .committees-item-nourl, .home-corporate-information .bottom-panel .left-panel .annualreport-item-content .period,
    .home-corporate-information .bottom-panel .right-panel .annualreport-item-content .period, .home-corporate-information .bottom-panel .left-panel .annualreport-item-content .content,
    .home-corporate-information .bottom-panel .right-panel .annualreport-item-content .content, .home-corporate-information .bottom-panel .left-panel .annualreport-item-content .report-download-btn,
    .home-corporate-information .bottom-panel .right-panel .annualreport-item-content .report-download-btn, .home-corporate-information .bottom-panel .more-btn, .home-business-container .home-mean-business p, .our-mission-container .mission-item-container .mission-item .right-content p, .our-mission-container .mission-structure-container .structure-item a span, .corporate-information .bottom-panel .left-panel .annualreport-item-content .period,
    .corporate-information .bottom-panel .right-panel .annualreport-item-content .period, .corporate-information .bottom-panel .left-panel .annualreport-item-content .period-dropdown,
    .corporate-information .bottom-panel .right-panel .annualreport-item-content .period-dropdown, .corporate-information .bottom-panel .left-panel .annualreport-item-content .content,
    .corporate-information .bottom-panel .right-panel .annualreport-item-content .content, .corporate-information .bottom-panel .left-panel .annualreport-item-content .more-btn,
    .corporate-information .bottom-panel .right-panel .annualreport-item-content .more-btn, .company-banner-container .left-panel h5, .company-banner-container .left-panel p, .company-whatwedo-container p, .thumb-item-container p {
      font-size: 14px; } }

.gb-fontsize-s, .button-share__title, .hktdc-events__list, .hktdc-events__info, .hktdc-events__legend, .mf-detail__intro-desc, .mf-detail__list, .mf-detail__link, .mf-detail__box-date,
.mf-detail__box-desc, .sitemap__link, .sitemap__quicklink, .success-stories__info-desc a, .submenu__intro-desc, .submenu__event-desc, .submenu__event-label, .submenu__event-date, .submenu__link, .submenu__col-more, .submenu__more-link {
  font-size: 14px; }
  @media screen and (max-width: 640px) {
    .gb-fontsize-s, .button-share__title, .hktdc-events__list, .hktdc-events__info, .hktdc-events__legend, .mf-detail__intro-desc, .mf-detail__list, .mf-detail__link, .mf-detail__box-date,
    .mf-detail__box-desc, .sitemap__link, .sitemap__quicklink, .success-stories__info-desc a, .submenu__intro-desc, .submenu__event-desc, .submenu__event-label, .submenu__event-date, .submenu__link, .submenu__col-more, .submenu__more-link {
      font-size: 13px; } }

.gb-fontsize-xs, .alert-bar__title, .alert-bar__desc, .button, .feature-listing__btn, .feedback-panel__btn, .organic-listing__btn, .pagination__btn-prev,
.pagination__btn-next, .child-item__link, .cookies-bar__title, .cookies-bar__desc, .footer-bottom__item, .footer-bottom__copyright {
  font-size: 13px; }
  @media screen and (max-width: 640px) {
    .gb-fontsize-xs, .alert-bar__title, .alert-bar__desc, .button, .feature-listing__btn, .feedback-panel__btn, .organic-listing__btn, .pagination__btn-prev,
    .pagination__btn-next, .child-item__link, .cookies-bar__title, .cookies-bar__desc, .footer-bottom__item, .footer-bottom__copyright {
      font-size: 12px; } }

.gb-fontsize-xxs, .cookies-bar__control .button, .cookies-bar__control .feature-listing__btn, .cookies-bar__control .feedback-panel__btn, .cookies-bar__control .organic-listing__btn, .cookies-bar__control .pagination__btn-prev,
.cookies-bar__control .pagination__btn-next, .cookies-bar__control .child-item__link {
  font-size: 12px; }

main {
  background: #FFF;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  transition: 0.2s;
  transition: filter 0.4s; }
  @media screen and (max-width: 991px) {
    .mob-menu-open main {
      -webkit-filter: blur(4px);
      -moz-filter: blur(4px);
      -o-filter: blur(4px);
      -ms-filter: blur(4px);
      filter: blur(4px); } }
  .search-panel-open main {
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px); }

.access {
  position: absolute;
  left: 0px;
  top: -500px;
  width: 1px;
  height: 1px;
  overflow: hidden; }

.clearfix {
  content: '';
  display: table;
  width: 100%;
  height: 0;
  clear: both; }

.common-wrapper, .content-wrapper, .app-sec__inner, .header__wrapper, .search-nores, .sec-intro__detail-wrapper, .submenu__wrapper {
  width: 94%; }
  @media screen and (max-width: 767px) {
    .common-wrapper, .content-wrapper, .app-sec__inner, .header__wrapper, .search-nores, .sec-intro__detail-wrapper, .submenu__wrapper {
      width: 90%; } }

.content-wrapper, .app-sec__inner, .header__wrapper, .search-nores, .sec-intro__detail-wrapper, .submenu__wrapper {
  margin: 0 auto;
  display: block;
  max-width: 1380px;
  position: relative; }

.content-paragraph {
  margin: 0 auto 15px;
  line-height: 1.5em; }
  .content-paragraph:last-child {
    margin-bottom: 0; }

.content-link:hover, .more-link:hover {
  opacity: 1;
  text-decoration: underline; }

.more-link {
  font-size: 20px; }
  @media screen and (max-width: 640px) {
    .more-link {
      font-size: 16px; } }

.basic-section, .market-focus__holder, .side-tabs {
  position: relative;
  padding: 100px 0; }
  @media screen and (max-width: 1580px) {
    .basic-section, .market-focus__holder, .side-tabs {
      padding: 80px 0; } }
  @media screen and (max-width: 991px) {
    .basic-section, .market-focus__holder, .side-tabs {
      padding: 60px 0; } }
  @media screen and (max-width: 767px) {
    .basic-section, .market-focus__holder, .side-tabs {
      padding: 40px 0; } }

.basic-desc, .market-focus__intro-desc {
  display: block;
  width: 100%;
  max-width: 640px;
  margin: 0 auto 15px;
  line-height: 1.5em; }
  .basic-desc:last-child, .market-focus__intro-desc:last-child {
    margin-bottom: 0; }

.force-oneword {
  display: inline-block;
  white-space: nowrap; }

.force-nobreak {
  white-space: nowrap; }

.force-break {
  word-break: break-all; }

.display--inline-block {
  display: inline-block; }

.display--block {
  display: inline-block; }

.display--inline {
  display: inline; }

.align--hc {
  text-align: center; }

.align--hl {
  text-align: left; }

.align--hr {
  text-align: right; }

.align--vt {
  vertical-align: top; }

.align--vb {
  vertical-align: bottom; }

.align--vm {
  vertical-align: middle; }

.align--hvc {
  text-align: center;
  vertical-align: middle; }

@media screen and (max-width: 991px) {
  .visible-dt {
    display: none !important; } }

@media screen and (min-width: 992px), screen and (max-width: 767px) {
  .visible-tb {
    display: none !important; } }

@media screen and (min-width: 768px) {
  .visible-mb {
    display: none !important; } }

@media screen and (min-width: 992px) {
  .hidden-dt {
    display: none !important; } }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .hidden-tb {
    display: none !important; } }

@media screen and (max-width: 767px) {
  .hidden-mb {
    display: none !important; } }

.hidden-all {
  display: none !important; }

/* END Style commonly used  */
/* ------------------------------------------ */
/* Style for app */
/* ------------------------------------------ */
.app {
  position: relative;
  width: 100%; }

.app__top {
  position: relative;
  width: 100%; }

.app__main {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 110px; }
  @media screen and (max-width: 1580px) {
    .app__main {
      padding-top: 90px; } }
  @media screen and (max-width: 991px) {
    .app__main {
      padding-top: 70px; } }
  .inner-page .app__main {
    padding-top: 200px; }
    @media screen and (max-width: 1480px) {
      .inner-page .app__main {
        padding-top: 120px; } }
    @media screen and (max-width: 991px) {
      .inner-page .app__main {
        padding-top: 110px; } }
    @media screen and (max-width: 767px) {
      .inner-page .app__main {
        padding-top: 80px; } }

/* END Style app  */
/* ------------------------------------------ */
/* Style for access-elem */
/* ------------------------------------------ */
.access-elem {
  position: absolute;
  left: 0px;
  top: -5000px;
  width: 1px;
  height: 1px;
  overflow: hidden; }

/* END Style access-elem  */
/* ------------------------------------------ */
/* Style for accordion */
/* ------------------------------------------ */
.accordion {
  position: relative;
  display: block;
  margin-bottom: 15px; }
  @media screen and (max-width: 991px) {
    .accordion {
      border-bottom: 1px solid #777;
      padding: 30px; } }
  @media screen and (max-width: 380px) {
    .accordion {
      padding: 20px; } }
  .accordion:last-child {
    margin-bottom: 0; }

/*test*/
.accordion__btn {
  position: relative;
  font-weight: bold; }
  .accordion__btn:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-left: 5px;
    width: 0.4em;
    height: 0.4em;
    border-top: 2px solid #fb5004;
    border-right: 2px solid #fb5004;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }
    .accordion.is-active .accordion__btn:after {
      -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: rotate(135deg); }

.accordion__content {
  display: none;
  padding: 15px 0;
  font-weight: 300; }
  .accordion.is-active .accordion__content {
    display: block; }
  .accordion__content p {
    margin: 0 0 10px; }
    .accordion__content p:last-child {
      margin: 0; }
  .accordion__content a:hover {
    text-decoration: underline; }

/* END Style accordion  */
/* ------------------------------------------ */
/* Style for alert-bar */
/* ------------------------------------------ */
.alert-bar {
  padding: 20px 0;
  background: #ededed;
  color: #1a1a1a;
  display: none;
  transition: filter 0.4s; }
  [data-page=home] .alert-bar,
  [data-page=service] .alert-bar {
    display: block; }
  .alert-bar.is-hidden {
    display: none !important; }
  @media screen and (max-width: 991px) {
    .mob-menu-open .alert-bar {
      -webkit-filter: blur(4px);
      -moz-filter: blur(4px);
      -o-filter: blur(4px);
      -ms-filter: blur(4px);
      filter: blur(4px); } }
  .search-panel-open .alert-bar {
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px); }
  @media screen and (max-width: 480px) {
    .alert-bar .content-wrapper, .alert-bar .app-sec__inner, .alert-bar .header__wrapper, .alert-bar .search-nores, .alert-bar .sec-intro__detail-wrapper, .alert-bar .submenu__wrapper {
      padding-right: 35px; } }

.alert-bar__holder {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  @media screen and (max-width: 480px) {
    .alert-bar__holder {
      display: block; } }

.alert-bar__title {
  margin: 0;
  padding-right: 30px;
  font-weight: bold;
  color: #fb5004; }
  @media screen and (max-width: 767px) {
    .alert-bar__title {
      padding-right: 20px; } }

.alert-bar__desc {
  color: #FFF;
  color: #1a1a1a;
  width: 80%; }
  .alert-bar__desc:active, .alert-bar__desc:focus, .alert-bar__desc:hover {
    opacity: 1;
    color: #FFF;
    text-decoration: underline; }
  @media screen and (max-width: 480px) {
    .alert-bar__desc {
      font-size: 12px; } }

.alert-bar__close {
  cursor: pointer;
  -webkit-appearance: none;
  position: relative;
  display: block;
  color: #1a1a1a;
  width: 20px;
  height: 20px;
  box-shadow: none;
  background: transparent;
  border: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }
  @media screen and (max-width: 480px) {
    .alert-bar__close {
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -10px; } }
  .alert-bar__close:before, .alert-bar__close:after {
    content: '';
    background: #1a1a1a;
    position: absolute;
    display: block; }
  .alert-bar__close:before {
    width: 20px;
    height: 2px;
    left: 0;
    top: 10px;
    margin-top: -1px; }
  .alert-bar__close:after {
    height: 20px;
    width: 2px;
    top: 0;
    left: 10px;
    margin-left: -1px; }

/* END Style alert-bar  */
/* ------------------------------------------ */
/* Style for alert-template */
/* ------------------------------------------ */
.alert-template {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 35px 20px 20px;
  background: #dadada;
  color: #1a1a1a;
  z-index: 250;
  position: fixed;
  bottom: 40px;
  right: 45px;
  max-width: 350px;
  width: 90%; }
  @media screen and (max-width: 767px) {
    .alert-template {
      bottom: 25px;
      right: 5%;
      text-align: center; } }
  [data-cookies-bar="show"] .alert-template {
    bottom: 85px; }
    @media screen and (max-width: 767px) {
      [data-cookies-bar="show"] .alert-template {
        bottom: 120px; } }
  .alert-template .alert-template__btn {
    padding: 10px 25px; }
    @media screen and (max-width: 767px) {
      .alert-template .alert-template__btn {
        padding: 8px 18px; } }
  .feedback-panel__alert.is-hidden .alert-template {
    display: none; }
  .alert-template a {
    text-decoration: underline; }
    .alert-template a:hover {
      opacity: 1;
      color: #fb5004; }

.alert-template__msg {
  margin-bottom: 10px; }
  .alert-template__msg p {
    line-height: 1.5em;
    margin-bottom: 15px; }
    .alert-template__msg p:last-child {
      margin-bottom: 0; }

.alert-template__btns {
  white-space: nowrap; }

.alert-template__close {
  display: block;
  width: 16px;
  height: 16px;
  background: url("./assets/images/common/icons/svg/black/icon-close.svg") no-repeat center center;
  background-size: 100%;
  position: absolute;
  right: 12px;
  top: 12px;
  border: 0;
  box-shadow: none;
  cursor: pointer; }
  .alert-template__close:hover {
    background-image: url("./assets/images/common/icons/svg/orange/icon-close.svg"); }

/* END Style alert-template  */
/* ------------------------------------------ */
/* Style for app-intro */
/* ------------------------------------------ */
.app-intro {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 0; }
  @media screen and (max-width: 380px) {
    .app-intro {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; } }

.app-intro__icon {
  position: relative;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; }
  @media screen and (max-width: 380px) {
    .app-intro__icon {
      margin: 0 auto; } }
  .app-intro__icon img {
    display: block;
    width: 70px; }
    @media screen and (max-width: 1200px) {
      .app-intro__icon img {
        width: 55px; } }

.app-intro__intro {
  flex-basis: 100%;
  padding: 0 30px; }
  @media screen and (max-width: 1200px) {
    .app-intro__intro {
      padding: 0 15px; } }
  @media screen and (max-width: 380px) {
    .app-intro__intro {
      display: block;
      width: calc(100% - 55px);
      padding: 15px 0 0;
      text-align: center; } }

.app-intro__title {
  margin: 0 0 10px; }
  .app-intro__title:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-left: 5px;
    width: 0.3em;
    height: 0.3em;
    border-top: 4px solid #fb5004;
    border-right: 4px solid #fb5004;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }

.app-intro__desc {
  width: 100%; }

@media screen and (max-width: 380px) {
  .app-intro__btn {
    margin-top: 20px;
    width: 100%;
    text-align: center; } }

/* END Style app-intro  */
/* ------------------------------------------ */
/* Style for app-sec */
/* ------------------------------------------ */
.app-sec {
  position: relative;
  width: 100%; }

.app-sec__inner {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  padding: 90px 0; }
  @media screen and (max-width: 900px) {
    .app-sec__inner {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
      align-content: stretch;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start; } }
  @media screen and (max-width: 767px) {
    .app-sec__inner {
      padding: 45px 0; } }

.app-sec__item:nth-child(2n) .app-sec__inner {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }
  .app-sec__item:nth-child(2n) .app-sec__inner .app-sec__detail {
    padding-left: 0;
    padding-right: 45px; }
    @media screen and (max-width: 900px) {
      .app-sec__item:nth-child(2n) .app-sec__inner .app-sec__detail {
        padding-right: 0; } }
  @media screen and (max-width: 900px) {
    .app-sec__item:nth-child(2n) .app-sec__inner {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
      align-content: stretch;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start; } }

.app-sec__item--white {
  color: #FFF; }

.app-sec__img {
  width: 55%;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; }
  @media screen and (max-width: 900px) {
    .app-sec__img {
      width: 100%;
      margin-bottom: 15px;
      max-width: 800px; }
      .app-sec__img img {
        display: block; } }

.app-sec__detail {
  width: 45%;
  padding-left: 45px; }
  @media screen and (max-width: 900px) {
    .app-sec__detail {
      width: 100%;
      padding-left: 0; } }

.app-sec__icon {
  margin-bottom: 10px; }
  @media screen and (max-width: 1280px) {
    .app-sec__icon {
      width: 72px; } }

.app-sec__detail-item {
  margin-bottom: 20px; }

.app-sec__big-title {
  font-weight: 300;
  margin: 0 0 15px; }

.app-sec__title {
  margin-bottom: 10px; }

.app-sec__detail-title {
  margin-bottom: 10px;
  color: #fb5004; }
  .app-sec__item--white .app-sec__detail-title {
    color: #FFF; }

.app-sec__detail-desc {
  line-height: 1.5em;
  max-width: 480px; }
  @media screen and (max-width: 991px) {
    .app-sec__detail-desc {
      font-size: 14px; } }

.app-sec__link-icon {
  width: 20px; }

.app-sec__links-title {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  margin-right: 10px; }
  @media screen and (max-width: 1280px) {
    .app-sec__links-title {
      font-size: 14px; } }

.app-sec__link {
  position: relative;
  padding-right: 15px;
  margin-right: 15px;
  background: url() no-repeat center center;
  background-size: cover; }
  @media screen and (max-width: 1280px) {
    .app-sec__link {
      font-size: 14px; } }
  .app-sec__link:after {
    content: '';
    display: block;
    width: 1px;
    height: 18px;
    background: #1a1a1a;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -9px; }
    .app-sec__item--white .app-sec__link:after {
      background: #FFF; }
  .app-sec__link:last-child {
    padding-right: 0;
    margin-right: 0; }
    .app-sec__link:last-child:after {
      display: none; }
  .app-sec__link span,
  .app-sec__link .app-sec__link-icon {
    display: inline-block;
    vertical-align: middle; }
  .app-sec__link .app-sec__link-icon {
    margin-right: 5px;
    margin-top: -3px; }
  .app-sec__item--white .app-sec__link {
    color: #FFF; }

/* END Style app-sec  */
/* ------------------------------------------ */
/* Style for button */
/* ------------------------------------------ */
.button, .feature-listing__btn, .feedback-panel__btn, .organic-listing__btn, .pagination__btn-prev,
.pagination__btn-next, .child-item__link {
  display: inline-block;
  margin: 0;
  padding: 12px 40px;
  box-sizing: border-box;
  font-weight: bold;
  border-radius: 30px;
  background: transparent;
  color: #fb5004;
  border: 1px solid #fb5004;
  cursor: pointer; }
  .button + .button, .feature-listing__btn + .button, .feedback-panel__btn + .button, .organic-listing__btn + .button, .pagination__btn-prev + .button, .pagination__btn-next + .button, .child-item__link + .button, .button + .feature-listing__btn, .feature-listing__btn + .feature-listing__btn, .feedback-panel__btn + .feature-listing__btn, .organic-listing__btn + .feature-listing__btn, .pagination__btn-prev + .feature-listing__btn, .pagination__btn-next + .feature-listing__btn, .child-item__link + .feature-listing__btn, .button + .feedback-panel__btn, .feature-listing__btn + .feedback-panel__btn, .feedback-panel__btn + .feedback-panel__btn, .organic-listing__btn + .feedback-panel__btn, .pagination__btn-prev + .feedback-panel__btn, .pagination__btn-next + .feedback-panel__btn, .child-item__link + .feedback-panel__btn, .button + .organic-listing__btn, .feature-listing__btn + .organic-listing__btn, .feedback-panel__btn + .organic-listing__btn, .organic-listing__btn + .organic-listing__btn, .pagination__btn-prev + .organic-listing__btn, .pagination__btn-next + .organic-listing__btn, .child-item__link + .organic-listing__btn, .button + .pagination__btn-prev, .feature-listing__btn + .pagination__btn-prev, .feedback-panel__btn + .pagination__btn-prev, .organic-listing__btn + .pagination__btn-prev, .pagination__btn-prev + .pagination__btn-prev, .pagination__btn-next + .pagination__btn-prev, .child-item__link + .pagination__btn-prev,
  .button + .pagination__btn-next,
  .feature-listing__btn + .pagination__btn-next,
  .feedback-panel__btn + .pagination__btn-next,
  .organic-listing__btn + .pagination__btn-next,
  .pagination__btn-prev + .pagination__btn-next,
  .pagination__btn-next + .pagination__btn-next,
  .child-item__link + .pagination__btn-next, .button + .child-item__link, .feature-listing__btn + .child-item__link, .feedback-panel__btn + .child-item__link, .organic-listing__btn + .child-item__link, .pagination__btn-prev + .child-item__link, .pagination__btn-next + .child-item__link, .child-item__link + .child-item__link {
    margin-left: 5px; }
  .button > span, .feature-listing__btn > span, .feedback-panel__btn > span, .organic-listing__btn > span, .pagination__btn-prev > span, .pagination__btn-next > span, .child-item__link > span {
    display: inline-block;
    vertical-align: middle; }
  .button:hover, .feature-listing__btn:hover, .feedback-panel__btn:hover, .organic-listing__btn:hover, .pagination__btn-prev:hover,
  .pagination__btn-next:hover, .child-item__link:hover {
    opacity: 1;
    background: #fb5004;
    color: #FFF; }
    .button:hover.button--right-arr:after, .feature-listing__btn:hover.button--right-arr:after, .feedback-panel__btn:hover.button--right-arr:after, .organic-listing__btn:hover.button--right-arr:after, .pagination__btn-prev:hover.button--right-arr:after,
    .pagination__btn-next:hover.button--right-arr:after, .child-item__link:hover.button--right-arr:after {
      border-top: 2px solid #FFF;
      border-right: 2px solid #FFF; }
  .button.button--white, .button--white.feature-listing__btn, .button--white.feedback-panel__btn, .button--white.organic-listing__btn, .button--white.pagination__btn-prev,
  .button--white.pagination__btn-next, .button--white.child-item__link {
    background: #FFF;
    color: #1a1a1a; }
    .button.button--white:hover, .button--white.feature-listing__btn:hover, .button--white.feedback-panel__btn:hover, .button--white.organic-listing__btn:hover, .button--white.pagination__btn-prev:hover,
    .button--white.pagination__btn-next:hover, .button--white.child-item__link:hover {
      background: #fb5004;
      color: #FFF; }
  .button.button--orange, .button--orange.feature-listing__btn, .button--orange.feedback-panel__btn, .button--orange.organic-listing__btn, .button--orange.pagination__btn-prev,
  .button--orange.pagination__btn-next, .button--orange.child-item__link {
    background: #fb5004;
    color: #FFF; }
    .button.button--orange:hover, .button--orange.feature-listing__btn:hover, .button--orange.feedback-panel__btn:hover, .button--orange.organic-listing__btn:hover, .button--orange.pagination__btn-prev:hover,
    .button--orange.pagination__btn-next:hover, .button--orange.child-item__link:hover {
      background: #FFF;
      color: #fb5004; }
  .button.button--ghost-black, .button--ghost-black.feature-listing__btn, .button--ghost-black.feedback-panel__btn, .button--ghost-black.organic-listing__btn, .button--ghost-black.pagination__btn-prev,
  .button--ghost-black.pagination__btn-next, .button--ghost-black.child-item__link {
    background: transparent;
    color: #1a1a1a;
    border-color: #1a1a1a; }
    .button.button--ghost-black:hover, .button--ghost-black.feature-listing__btn:hover, .button--ghost-black.feedback-panel__btn:hover, .button--ghost-black.organic-listing__btn:hover, .button--ghost-black.pagination__btn-prev:hover,
    .button--ghost-black.pagination__btn-next:hover, .button--ghost-black.child-item__link:hover {
      background: #fb5004;
      color: #FFF;
      border-color: #fb5004; }
  .button.button--ghost-white, .button--ghost-white.feature-listing__btn, .button--ghost-white.feedback-panel__btn, .button--ghost-white.organic-listing__btn, .button--ghost-white.pagination__btn-prev,
  .button--ghost-white.pagination__btn-next, .button--ghost-white.child-item__link, .child-item__link.child-item__link--white {
    background: transparent;
    color: #FFF;
    border-color: #FFF; }
    .button.button--ghost-white:hover, .button--ghost-white.feature-listing__btn:hover, .button--ghost-white.feedback-panel__btn:hover, .button--ghost-white.organic-listing__btn:hover, .button--ghost-white.pagination__btn-prev:hover,
    .button--ghost-white.pagination__btn-next:hover, .button--ghost-white.child-item__link:hover, .child-item__link.child-item__link--white:hover {
      background: #FFF;
      color: #fb5004;
      border-color: #FFF; }
  .button.button--ghost-orange, .button--ghost-orange.feature-listing__btn, .button--ghost-orange.feedback-panel__btn, .button--ghost-orange.organic-listing__btn, .button--ghost-orange.pagination__btn-prev,
  .button--ghost-orange.pagination__btn-next, .button--ghost-orange.child-item__link {
    background: transparent;
    color: #fb5004;
    border-color: #fb5004; }
    .button.button--ghost-orange:hover, .button--ghost-orange.feature-listing__btn:hover, .button--ghost-orange.feedback-panel__btn:hover, .button--ghost-orange.organic-listing__btn:hover, .button--ghost-orange.pagination__btn-prev:hover,
    .button--ghost-orange.pagination__btn-next:hover, .button--ghost-orange.child-item__link:hover {
      background: #fb5004;
      color: #FFF;
      border-color: #fb5004; }
  .button.button--wide, .button--wide.feature-listing__btn, .button--wide.feedback-panel__btn, .button--wide.organic-listing__btn, .button--wide.pagination__btn-prev,
  .button--wide.pagination__btn-next, .button--wide.child-item__link {
    padding: 12px 45px; }
  .button.button--s, .button--s.feature-listing__btn, .button--s.feedback-panel__btn, .button--s.organic-listing__btn, .button--s.pagination__btn-prev,
  .button--s.pagination__btn-next, .button--s.child-item__link {
    padding: 10px 30px; }
    @media screen and (max-width: 767px) {
      .button.button--s, .button--s.feature-listing__btn, .button--s.feedback-panel__btn, .button--s.organic-listing__btn, .button--s.pagination__btn-prev,
      .button--s.pagination__btn-next, .button--s.child-item__link {
        padding: 8px 18px; } }
  .button.button--right-arr, .button--right-arr.feature-listing__btn, .button--right-arr.feedback-panel__btn, .button--right-arr.organic-listing__btn, .button--right-arr.pagination__btn-prev,
  .button--right-arr.pagination__btn-next, .button--right-arr.child-item__link {
    white-space: nowrap; }
    .button.button--right-arr span, .button--right-arr.feature-listing__btn span, .button--right-arr.feedback-panel__btn span, .button--right-arr.organic-listing__btn span, .button--right-arr.pagination__btn-prev span, .button--right-arr.pagination__btn-next span, .button--right-arr.child-item__link span {
      display: inline-block;
      vertical-align: middle;
      white-space: all; }
    .button.button--right-arr:after, .button--right-arr.feature-listing__btn:after, .button--right-arr.feedback-panel__btn:after, .button--right-arr.organic-listing__btn:after, .button--right-arr.pagination__btn-prev:after,
    .button--right-arr.pagination__btn-next:after, .button--right-arr.child-item__link:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-top: 0;
      margin-left: 5px;
      width: 0.4em;
      height: 0.4em;
      border-top: 2px solid #fb5004;
      border-right: 2px solid #fb5004;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg); }

.button-row {
  margin: 30px auto; }
  .button-row:last-child {
    margin-bottom: 0; }

/* END Style button  */
/* ------------------------------------------ */
/* Style for button-share */
/* ------------------------------------------ */
.button-share__holder {
  position: relative;
  font-size: 0;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }
  .footer-bottom .button-share__holder, .button-share__holder.button-share__holder--big {
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    @media screen and (max-width: 480px) {
      .footer-bottom .button-share__holder, .button-share__holder.button-share__holder--big {
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; } }
  .footer-bottom .button-share__holder {
    margin-top: 15px; }
    @media screen and (max-width: 640px) {
      .footer-bottom .button-share__holder {
        text-align: left;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 20px; } }

.button-share__btn-holder {
  margin-top: -3px;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }
  @media screen and (max-width: 640px) {
    .footer-bottom .button-share__btn-holder {
      margin-top: 5px;
      text-align: left;
      width: 100%; } }

.button-share {
  cursor: pointer;
  border: 0;
  box-shadow: none;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  margin-bottom: 20px;
  width: 22px;
  height: 22px;
  background: url() no-repeat center center;
  background-size: 85%; }
  @media screen and (max-width: 1580px) {
    .button-share {
      margin-right: 5px; } }
  @media screen and (max-width: 991px) {
    .button-share {
      margin-right: 15px; } }
  @media screen and (max-width: 767px) {
    .button-share {
      width: 18px;
      height: 18px;
      margin-right: 15px; } }
  @media screen and (max-width: 640px) {
    .footer-bottom .button-share {
      margin-bottom: 0; } }
  .button-share__holder--big .button-share {
    width: 35px;
    height: 35px;
    margin: 0;
    margin-right: 30px; }
    .button-share__holder--big .button-share:last-child {
      margin-right: 0; }
    @media screen and (max-width: 1200px) {
      .button-share__holder--big .button-share {
        width: 28px;
        height: 28px;
        margin-right: 20px; } }
    @media screen and (max-width: 480px) {
      .button-share__holder--big .button-share {
        width: 24px;
        height: 24px;
        margin-right: 15px;
        margin-bottom: 12px; } }
  .button-share:focus {
    outline: none; }
  .button-share.button-share--email {
    background-image: url("./assets/images/common/icons/svg/black/icon-share-email.svg"); }
  .button-share.button-share--wa {
    background-image: url("./assets/images/common/icons/svg/black/icon-share-wa.svg"); }
  .button-share.button-share--fb {
    background-image: url("./assets/images/common/icons/svg/black/icon-share-fb.svg");
    background-size: 76%; }
  .button-share.button-share--tw {
    background-image: url("./assets/images/common/icons/svg/black/icon-share-tw.svg");
    background-size: 76%; }
  .button-share.button-share--ln {
    background-image: url("./assets/images/common/icons/svg/black/icon-share-ln.svg");
    background-size: 76%; }
  .button-share.button-share--wb {
    background-image: url("./assets/images/common/icons/svg/black/icon-share-wb.svg");
    background-size: 100%; }
  .button-share.button-share--yt {
    background-image: url("./assets/images/common/icons/svg/black/icon-share-yt.svg");
    background-size: 100%; }
  .button-share.button-share--sp {
    background-image: url("./assets/images/common/icons/svg/black/icon-share-sp.svg"); }
  .button-share.button-share--wc {
    background-image: url("./assets/images/common/icons/svg/black/icon-share-wc.svg");
    background-size: 95%; }
  .button-share.button-share--yk {
    background-image: url("./assets/images/common/icons/svg/black/icon-share-yk.svg"); }
  .button-share.button-share--fbm {
    background-image: url("./assets/images/common/icons/svg/black/icon-share-fbmessenger.svg"); }
  .main-banner .button-share {
    margin-bottom: 15px; }
    @media screen and (max-width: 991px) {
      .main-banner .button-share.button-share--email {
        background-image: url("./assets/images/common/icons/svg/white/icon-share-email.svg"); }
      .main-banner .button-share.button-share--wa {
        background-image: url("./assets/images/common/icons/svg/white/icon-share-wa.svg"); }
      .main-banner .button-share.button-share--fb {
        background-image: url("./assets/images/common/icons/svg/white/icon-share-fb.svg"); }
      .main-banner .button-share.button-share--tw {
        background-image: url("./assets/images/common/icons/svg/white/icon-share-tw.svg"); }
      .main-banner .button-share.button-share--ln {
        background-image: url("./assets/images/common/icons/svg/white/icon-share-ln.svg"); }
      .main-banner .button-share.button-share--wb {
        background-image: url("./assets/images/common/icons/svg/white/icon-share-wb.svg"); }
      .main-banner .button-share.button-share--wc {
        background-image: url("./assets/images/common/icons/svg/white/icon-share-wc.svg"); }
      .main-banner .button-share.button-share--yt {
        background-image: url("./assets/images/common/icons/svg/white/icon-share-yt.svg"); }
      .main-banner .button-share.button-share--yk {
        background-image: url("./assets/images/common/icons/svg/white/icon-share-yk.svg"); }
      .main-banner .button-share.button-share--fbm {
        background-image: url("./assets/images/common/icons/svg/white/icon-share-fbmessenger.svg"); } }

.button-share__title {
  margin-bottom: 0;
  margin-right: 15px;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap; }
  @media screen and (max-width: 1580px) {
    .button-share__title {
      margin-right: 5px; } }
  @media screen and (max-width: 991px) {
    .button-share__title {
      margin-right: 15px; } }
  .footer-bottom .button-share__title {
    margin-bottom: 20px; }
    @media screen and (max-width: 640px) {
      .footer-bottom .button-share__title {
        margin-top: 4px;
        margin-bottom: 0;
        text-align: left; } }
    @media screen and (max-width: 480px) {
      .footer-bottom .button-share__title {
        width: 100%; } }
  .button-share__holder--big .button-share__title {
    margin: 0;
    margin-right: 30px;
    font-weight: 400;
    white-space: nowrap; }
    @media screen and (max-width: 480px) {
      .button-share__holder--big .button-share__title {
        margin: 0 auto 10px; } }

.button-share__wcqr {
  text-align: center;
  padding: 30px 0 0;
  max-width: 200px; }
  .button-share__wcqr + button {
    cursor: pointer; }

.button-share__wcqr-txt {
  text-align: center;
  margin-top: 15px;
  padding: 0 30px;
  font-size: 14px;
  line-height: 1.5em; }

/* END Style button-share  */
/* ------------------------------------------ */
/* Style for sample */
/* ------------------------------------------ */
.channel-bar {
  position: relative;
  font-size: 0;
  margin: 0 0 45px; }
  @media screen and (max-width: 991px) {
    .channel-bar {
      margin: 0 auto; } }
  .channel-bar > .content-wrapper, .channel-bar > .app-sec__inner, .channel-bar > .header__wrapper, .channel-bar > .search-nores, .channel-bar > .sec-intro__detail-wrapper, .channel-bar > .submenu__wrapper {
    display: table; }
    @media screen and (max-width: 991px) {
      .channel-bar > .content-wrapper, .channel-bar > .app-sec__inner, .channel-bar > .header__wrapper, .channel-bar > .search-nores, .channel-bar > .sec-intro__detail-wrapper, .channel-bar > .submenu__wrapper {
        display: block; } }

.channel-bar__item {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  border-right: 2px solid #e5e5e5;
  padding-right: 45px; }
  @media screen and (max-width: 991px) {
    .channel-bar__item {
      display: block;
      width: 100%; } }
  @media screen and (max-width: 991px) {
    .channel-bar__item {
      padding-right: 0;
      border-right: 0; } }
  .channel-bar > .content-wrapper.animated .channel-bar__item, .channel-bar > .animated.app-sec__inner .channel-bar__item, .channel-bar > .animated.header__wrapper .channel-bar__item, .channel-bar > .animated.search-nores .channel-bar__item, .channel-bar > .animated.sec-intro__detail-wrapper .channel-bar__item, .channel-bar > .animated.submenu__wrapper .channel-bar__item {
    transition: 0.6s; }
  .channel-bar > .content-wrapper.animated .channel-bar__item:first-child, .channel-bar > .animated.app-sec__inner .channel-bar__item:first-child, .channel-bar > .animated.header__wrapper .channel-bar__item:first-child, .channel-bar > .animated.search-nores .channel-bar__item:first-child, .channel-bar > .animated.sec-intro__detail-wrapper .channel-bar__item:first-child, .channel-bar > .animated.submenu__wrapper .channel-bar__item:first-child {
    -webkit-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    transform: translateX(-100px); }
  .channel-bar > .content-wrapper.animated.fadeIn .channel-bar__item:first-child, .channel-bar > .animated.fadeIn.app-sec__inner .channel-bar__item:first-child, .channel-bar > .animated.fadeIn.header__wrapper .channel-bar__item:first-child, .channel-bar > .animated.fadeIn.search-nores .channel-bar__item:first-child, .channel-bar > .animated.fadeIn.sec-intro__detail-wrapper .channel-bar__item:first-child, .channel-bar > .animated.fadeIn.submenu__wrapper .channel-bar__item:first-child {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  .channel-bar__item:last-child {
    padding-left: 45px;
    padding-right: 0;
    border-right: 0; }
    .channel-bar > .content-wrapper.animated .channel-bar__item:last-child, .channel-bar > .animated.app-sec__inner .channel-bar__item:last-child, .channel-bar > .animated.header__wrapper .channel-bar__item:last-child, .channel-bar > .animated.search-nores .channel-bar__item:last-child, .channel-bar > .animated.sec-intro__detail-wrapper .channel-bar__item:last-child, .channel-bar > .animated.submenu__wrapper .channel-bar__item:last-child {
      -webkit-transform: translateX(100px);
      -ms-transform: translateX(100px);
      transform: translateX(100px); }
    .channel-bar > .content-wrapper.animated.fadeIn .channel-bar__item:last-child, .channel-bar > .animated.fadeIn.app-sec__inner .channel-bar__item:last-child, .channel-bar > .animated.fadeIn.header__wrapper .channel-bar__item:last-child, .channel-bar > .animated.fadeIn.search-nores .channel-bar__item:last-child, .channel-bar > .animated.fadeIn.sec-intro__detail-wrapper .channel-bar__item:last-child, .channel-bar > .animated.fadeIn.submenu__wrapper .channel-bar__item:last-child {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0); }
    @media screen and (max-width: 991px) {
      .channel-bar__item:last-child {
        padding-left: 0;
        text-align: center;
        border-top: 2px solid #e5e5e5;
        padding: 45px 0; } }

/* END Style sample  */
/* ------------------------------------------ */
/* Style for comformance */
/* ------------------------------------------ */
.comformance {
  position: relative;
  text-align: center;
  margin: 0 0 15px; }
  @media screen and (max-width: 991px) {
    .comformance {
      display: none; } }

.comformance__item {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px; }
  @media screen and (max-width: 1180px) {
    .comformance__item {
      margin: 0 4px; } }

/* END Style comformance  */
/* ------------------------------------------ */
.staticImage img {
  width: 100px; }

.contactUsPage .sec-intro {
  padding-bottom: 0; }

.globalNetworkWrapper {
  position: relative;
  background-color: #f5f5f5;
  background-image: url("https://img.hktdc.com/eyJidWNrZXQiOiJjbXMtaW1nbGliLXByZCIsImtleSI6IkFib3V0IFVzLzIwMjUwMTIxLzUybnRjbnktUVdKdmRYUlZjeUJ1WlhjZ1ltRmphMmR5YjNWdVpGOHhPVEl3ZURVME1BPT0uanBlZyIsInJlZ2lvbiI6ImFwLXNvdXRoZWFzdC0xIiwiZm9ybWF0IjoianBlZyIsInF1YWxpdHkiOjEwMCwiZWRpdHMiOnt9fQ==");
  /*background-position: center;*/
  background-size: cover;
  text-align: center;
  font-weight: lighter; }

.globalNetworkWrapper .wrapper {
  padding-top: 70px;
  padding-bottom: 20px;
  max-width: 1000px;
  margin: auto;
  width: 80vw; }

.globalNetworkWrapper p {
  margin: 0;
  padding: 0; }

.globalNetworkWrapper p.globalNetworkTitle,
p.globalNetworkSubtitle {
  color: #242753;
  font-size: 30px; }

.globalNetworkWrapper p.committeDesc {
  color: white;
  margin: 15px auto;
  line-height: 1.5; }
  .globalNetworkWrapper p.committeDesc b {
    font-weight: bold; }

.globalNetworkWrapper span.committeDesc {
  margin: 15px auto;
  line-height: 1.5; }
  .globalNetworkWrapper span.committeDesc b {
    font-weight: bold; }
  .globalNetworkWrapper span.committeDesc.display-block {
    display: inline-block; }

.globalNetworkWrapper div.committeLinks {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }

.globalNetworkWrapper .committeLink {
  color: white;
  border: solid 1px #fb5004;
  font-size: 16px;
  padding: 3px 10px;
  display: inline-block;
  margin: 3px 3px; }

.globalNetworkWrapper .separateLine {
  height: 2px;
  width: 75px;
  background-color: #fb5004;
  margin: 20px auto; }

.globalNetworkWrapper .filter {
  display: flex;
  color: white;
  list-style: none;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  align-items: stretch; }

.globalNetworkWrapper .filter li {
  margin: 5px 20px; }

.globalNetworkWrapper li.clickable:hover {
  cursor: pointer;
  color: #fb5004; }

.globalNetworkWrapper li.clickable.active {
  color: #fb5004; }

.addressWrapper {
  line-height: 1.5;
  text-align: center;
  margin: 0 auto;
  max-width: 1000px;
  width: 80%; }

.addressWrapper p {
  margin: 0;
  padding: 0;
  font-weight: lighter; }

.addressWrapper p.addressTitle {
  color: #fb5004;
  margin-bottom: 30px; }

.addressWrapper p.addressBody {
  color: #333;
  line-height: 1.8; }

.addressWrapper img {
  margin-top: 10px; }

.contactMethodWrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px; }

.contactMethodWrapper img {
  margin-right: 15px;
  height: 20px; }

.contactMethodWrapper .contactMethodIcon {
  margin-right: 10px;
  height: 20px;
  top: 5px;
  position: relative; }

.contactMethodWrapper p {
  height: 29px;
  line-height: 29px;
  padding: 0;
  margin: 5px 8px; }
  .contactMethodWrapper p:not(:last-child):after {
    content: '|';
    vertical-align: top;
    position: absolute;
    font-size: 20px;
    font-weight: lighter;
    color: #a1a1a1; }

.contactMethodWrapper p span {
  position: relative;
  font-weight: lighter;
  padding-right: 13px; }

.contactWrapper {
  padding: 60px 0;
  position: relative;
  background-color: #f5f5f5;
  background-image: url("https://img-sit.hktdc.com/eyJidWNrZXQiOiJjbXMtaW1nbGliLWRldiIsImtleSI6IkFib3V0IFVzLzIwMjUwMTIwLzM0bHE4ZDFxLVFXSnZkWFJWY3lCdVpYY2dZbUZqYTJkeWIzVnVaRjh4T1RJd2VEVTBNQT09LmpwZWciLCJyZWdpb24iOiJhcC1zb3V0aGVhc3QtMSIsImZvcm1hdCI6ImpwZWciLCJxdWFsaXR5IjoxMDAsImVkaXRzIjp7fX0=");
  /*background-position: center;*/
  background-size: cover;
  color: #333; }
  .contactWrapper a {
    color: #333; }

.contactWrapper .separateLine {
  height: 2px;
  width: 100px;
  background-color: #d2d2d2;
  margin: 20px auto 30px; }

.contactUsMapWrapper {
  position: relative; }

.contactUsMapWrapper .overlayView {
  position: absolute;
  top: 15%;
  left: calc((100vw - 365px)*0.8);
  background-color: white;
  width: 365px;
  padding: 50px;
  z-index: 20; }

.contactUsMapWrapper .overlayView p {
  margin: 0;
  padding: 0; }

button.crossBtn {
  width: 20px;
  padding: 0;
  background: transparent;
  border: none;
  position: absolute;
  top: 30px;
  right: 30px;
  outline: none; }

.home-network-container .contactUsMapWrapper {
  height: 100%; }

.home-network-container .contactUsMapWrapper .overlayView {
  position: relative;
  left: 0;
  margin: auto;
  top: 20%; }

.contactUsMapWrapper .overlayView p.title {
  font-size: 25px;
  color: #fb5004; }

.contactUsMapWrapper .overlayView img.thumbnail {
  margin-top: 10px; }

.contactUsMapWrapper .overlayView p.directorName {
  font-size: 20px;
  color: #171717; }

.contactUsMapWrapper .overlayView p.directorPosition {
  font-size: 14px;
  color: #fb5004; }

.contactUsMapWrapper .overlayView p.contact {
  font-size: 14px;
  color: #626262; }

.contactUsMapWrapper .overlayView .contactWrapper {
  margin: 20px 0; }

.contactUsMapWrapper .overlayView .socialMediaBar button {
  padding: 0;
  background: transparent;
  border: 0;
  width: 15px;
  margin-right: 15px; }

.gmnoprint:hover,
button.crossBtn:hover {
  cursor: pointer; }

.mobileFilter > li {
  height: 50px;
  line-height: 40px; }

.dropDownBox {
  width: 80%; }

.dropDwonBoxWrapper > div {
  display: flex;
  flex-direction: column; }

.dropDownBox li {
  margin: 0 !important;
  border: solid 1px white;
  height: 50px;
  line-height: 50px;
  order: 2; }

/*.dropDownBox li { display: none;}*/
.dropDownBox li.active {
  display: block;
  order: 1; }

.filter.mobileFilter {
  display: none; }

@media screen and (max-width: 767px) {
  .filter.desktopFilter {
    display: none; }
  .filter.mobileFilter {
    display: flex; }
  .contactUsMapWrapper .overlayView {
    position: absolute;
    top: 15%;
    left: calc((100% - 365px)/2);
    background-color: white;
    width: 365px;
    padding: 50px; }
  .addressWrapper p.addressTitle {
    font-size: 15px; }
  .addressWrapper p.addressBody {
    font-size: 15px;
    color: #333; }
  .globalNetworkWrapper p.globalNetworkTitle,
  p.globalNetworkSubtitle {
    font-size: 25px; } }

/* adhoc delete google map */
.globalNetworkWrapper div.committeLinks, .filter.desktopFilter, .filter.mobileFilter, #globalNetworkMap {
  display: none; }

/* Style for cookies-bar */
/* ------------------------------------------ */
.cookies-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px 0;
  background: #242753;
  color: #FFF;
  z-index: 200;
  transition: filter 0.4s; }
  .cookies-bar.is-hidden {
    display: none; }
  @media screen and (max-width: 991px) {
    .mob-menu-open .cookies-bar {
      -webkit-filter: blur(4px);
      -moz-filter: blur(4px);
      -o-filter: blur(4px);
      -ms-filter: blur(4px);
      filter: blur(4px); } }
  .search-panel-open .cookies-bar {
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px); }
  @media screen and (max-width: 767px) {
    .cookies-bar {
      text-align: center; } }

.cookies-bar__holder {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  @media screen and (max-width: 480px) {
    .cookies-bar__holder {
      display: block; } }

.cookies-bar__title {
  margin: 0;
  padding-right: 30px;
  font-weight: bold;
  color: #FFF; }
  @media screen and (max-width: 767px) {
    .cookies-bar__title {
      padding-right: 20px; } }

.cookies-bar__desc {
  color: #FFF;
  width: 80%;
  line-height: 1.5; }
  @media screen and (max-width: 767px) {
    .cookies-bar__desc {
      width: 100%; } }
  .cookies-bar__desc a {
    color: rgba(255, 255, 255, 0.7); }
    .cookies-bar__desc a:hover {
      opacity: 1;
      color: rgba(255, 255, 255, 0.7);
      text-decoration: underline; }

.cookies-bar__close {
  cursor: pointer;
  position: relative;
  display: block;
  width: 18px;
  height: 18px;
  background: url("./assets/images/common/icons/svg/white/icon-close.svg") no-repeat center center transparent;
  background-size: 100%;
  box-shadow: none;
  border: 0; }

.cookies-bar__control {
  white-space: nowrap; }
  @media screen and (max-width: 480px) {
    .cookies-bar__control {
      margin-top: 10px; } }

.button.button--ghost-white.cookies-bar__btn_refuse, .button--ghost-white.cookies-bar__btn_refuse.feature-listing__btn, .button--ghost-white.cookies-bar__btn_refuse.feedback-panel__btn, .button--ghost-white.cookies-bar__btn_refuse.organic-listing__btn, .button--ghost-white.cookies-bar__btn_refuse.pagination__btn-prev,
.button--ghost-white.cookies-bar__btn_refuse.pagination__btn-next, .button--ghost-white.cookies-bar__btn_refuse.child-item__link, .cookies-bar__btn_refuse.child-item__link.child-item__link--white {
  border: none;
  background: transparent; }
  .button.button--ghost-white.cookies-bar__btn_refuse:hover, .button--ghost-white.cookies-bar__btn_refuse.feature-listing__btn:hover, .button--ghost-white.cookies-bar__btn_refuse.feedback-panel__btn:hover, .button--ghost-white.cookies-bar__btn_refuse.organic-listing__btn:hover, .button--ghost-white.cookies-bar__btn_refuse.pagination__btn-prev:hover,
  .button--ghost-white.cookies-bar__btn_refuse.pagination__btn-next:hover, .button--ghost-white.cookies-bar__btn_refuse.child-item__link:hover, .cookies-bar__btn_refuse.child-item__link.child-item__link--white:hover {
    color: white;
    opacity: 0.7;
    border: none;
    background: transparent; }

.button.button--ghost-white.cookies-bar__btn-accept, .button--ghost-white.cookies-bar__btn-accept.feature-listing__btn, .button--ghost-white.cookies-bar__btn-accept.feedback-panel__btn, .button--ghost-white.cookies-bar__btn-accept.organic-listing__btn, .button--ghost-white.cookies-bar__btn-accept.pagination__btn-prev,
.button--ghost-white.cookies-bar__btn-accept.pagination__btn-next, .button--ghost-white.cookies-bar__btn-accept.child-item__link, .cookies-bar__btn-accept.child-item__link.child-item__link--white {
  border-radius: 0;
  border: none;
  background: #ead219;
  color: #242753; }
  .button.button--ghost-white.cookies-bar__btn-accept:hover, .button--ghost-white.cookies-bar__btn-accept.feature-listing__btn:hover, .button--ghost-white.cookies-bar__btn-accept.feedback-panel__btn:hover, .button--ghost-white.cookies-bar__btn-accept.organic-listing__btn:hover, .button--ghost-white.cookies-bar__btn-accept.pagination__btn-prev:hover,
  .button--ghost-white.cookies-bar__btn-accept.pagination__btn-next:hover, .button--ghost-white.cookies-bar__btn-accept.child-item__link:hover, .cookies-bar__btn-accept.child-item__link.child-item__link--white:hover {
    opacity: 0.7; }

/* END Style cookies-bar  */
/* ------------------------------------------ */
.page-items {
  position: relative; }

.corporateStructureOverview {
  height: 180px;
  width: 100%;
  background-color: #f5f5f5;
  background-image: url("https://img.hktdc.com/eyJidWNrZXQiOiJjbXMtaW1nbGliLXByZCIsImtleSI6IkFib3V0IFVzLzIwMjUwMTIxLzUybnRjbnktUVdKdmRYUlZjeUJ1WlhjZ1ltRmphMmR5YjNWdVpGOHhPVEl3ZURVME1BPT0uanBlZyIsInJlZ2lvbiI6ImFwLXNvdXRoZWFzdC0xIiwiZm9ybWF0IjoianBlZyIsInF1YWxpdHkiOjEwMCwiZWRpdHMiOnt9fQ==");
  background-position: center;
  color: #000;
  margin-bottom: 20px; }

.corporateStructureOverview .wrapper {
  max-width: 1440px;
  display: flex;
  align-items: center;
  height: 100%;
  margin: auto; }

.corporateStructureOverview .wrapper p {
  width: 50vw;
  text-align: center;
  margin: auto;
  /*
	line-height: 20px;
	font-size: 14px;
	*/
  line-height: 1.5em; }

.corporateStructure:last-of-type {
  margin-bottom: 80px; }

.corporateStructure {
  margin-bottom: -1px; }

.corporateStructure .wrapper {
  width: 60vw;
  margin: auto;
  border-bottom: solid 1px #E3E3E3; }

.corporateStructure .titleWrapper {
  text-align: center;
  margin: auto;
  position: relative;
  cursor: pointer; }

.corporateStructure .titleWrapper p {
  padding: 40px 0;
  color: #f5f5f5;
  font-size: 16px;
  font-weight: bold;
  border-top: solid 1px #E3E3E3; }
  .corporateStructure .titleWrapper p a {
    color: #242753; }
    .corporateStructure .titleWrapper p a .icon-right-arrow-angle.opened::before {
      transform: rotate(90deg); }

.corporateStructure .imageWrapper {
  text-align: center; }

.corporateStructure .imageWrapper img {
  margin: auto; }

button.arrowBtn {
  width: 30px;
  background-color: transparent;
  border: none;
  position: absolute;
  top: calc(( 100px - 30px )/2);
  right: 0;
  outline: none; }

button.arrowBtn:hover, button.downloadBtn:hover {
  cursor: pointer; }

.corporateStructure .buttonWrapper {
  text-align: center; }

button.downloadBtn {
  border: solid 1px #fb5004;
  background-color: transparent;
  border-radius: 20px;
  outline: none;
  padding: 10px 40px;
  color: #fb5004;
  margin-top: 20px;
  margin-bottom: 50px; }

@media screen and (max-width: 991px) {
  .corporateStructureOverview {
    height: auto !important;
    padding: 20px 0px; }
  .corporateStructure .wrapper {
    width: 90vw; }
  .corporateStructureOverview .wrapper p {
    width: 80vw !important; } }

.corporateStructureOverview {
  height: 180px;
  width: 100%;
  background-color: #f5f5f5;
  color: #000;
  margin-bottom: 20px; }

.corporateStructureOverview .wrapper {
  max-width: 1440px;
  display: flex;
  align-items: center;
  height: 100%; }

.corporateStructureOverview .wrapper p {
  width: 50vw;
  text-align: center;
  margin: auto;
  /*
	line-height: 20px;
	font-size: 14px;
	*/
  line-height: 1.5em; }

.expandableItem {
  margin-bottom: -1px; }

.expandableItem .wrapper {
  width: 80vw;
  margin: auto;
  border-bottom: solid 1px #E3E3E3; }

.expandableItem .titleWrapper {
  display: flex;
  align-items: center;
  text-align: left;
  margin: auto;
  position: relative;
  cursor: pointer;
  border-top: solid 1px #E3E3E3; }

.expandableItem .titleWrapper p {
  padding: 40px 0;
  color: #f05323;
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 0px;
  width: 100%; }

.expandableItem .imageWrapper {
  text-align: left; }
  .expandableItem .imageWrapper ul {
    padding-left: 0; }
  .expandableItem .imageWrapper li {
    list-style: none;
    display: block;
    width: 100%; }
  .expandableItem .imageWrapper li:before {
    content: "•";
    padding-right: 10px;
    font-size: 20px;
    color: #fb5004; }
  .expandableItem .imageWrapper .emptyLi:before {
    color: white; }

.expandableItem .imageWrapper img {
  margin: auto; }

img.arrowBtn {
  width: 16px; }

button.arrowBtn:hover, button.downloadBtn:hover {
  cursor: pointer; }

.corporateStructure .buttonWrapper {
  text-align: center; }

button.downloadBtn {
  border: solid 1px #fb5004;
  background-color: transparent;
  border-radius: 20px;
  outline: none;
  padding: 10px 40px;
  color: #fb5004;
  margin-top: 20px;
  margin-bottom: 50px; }
  button.downloadBtn .download-icon:before {
    content: "";
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: 5px;
    background: url() no-repeat 50%;
    background-image: url("./assets/images/common/icons/svg/orange/icon-download.svg");
    background-size: 100%;
    fill: #F05323; }
  button.downloadBtn:hover {
    background-color: #fb5004;
    color: white; }
    button.downloadBtn:hover .download-icon:before {
      background-image: url("./assets/images/common/icons/svg/white/icon-download.svg"); }

/* Style for sample */
/* ------------------------------------------ */
/* END Style sample  */
/* ------------------------------------------ */
/* Style for popup_video */
/* ------------------------------------------ */
.popup-content {
  background: none !important;
  border: none !important; }

.popup-close-btn {
  content: "";
  width: 36px;
  height: 36px;
  display: block;
  margin-top: -48px;
  float: right;
  background: url() no-repeat 50%;
  background-image: url("./assets/images/common/icons/svg/white/icon-close.svg");
  background-size: 100%;
  fill: #F05323; }

iframe, video {
  border: none;
  display: block;
  margin: auto;
  width: 560px;
  height: 315px; }

@media screen and (max-width: 767px) {
  .popup-content {
    width: 100% !important; }
  iframe, video {
    width: 90%; } }

/* END Style popup_video  */
/* ------------------------------------------ */
/* Style for feature-listing */
/* ------------------------------------------ */
.feature-listing {
  position: relative; }
  .feature-listing em {
    font-style: normal;
    font-weight: bold;
    color: #fb5004; }

.feature-listing__head {
  position: relative;
  background: #fb5004;
  color: #FFF;
  font-size: 12px;
  padding: 10px 15px;
  padding-left: 35px; }
  .feature-listing__head h2,
  .feature-listing__head h3,
  .feature-listing__head h4,
  .feature-listing__head h5 {
    margin: 0; }
  .feature-listing__head:before {
    content: '';
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    top: 50%;
    left: 15px;
    margin-top: -7.5px;
    background: url("./assets/images/common/icons/svg/white/icon-bookmark.svg") no-repeat center center;
    background-size: 100%; }

.feature-listing__items {
  position: relative;
  background: #fff3ec;
  padding: 15px 5%; }

.feature-listing__item {
  padding: 20px 0;
  width: 100%;
  border-bottom: 1px solid #777; }
  .feature-listing__item:last-child {
    border-bottom: 0; }

.feature-listing__item-big-title {
  margin: 0 0 15px; }

.feature-listing__item-row {
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  @media screen and (max-width: 480px) {
    .feature-listing__item-row {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; } }

.feature-listing__img {
  position: relative;
  padding-right: 20px;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; }
  @media screen and (max-width: 480px) {
    .feature-listing__img {
      width: 100%;
      margin-bottom: 10px; } }

.feature-listing__thumb {
  display: inline-block;
  border: 1px solid #777;
  margin-right: 10px;
  width: 160px;
  cursor: zoom-in; }
  .feature-listing__thumb:last-child {
    margin-right: 0; }
  @media screen and (max-width: 991px) {
    .feature-listing__thumb {
      width: 120px; } }
  @media screen and (max-width: 640px) {
    .feature-listing__thumb {
      width: 100px; } }

.feature-listing__img-holder:hover .feature-listing__pop {
  display: block; }

.feature-listing__img-holder:hover:after {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 30px;
  left: 0;
  bottom: 100%;
  z-index: 500; }

.feature-listing__pop {
  position: absolute;
  background: #FFF;
  font-size: 0;
  width: 480px;
  z-index: 1000;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  left: 0;
  bottom: 100%;
  margin-bottom: 30px;
  display: none; }
  .feature-listing__pop:hover {
    display: block; }

.feature-listing__popcap {
  background: #fb5004;
  color: #FFF;
  padding: 15px 30px; }

.feature-listing__popcap-title em {
  color: #FFF; }

.feature-listing__popcap-btns {
  margin-top: 10px; }
  .feature-listing__popcap-btns .feature-listing__btn {
    color: #FFF;
    border-color: #FFF;
    margin: 0;
    margin-right: 10px;
    margin-bottom: 10px; }

.feature-listing__item-head {
  display: block;
  margin: 0 0 5px; }

.feature-listing__item-title {
  display: inline-block;
  margin: 0;
  margin-right: 10px;
  font-size: 15px;
  font-weight: bold; }

.feature-listing__infos {
  position: relative;
  font-size: 0;
  margin: 0 0 5px; }

.feature-listing__info {
  display: inline-block;
  font-size: 12px;
  margin-right: 10px;
  padding-right: 10px;
  position: relative; }
  .feature-listing__info:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    height: 16px;
    margin-top: -8px;
    width: 1px;
    background: #1a1a1a; }
  .feature-listing__info:last-child:after {
    display: none;
    margin-right: 0;
    padding-right: 0; }

.feature-listing__desc {
  font-size: 14px;
  line-height: 1.5;
  margin: 0 0 5px; }

.feature-listing__specs {
  position: relative;
  font-size: 0; }
  @media screen and (max-width: 640px) {
    .feature-listing__specs {
      display: block;
      margin: 10px 0; } }

.feature-listing__spec {
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;
  padding-right: 10px;
  position: relative; }
  .feature-listing__spec:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    height: 16px;
    margin-top: -8px;
    width: 1px;
    background: #1a1a1a; }
  .feature-listing__spec:last-child:after {
    display: none;
    margin-right: 0;
    padding-right: 0; }

.feature-listing__btn {
  margin: 10px 0 0;
  margin-right: 5px;
  padding: 8px 20px; }

.feature-listing__note-icons {
  display: inline-block;
  vertical-align: middle; }
  @media screen and (max-width: 640px) {
    .feature-listing__note-icons {
      display: block;
      margin: 10px 0; } }

.feature-listing__icons {
  margin-top: 5px; }

.feature-listing__icon {
  display: inline-block;
  vertical-align: middle;
  width: 70px;
  height: 22px;
  margin-right: 10px;
  background: url("./assets/images/common/icons/img-search-icons.png") no-repeat;
  background-position-x: 0;
  background-position-y: 0; }
  .feature-listing__icon:last-child {
    margin-right: 0; }
  .feature-listing__icon--booth {
    width: 62px;
    height: 24px;
    background-size: 120px auto;
    background-position-x: 0;
    background-position-y: -542px; }
  .feature-listing__icon--small-order {
    height: 24px;
    background-size: 120px auto;
    background-position-x: 0;
    background-position-y: -1022px; }
  .feature-listing__icon--db-checked {
    width: 62px;
    height: 20px;
    background-size: 120px auto;
    background-position-x: 0;
    background-position-y: -744px; }
  .feature-listing__icon--cert-verified {
    width: 62px;
    height: 20px;
    background-size: 120px auto;
    background-position-x: 0;
    background-position-y: -947px; }
  .feature-listing__icon--premier-adv {
    width: 62px;
    height: 20px;
    background-size: 120px auto;
    background-position-x: 0;
    background-position-y: -246px; }
  .feature-listing__icon--supplier-ref {
    width: 62px;
    height: 20px;
    background-size: 120px auto;
    background-position-x: 0;
    background-position-y: -280px;
    background-position-y: -273px; }

/* END Style feature-listing  */
/* ------------------------------------------ */
/* Style for feedback-panel */
/* ------------------------------------------ */
.feedback-panel {
  display: none; }

.feedback-panel__btn {
  cursor: pointer;
  display: none;
  border: 0;
  box-shadow: none;
  position: fixed;
  bottom: 45px;
  right: 45px;
  margin-right: 2px;
  padding: 8px 15px;
  padding-right: 20px;
  transition: 0.3s;
  background: #dadada;
  color: #1a1a1a;
  z-index: 150;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  [data-cookies-bar="show"] .feedback-panel__btn {
    bottom: 90px; }
  .feedback-panel__btn:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    background: url("./assets/images/common/icons/svg/black/icon-star.svg") no-repeat center center;
    background-size: 100%;
    margin-right: 8px; }
  .feedback-panel__btn span {
    display: inline-block;
    vertical-align: middle; }
  @media screen and (max-width: 767px) {
    .feedback-panel__btn {
      bottom: 30px;
      right: 5%; } }
  @media screen and (max-width: 480px) {
    [data-cookies-bar="show"] .feedback-panel__btn {
      bottom: 130px; } }
  .feedback-panel__btn:hover {
    box-shadow: 0 7px 14px rgba(0, 0, 0, 0.25), 0 3px 3px rgba(0, 0, 0, 0.22); }
    .feedback-panel__btn:hover:before {
      background-image: url("./assets/images/common/icons/svg/white/icon-star.svg"); }

/* END Style feedback-panel  */
/* ------------------------------------------ */
/* Style for footer */
/* ------------------------------------------ */
footer {
  position: relative; }

.footer__body {
  position: relative;
  padding: 80px 0; }
  @media screen and (max-width: 991px) {
    .footer__body {
      padding: 60px 0; } }

/* END Style footer  */
/* ------------------------------------------ */
/* Style for footer-bar */
/* ------------------------------------------ */
.footer-bar {
  position: relative;
  width: 100%;
  background: #fb5004;
  padding: 30px 0;
  color: #FFF;
  text-align: center; }
  @media screen and (max-width: 991px) {
    .footer-bar {
      text-align: left; } }

.footer-bar__title {
  display: inline-block;
  vertical-align: middle;
  color: #FFF;
  margin: 0;
  font-weight: 300; }

.footer-bar__desc {
  display: inline-block;
  vertical-align: middle;
  color: #FFF;
  margin: 10px 60px;
  line-height: 1.5;
  font-weight: 300; }
  @media screen and (max-width: 991px) {
    .footer-bar__desc {
      display: block;
      margin-left: 0;
      margin-right: 0; } }

.footer-bar__arrow {
  display: inline-block;
  vertical-align: middle; }
  .footer-bar__arrow:before {
    content: '';
    width: 45px;
    height: 45px;
    display: block;
    background: url() no-repeat center center;
    background-image: url("./assets/images/common/icons/svg/white/icon-long-arrow.svg");
    background-size: 100%; }
    @media screen and (max-width: 991px) {
      .footer-bar__arrow:before {
        width: 24px;
        height: 24px; } }

/* END Style footer-bar  */
/* ------------------------------------------ */
/* Style for footer-bottom */
/* ------------------------------------------ */
.footer-bottom {
  text-align: center; }
  @media screen and (max-width: 640px) {
    .footer-bottom {
      text-align: left; } }

.footer-bottom__logo {
  display: block;
  margin: 0 auto 20px; }
  @media screen and (max-width: 640px) {
    .footer-bottom__logo {
      margin-top: 0;
      text-align: left; } }

.footer-bottom__row {
  text-align: center;
  margin-bottom: 10px; }
  @media screen and (max-width: 640px) {
    .footer-bottom__row {
      display: inline; }
      .footer-bottom__row + .footer-bottom__row {
        margin-top: -10px; } }
  .footer-bottom__row:first-child {
    margin-top: 45px; }
    @media screen and (max-width: 991px) {
      .footer-bottom__row:first-child {
        margin-top: 0; } }
  @media screen and (max-width: 991px) {
    .footer-bottom__row.footer-bottom__row--lang {
      display: none; } }
  @media screen and (max-width: 640px) {
    .footer-bottom__row {
      text-align: left; } }

.footer-bottom__item {
  position: relative;
  display: inline-block;
  padding: 0 15px; }
  @media screen and (max-width: 1580px) {
    .footer-bottom__item {
      padding: 0 10px; } }
  @media screen and (max-width: 640px) {
    .footer-bottom__item {
      width: 50%;
      text-align: left;
      margin-bottom: 15px;
      vertical-align: top;
      padding: 0;
      font-size: 14px; } }
  .footer-bottom__item:after {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    height: 14px;
    right: 0;
    top: 50%;
    margin-top: -7px;
    background: #1a1a1a; }
    @media screen and (max-width: 640px) {
      .footer-bottom__item:after {
        display: none; } }
  .footer-bottom__item:last-child:after {
    display: none; }
  .footer-bottom__item:nth-child(2n+1) {
    padding-right: 10px; }
  @media screen and (max-width: 640px) {
    .footer-bottom__item.footer-bottom__item--hidden-mb {
      display: none; } }

.footer-bottom__row--lang .footer-bottom__link {
  cursor: pointer;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: inline-block;
  vertical-align: middle;
  background: url() no-repeat center center;
  background-size: 100%; }

.footer-bottom__row--lang .footer-bottom__link-German {
  background-image: url("./assets/images/common/icons/img-footer-lang.gif");
  background-repeat: no-repeat;
  background-position: -12px center;
  background-size: 690px; }

.footer-bottom__row--lang .footer-bottom__link-Czech {
  background-image: url("./assets/images/common/icons/img-footer-lang.gif");
  background-repeat: no-repeat;
  background-position: -633px center;
  background-size: 690px; }

.footer-bottom__row--lang .footer-bottom__link-Spanish {
  background-image: url("./assets/images/common/icons/img-footer-lang.gif");
  background-repeat: no-repeat;
  background-position: -84px center;
  background-size: 690px; }

.footer-bottom__row--lang .footer-bottom__link-French {
  background-image: url("./assets/images/common/icons/img-footer-lang.gif");
  background-repeat: no-repeat;
  background-position: -155px center;
  background-size: 690px; }

.footer-bottom__row--lang .footer-bottom__link-Italian {
  background-image: url("./assets/images/common/icons/img-footer-lang.gif");
  background-repeat: no-repeat;
  background-position: -225px center;
  background-size: 690px; }

.footer-bottom__row--lang .footer-bottom__link-Portuguese {
  width: 60px;
  background-image: url("./assets/images/common/icons/img-footer-lang.gif");
  background-repeat: no-repeat;
  background-position: -298px center;
  background-size: 690px; }

.footer-bottom__row--lang .footer-bottom__link-Japanese {
  background-image: url("./assets/images/common/icons/img-footer-lang.gif");
  background-repeat: no-repeat;
  background-position: -377px center;
  background-size: 690px; }

.footer-bottom__row--lang .footer-bottom__link-Korean {
  background-image: url("./assets/images/common/icons/img-footer-lang.gif");
  background-repeat: no-repeat;
  background-position: -441px center;
  background-size: 690px; }

.footer-bottom__row--lang .footer-bottom__link-Russian {
  background-image: url("./assets/images/common/icons/img-footer-lang.gif");
  background-repeat: no-repeat;
  background-position: -511px center;
  background-size: 690px; }

.footer-bottom__row--lang .footer-bottom__link-Arabric {
  background-image: url("./assets/images/common/icons/img-footer-lang.gif");
  background-repeat: no-repeat;
  background-position: -577px center;
  background-size: 690px; }

.footer-bottom__row--lang .footer-bottom__link-Polish {
  background-image: url("./assets/images/common/icons/img-lang-full.png");
  background-repeat: no-repeat;
  background-position: -335px center;
  background-size: 700px; }

.footer-bottom__copyright-title {
  display: inline-block;
  margin-right: 1em; }
  @media screen and (max-width: 640px) {
    .footer-bottom__copyright-title {
      display: block;
      margin-right: 0;
      margin-bottom: 5px; } }

@media screen and (max-width: 640px) {
  .footer-bottom__logo-img-dt {
    display: none; } }

.footer-bottom__logo-img-mb {
  max-width: 240px; }
  @media screen and (min-width: 641px) {
    .footer-bottom__logo-img-mb {
      display: none; } }

/* END Style footer-bottom  */
/* ------------------------------------------ */
/* Style for header */
/* ------------------------------------------ */
* {
  line-height: 1.2; }

.header__item.hidden-item {
  display: none; }
  @media screen and (max-width: 767px) {
    .header__item.hidden-item {
      display: block; } }

header {
  background: transparent;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 500;
  color: #FFF;
  box-sizing: content-box;
  transition: height 0.3s;
  padding: 35px 0 30px;
  padding-bottom: 0;
  /*
    @media screen and (max-width:991px) {
        height: $headerHeightTb;
        .header--small & {
            height: $headerHeightTb;
            background: rgba(#FFF, .95);
        }
    }
    @media screen and (max-width:767px) {
        height: $headerHeightMb;
        .header--small & {
            height: $headerHeightTb;
            height: $headerHeightMb;
            background: rgba(#FFF, .95);
        }
    }
    */ }
  @media screen and (max-width: 991px) {
    header {
      padding: 35px 0; } }
  .header--small header {
    color: #1a1a1a;
    height: 75px;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 3px 4px rgba(150, 150, 150, 0.2); }
  @media screen and (min-width: 992px) {
    header.header--sub-portal {
      padding: 10px 0 15px; } }

.header__wrapper {
  max-width: 1440px;
  display: block; }

.header__top {
  /*
    position: relative;
    text-align: right;
    padding: 10px 0 0;
    &+.header__main {
        padding-top: 0;
    }*/
  z-index: 1000;
  width: 100%;
  top: 0;
  padding: 10px 0;
  position: absolute;
  text-align: right; }
  .search-panel-open .header__top {
    pointer-events: none;
    opacity: 0.5;
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    filter: blur(2px); }
  @media screen and (max-width: 991px) {
    .header__top {
      display: none; } }

.header__right {
  right: 0; }
  @media screen and (max-width: 991px) {
    .header__right {
      position: absolute; } }

.header__main {
  position: relative;
  padding: 10px 0; }
  @media screen and (max-width: 991px) {
    .header__main {
      padding: 0; } }
  .header__main .header__wrapper {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    @media screen and (min-width: 992px) {
      .header--sub-portal .header__main .header__wrapper {
        -webkit-align-items: flex-end;
        align-items: flex-end;
        -ms-flex-align: flex-end; } }

.header__item {
  display: inline-block;
  width: 100%;
  text-align: center; }

.header__mob-lang {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  background: url("./assets/images/common/icons/svg/black/icon-lang.svg") no-repeat center center;
  background-size: 100%;
  margin-left: 15px; }
  @media screen and (max-width: 350px) {
    .header__mob-lang {
      margin-left: 12px; } }

.header__mob-share {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  background: url("./assets/images/common/icons/svg/black/icon-share-header.svg") no-repeat center center;
  background-size: 100%;
  margin-left: 15px; }
  @media screen and (max-width: 350px) {
    .header__mob-share {
      margin-left: 12px; } }

.header__mob-menu {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  margin-left: 15px;
  border-top: 2px solid #333; }
  @media screen and (max-width: 350px) {
    .header__mob-menu {
      margin-left: 12px; } }
  .header__mob-menu:before, .header__mob-menu:after {
    content: '';
    background: #333;
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    left: 0; }
  .header__mob-menu:before {
    top: 50%;
    margin-top: -2px; }
  .header__mob-menu:after {
    width: 70%;
    bottom: 0; }

.header__submenu-back {
  position: absolute;
  top: 90px;
  font-weight: bold;
  left: 5%;
  margin-left: 8px;
  display: none; }
  .header__submenu-back:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -4px;
    margin-right: 5px;
    width: 0.4em;
    height: 0.4em;
    border-top: 2px solid #fb5004;
    border-right: 2px solid #fb5004;
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg); }
  @media screen and (max-width: 991px) {
    .on-submenu .header__submenu-back {
      display: block; } }

.header__mob-close {
  position: absolute;
  display: none;
  top: 50%;
  margin-top: -10px;
  margin-right: -10px;
  right: 5%;
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }
  @media screen and (max-width: 991px) {
    .mob-menu-open .header__mob-close {
      display: block;
      z-index: 1500; } }
  .header__mob-close:before, .header__mob-close:after {
    content: '';
    background: #333;
    position: absolute;
    display: block; }
  .header__mob-close:before {
    width: 100%;
    height: 2px;
    left: 0;
    top: 50%;
    margin-top: -1px; }
  .header__mob-close:after {
    height: 100%;
    width: 2px;
    top: 0;
    left: 50%;
    margin-left: -1px; }

@media screen and (min-width: 992px) {
  .header__mob-lang,
  .header__mob-share,
  .header__mob-menu,
  .header__mob-close {
    display: none; } }

/* END Style header  */
/* ------------------------------------------ */
/* Style for header-logo */
/* ------------------------------------------ */
.header-logo {
  /*
    position:relative;
    display:inline-block;
    vertical-align: middle;
    */
  z-index: 150;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 105px;
  font-size: 0; }
  @media screen and (max-width: 1580px) {
    .header-logo {
      width: 90px; } }
  @media screen and (max-width: 991px) {
    .header-logo {
      width: 65px;
      z-index: 1500; } }
  @media screen and (max-width: 767px) {
    .header-logo {
      width: 60px; } }
  @media screen and (max-width: 480px) {
    .header-logo {
      width: 55px; } }
  @media screen and (min-width: 992px) {
    .header--sub-portal .header-logo {
      position: relative;
      top: auto;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0); }
    .header-logo .header-logo__img {
      /*testtestest*/
      width: 84px;
      height: 59px; } }

.header-logo__tag {
  display: block;
  margin-top: 8px;
  font-size: 12px;
  white-space: nowrap;
  padding: 0 8%;
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center; }
  @media screen and (max-width: 991px) and (min-width: 480px) {
    .header-logo__tag {
      position: absolute;
      left: 100%;
      bottom: 5px;
      margin-left: 10px;
      padding-left: 10px;
      border-left: 3px solid #ee7700; } }
  @media screen and (max-width: 540px) {
    .header-logo__tag {
      margin-left: 5px;
      padding-left: 10px;
      -webkit-transform: scale(0.8, 0.8);
      -ms-transform: scale(0.8, 0.8);
      transform: scale(0.8, 0.8); } }
  @media screen and (max-width: 480px) {
    .header-logo__tag {
      margin-left: 0;
      margin-top: 2px;
      margin-bottom: -5px;
      padding-left: 8%; } }
  @media screen and (max-width: 480px) {
    .header-logo__tag {
      -webkit-transform: scale(0.6, 0.6);
      -ms-transform: scale(0.6, 0.6);
      transform: scale(0.6, 0.6); } }

/* END Style header-logo  */
/* ------------------------------------------ */
/* Style for .header-search */
/* ------------------------------------------ */
.header-search {
  display: inline-block; }

.header-search__btn {
  position: relative;
  display: inline-block;
  color: #FFF;
  background: #fb5004;
  padding: 15px 25px;
  padding-left: 25px;
  border-radius: 30px;
  white-space: nowrap;
  z-index: 120; }
  .header-search__btn:hover {
    color: #FFF; }
  @media screen and (max-width: 991px) {
    .header-search__btn {
      padding: 12px 20px;
      padding-left: 15px; } }
  @media screen and (max-width: 767px) {
    .header-search__btn {
      padding: 8px 15px;
      padding-left: 12px;
      font-size: 14px; } }
  @media screen and (max-width: 350px) {
    .header-search__btn {
      padding: 8px 12px;
      padding-left: 8px; } }
  .header-search__btn:before {
    content: '';
    width: 18px;
    height: 18px;
    background: url("./assets/images/common/icons/svg/white/icon-search.svg") no-repeat center center;
    background-size: 100%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px; }
    @media screen and (max-width: 991px) {
      .header-search__btn:before {
        width: 16px;
        height: 16px; } }
    @media screen and (max-width: 767px) {
      .header-search__btn:before {
        width: 14px;
        height: 14px; } }
    @media screen and (max-width: 350px) {
      .header-search__btn:before {
        margin-right: 5px; } }
  .search-panel-open .header-search__btn:before {
    background-image: url("./assets/images/common/icons/svg/white/icon-close.svg"); }

.header-search__input {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: #FFF;
  background: #fb5004;
  border: 0; }
  @media screen and (max-width: 991px) {
    .header-search__input {
      font-size: 14px; } }
  @media screen and (max-width: 767px) {
    .header-search__input {
      font-size: 12px; } }
  .header-search__input:focus {
    outline: none; }
  .header-search__input::-webkit-input-placeholder {
    color: #FFF; }
  .header-search__input:-moz-placeholder {
    /* Firefox 18- */
    color: #FFF; }
  .header-search__input::-moz-placeholder {
    /* Firefox 19+ */
    color: #FFF; }
  .header-search__input:-ms-input-placeholder {
    color: #FFF; }

.header-search__panel {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 120;
  background: rgba(255, 255, 255, 0.85);
  z-index: 100;
  display: none;
  padding-top: 300px; }
  .search-panel-open .header-search__panel {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    overflow: scroll; }

.header-search__wrapper {
  margin-top: -45px;
  text-align: center; }

.header-search__title {
  color: #fb5004;
  display: block;
  margin: 0 0 30px;
  text-align: center;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase; }

.header-search__txt-close {
  display: none;
  vertical-align: middle; }
  .search-panel-open .header-search__txt-close {
    display: inline-block; }

.header-search__txt-open {
  display: inline-block;
  vertical-align: middle; }
  .search-panel-open .header-search__txt-open {
    display: none; }

.header-search__input-holder {
  display: block;
  position: relative;
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 40px;
  border: 0; }

.header-search__input-btn {
  position: absolute;
  width: 100px;
  height: 80px;
  right: 0;
  top: 0;
  background: transparent;
  border: 0;
  cursor: pointer; }
  .header-search__input-btn:before {
    content: '';
    width: 24px;
    height: 24px;
    background: url("./assets/images/common/icons/svg/orange/icon-search.svg") no-repeat center center;
    background-size: 100%;
    display: inline-block;
    vertical-align: middle; }

.header-serach__input {
  background: #ececec;
  position: relative;
  width: 100%;
  height: 80px;
  border: 0;
  padding-left: 50px;
  padding-right: 60px; }
  .header-serach__input::-webkit-search-cancel-button {
    display: none; }
  .header-serach__input:focus {
    outline: none; }
  .header-serach__input + .header-search__autocomplete {
    display: none; }
  .header-serach__input:focus + .header-search__autocomplete,
  .header-serach__input + .header-search__autocomplete:focus,
  .header-serach__input + .header-search__autocomplete:hover {
    display: block; }
  .header-serach__input::-ms-clear {
    display: none; }

.header-search__filters {
  margin-bottom: 25px;
  font-size: 0; }

.header-search__filter {
  display: inline-block;
  margin-right: 45px; }
  .header-search__filter:last-child {
    margin-right: 0; }
  .header-search__filter input {
    display: none; }
    .header-search__filter input + label {
      position: relative;
      display: block;
      cursor: pointer;
      font-size: 14px;
      color: #1a1a1a; }
    .header-search__filter input:checked + label {
      font-weight: bold;
      padding: 5px 0; }
      .header-search__filter input:checked + label:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3px;
        background: #fb5004; }

.header-search__topsearch {
  margin-top: 25px;
  font-size: 12px; }

.header-search__topsearch-title {
  display: inline-block;
  vertical-align: middle;
  color: #fb5004;
  font-weight: bold;
  margin: 0;
  margin-right: 25px; }

.header-search__topsearch-item {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  margin-right: 15px; }

.header-search__autocomplete {
  position: relative;
  width: 100%;
  background: #FFF;
  text-align: left;
  padding-bottom: 25px; }

.header-search__autocomplet-grp {
  padding: 0 50px; }

.header-search__autocomplet-item {
  display: block;
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 14px; }

.header-search__autocomplet-subitem {
  display: block;
  padding-left: 15px;
  font-size: 12px;
  margin: 0 0 14px; }

/* END Style .header-search  */
/* ------------------------------------------ */
/* Style for hktdc-events */
/* ------------------------------------------ */
.hktdc-events {
  margin-top: 60px;
  margin-bottom: 60px; }
  @media screen and (max-width: 991px) {
    .hktdc-events {
      margin-top: 0; } }

.hktdc-events__row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }

.hktdc-events__col {
  width: 33.3333%;
  padding: 0 30px;
  opacity: 0; }
  .hktdc-events__col.animated.fadeIn {
    -webkit-animation: hktdc-events 0.3s;
    animation: hktdc-events 0.3s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }
    .hktdc-events__col.animated.fadeIn:nth-child(2) {
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s; }
    .hktdc-events__col.animated.fadeIn:nth-child(3) {
      -webkit-animation-delay: 0.6s;
      animation-delay: 0.6s; }

@-webkit-keyframes hktdc-events {
  0% {
    opacity: 0;
    -webkit-transform: translateY(45px);
    -ms-transform: translateY(45px);
    transform: translateY(45px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@keyframes hktdc-events {
  0% {
    opacity: 0;
    -webkit-transform: translateY(45px);
    -ms-transform: translateY(45px);
    transform: translateY(45px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }
  @media screen and (max-width: 1280px) {
    .hktdc-events__col {
      padding: 0 15px; } }
  @media screen and (max-width: 991px) {
    .hktdc-events__col {
      width: 50%; }
      .hktdc-events__col:first-child {
        width: 100%;
        text-align: center;
        padding: 30px 0; } }
  @media screen and (max-width: 767px) {
    .hktdc-events__col {
      width: 100%;
      padding: 0;
      margin-bottom: 20px; }
      .hktdc-events__col:first-child {
        padding: 20px 0 0; } }

.hktdc-events__tag {
  position: relative;
  color: #fb5004;
  padding: 0 30px;
  text-transform: uppercase; }
  @media screen and (max-width: 991px) {
    .hktdc-events__tag {
      display: block;
      padding: 25px 0;
      text-align: center;
      color: #FFF;
      width: 100%; }
      .hktdc-events__tag:after {
        content: '';
        position: absolute;
        display: block;
        width: 100vw;
        left: -3.19148%;
        height: 100%;
        top: 0;
        z-index: 0;
        background: #fd8811;
        /* Old browsers */
        background: -moz-linear-gradient(left, #fd8811 0%, #fb4805 50%, #fd8811 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(left, #fd8811 0%, #fb4805 50%, #fd8811 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, #fd8811 0%, #fb4805 50%, #fd8811 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fd8811', endColorstr='#fd8811', GradientType=1);
        /* IE6-9 */ } }
    @media screen and (max-width: 991px) and (max-width: 991px) {
      .hktdc-events__tag:after {
        left: -5.55556%; } }
  @media screen and (max-width: 991px) {
      .hktdc-events__tag span {
        position: relative;
        z-index: 10; } }

.hktdc-events__title {
  color: #fb5004;
  font-weight: 300;
  margin: 0 0 15px; }

.hktdc-events__intro {
  line-height: 1.5em; }

.hktdc-events__filter {
  position: relative;
  display: inline-block;
  margin-right: 30px;
  padding: 5px 0;
  font-size: 18px; }
  @media screen and (max-width: 767px) {
    .hktdc-events__filter {
      margin-right: 15px;
      font-size: 16px; } }
  .hktdc-events__filter:last-child {
    margin-right: 0; }
  .hktdc-events__filter.is-active {
    font-weight: bold; }
    .hktdc-events__filter.is-active:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      display: block;
      background: #000; }

.hktdc-events__item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px;
  padding-right: 20px;
  padding-left: 0; }

.hktdc-events__list {
  position: relative;
  max-height: 240px;
  overflow: auto; }

.hktdc-events__list-title {
  font-weight: 300;
  margin: 0 0 15px; }
  @media screen and (max-width: 767px) {
    .hktdc-events__list-title {
      text-align: center; } }

.hktdc-events__date {
  width: 60px;
  height: 60px;
  color: #FFF;
  background: #fb5004;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 15px; }
  .hktdc-events__item--os .hktdc-events__date {
    background: #242655; }
  @media screen and (max-width: 1280px) {
    .hktdc-events__date {
      width: 55px;
      height: 55px; } }

.hktdc-events__info {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 5px; }

.hktdc-events__info-loc {
  font-weight: bold;
  color: #fb5004;
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid #1a1a1a; }
  .hktdc-events__item--os .hktdc-events__info-loc {
    color: #242655; }
  @media screen and (max-width: 1280px) {
    .hktdc-events__info-loc {
      padding-right: 7px;
      margin-right: 7px; } }

.hktdc-events__days {
  display: block; }

.hktdc-events__legends {
  position: relative;
  display: block;
  padding: 0 8%; }
  @media screen and (max-width: 767px) {
    .hktdc-events__legends {
      text-align: center; } }

.hktdc-events__legend {
  display: inline-block;
  position: relative;
  padding-left: 15px;
  margin-right: 25px; }
  .hktdc-events__legend:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: #fb5004;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    border-radius: 50%; }
  .hktdc-events__legend.hktdc-events__legend--hk:before {
    background: #fb5004; }
  .hktdc-events__legend.hktdc-events__legend--os:before {
    background: #242655; }

.hktdc-events .DayPicker-Month {
  margin-top: 0; }

.hktdc-events .DayPicker {
  display: block;
  margin: 0 auto; }

.hktdc-events .DayPicker-NavButton {
  top: 0; }

.hktdc-events .DayPicker-NavButton--prev {
  right: 50%;
  margin: -1px 0 0;
  margin-right: 75px; }

.hktdc-events .DayPicker-NavButton--next {
  left: 50%;
  margin: -1px 0 0;
  margin-left: 75px; }

.hktdc-events .DayPicker-Caption {
  text-align: center; }

.hktdc-events .DayPicker-Day {
  padding: 0.5rem 1rem; }
  @media screen and (max-width: 1280px) {
    .hktdc-events .DayPicker-Day {
      padding: 0.5rem 0.8rem; } }

.hktdc-events .DayPicker-Day--hlhk,
.hktdc-events .DayPicker-Day--hlos {
  position: relative; }
  .hktdc-events .DayPicker-Day--hlhk:after,
  .hktdc-events .DayPicker-Day--hlos:after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    background: #fb5004;
    bottom: 1px;
    left: 50%;
    margin-left: -2.5px;
    position: absolute; }

.hktdc-events .DayPicker-Day--hlhk:after {
  background: #fb5004; }

.hktdc-events .DayPicker-Day--hlos:after {
  background: #242655; }

.hktdc-events .DayPicker-Day--hlhk.DayPicker-Day--hlos {
  position: relative; }
  .hktdc-events .DayPicker-Day--hlhk.DayPicker-Day--hlos:before, .hktdc-events .DayPicker-Day--hlhk.DayPicker-Day--hlos:after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    background: #fb5004;
    bottom: 1px;
    left: 50%;
    margin-left: -2.5px;
    position: absolute; }
  .hktdc-events .DayPicker-Day--hlhk.DayPicker-Day--hlos:before {
    left: calc(50% - 4px);
    background: #fb5004; }
  .hktdc-events .DayPicker-Day--hlhk.DayPicker-Day--hlos:after {
    left: calc(50% + 4px);
    background: #242655; }

.hktdc-events .DayPicker-Day--picked.DayPicker-Day--hlhk, .hktdc-events .DayPicker-Day--picked.DayPicker-Day--hlos {
  color: #FFF; }
  .hktdc-events .DayPicker-Day--picked.DayPicker-Day--hlhk:before, .hktdc-events .DayPicker-Day--picked.DayPicker-Day--hlos:before {
    content: '';
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    top: 50%;
    left: 50%;
    margin-top: -7.5px;
    margin-left: -7.5px; }

.hktdc-events .DayPicker-Day--picked.DayPicker-Day--hlhk {
  background: #fb5004; }

.hktdc-events .DayPicker-Day--picked.DayPicker-Day--hlos {
  background: #242655; }

/* END Style hktdc-events  */
/* ------------------------------------------ */
/* Style for sample */
/* ------------------------------------------ */
/* END Style sample  */
/* ------------------------------------------ */
p.shortDesc {
  width: 50%;
  visibility: hidden;
  position: absolute; }

.management-twomen .wrapper .bio p.shortDesc {
  width: 30vw; }

.management-threemen .wrapper .bio p.shortDesc {
  width: 20vw; }

.management-onemen {
  background-size: cover;
  /*background-position: center;*/
  width: 100%;
  padding: 40px 0px; }

.management-onemen .wrapper,
.management-twomen .wrapper,
.management-threemen .wrapper,
.management-manymen .wrapper {
  max-width: 1440px;
  margin: auto;
  display: flex;
  padding: 0;
  margin: auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: center; }

.management-twomen .wrapper,
.management-threemen .wrapper {
  padding: 60px 0px; }

.management-manymen .wrapper {
  flex-wrap: wrap;
  padding-bottom: 100px; }

.management-threemen .wrapper {
  justify-content: space-evenly; }

.management-threemen .wrapper > div {
  margin: 0 10px; }

.management-twomen .wrapper > div:first-child:not(.img-holder) {
  border-right: solid 1px #f2d5c6;
  margin-right: 80px;
  padding-right: 80px; }

.management-onemen .wrapper .bio {
  width: 50%; }

.management-threemen .wrapper {
  position: relative;
  margin-bottom: 100px; }

.management-onemen p,
.management-twomen p,
.management-threemen p,
.management-manymen p {
  margin: 0;
  padding: 0; }

.management-onemen p.name {
  color: black;
  font-size: 24px;
  margin-bottom: 5px;
  font-weight: bold; }

.management-onemen p.position {
  color: #fb5004;
  font-weight: 700; }

.management-onemen p.desc {
  color: #000; }

.management-onemen p.desc {
  /*font-size: 14px; line-height: 20px;*/
  margin-top: 17px;
  line-height: 1.5em; }

.management-onemen img,
.management-twomen img,
.management-threemen img,
.management-manymen img {
  border-radius: 50%;
  width: 90px;
  height: 90px;
  object-fit: cover;
  margin-right: 40px;
  object-position: 0 -10px; }

.management-onemen .img-holder,
.management-twomen .img-holder,
.management-threemen .img-holder,
.management-manymen .img-holder {
  display: inline-block;
  border-radius: 50%;
  min-width: 90px;
  width: 90px;
  height: 90px;
  object-fit: cover;
  margin-right: 40px;
  object-position: 0 -10px;
  background: url() no-repeat center -10px;
  background-size: cover; }

.management-onemen img {
  width: 120px;
  height: 120px; }

.management-onemen .img-holder {
  width: 120px;
  height: 120px; }

.management-manymen .bio {
  margin-left: 20px; }

.management-manymen .img-holder,
.management-manymen img {
  margin-right: 0px; }

.management-twomen p.name,
.management-threemen p.name,
.management-manymen p.name {
  color: black;
  font-size: 16px;
  font-weight: bold;
  margin-top: 5px; }

.management-twomen p.position,
.management-threemen p.position,
.management-manymen p.position {
  color: #fb5004;
  font-size: 12px;
  font-weight: bold; }

.management-manymen p.position {
  margin-top: 5px; }

.management-twomen p.desc,
.management-threemen p.desc {
  color: #8B8B8B;
  font-size: 14px;
  line-height: 20px;
  margin-top: 16px; }

.management-twomen .wrapper .bio {
  width: 30vw;
  overflow: hidden; }

.management-threemen .wrapper .bio {
  width: 20vw;
  overflow: hidden; }

button.ExpandBtn,
.management-onemen button.ProfileBtn {
  color: #fb5004;
  background-color: transparent;
  padding: 7px 40px;
  border: solid 1px #fb5004;
  border-radius: 20px;
  outline: none;
  margin-right: 20px;
  margin-top: 20px; }
  button.ExpandBtn .download-icon:before,
  .management-onemen button.ProfileBtn .download-icon:before {
    content: "";
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: 5px;
    background: url() no-repeat 50%;
    background-image: url("./assets/images/common/icons/svg/orange/icon-download.svg");
    background-size: 100%;
    fill: #F05323; }
  button.ExpandBtn:hover,
  .management-onemen button.ProfileBtn:hover {
    background-color: #fb5004;
    color: #fff;
    border: solid 1px #fb5004; }
    button.ExpandBtn:hover .download-icon:before,
    .management-onemen button.ProfileBtn:hover .download-icon:before {
      background-image: url("./assets/images/common/icons/svg/white/icon-download.svg"); }

.management-twomen button.ExpandBtn,
.management-twomen button.ProfileBtn,
.management-threemen button.ExpandBtn,
.management-threemen button.ProfileBtn {
  border: solid 1px #fb5004;
  background-color: transparent;
  border-radius: 20px;
  outline: none;
  padding: 7px 40px;
  color: #fb5004;
  margin-top: 20px; }
  .management-twomen button.ExpandBtn .download-icon:before,
  .management-twomen button.ProfileBtn .download-icon:before,
  .management-threemen button.ExpandBtn .download-icon:before,
  .management-threemen button.ProfileBtn .download-icon:before {
    content: "";
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: 5px;
    background: url() no-repeat 50%;
    background-image: url("./assets/images/common/icons/svg/orange/icon-download.svg");
    background-size: 100%;
    fill: #F05323; }
  .management-twomen button.ExpandBtn:hover,
  .management-twomen button.ProfileBtn:hover,
  .management-threemen button.ExpandBtn:hover,
  .management-threemen button.ProfileBtn:hover {
    background-color: #fb5004;
    color: white; }
    .management-twomen button.ExpandBtn:hover .download-icon:before,
    .management-twomen button.ProfileBtn:hover .download-icon:before,
    .management-threemen button.ExpandBtn:hover .download-icon:before,
    .management-threemen button.ProfileBtn:hover .download-icon:before {
      background-image: url("./assets/images/common/icons/svg/white/icon-download.svg"); }

.management-onemen button:hover,
.management-twomen button:hover,
.management-threemen button:hover {
  cursor: pointer; }

.management-manymen .wrapper > div {
  width: 40%;
  border-top: solid 1px #E3E3E3;
  display: flex;
  align-items: center;
  padding: 20px 0;
  margin-right: 40px; }

.management-manymen .wrapper > div:nth-last-child(2),
.management-manymen .wrapper > div:last-child {
  border-bottom: solid 1px #E3E3E3; }

.management-manymen .wrapper .noBorder {
  border-bottom: none !important;
  padding: 0px 0px !important; }
  @media screen and (max-width: 767px) {
    .management-manymen .wrapper .noBorder {
      border-top: none !important; } }

.counil-committee {
  background-color: #f5f5f5;
  padding: 40px 0px;
  display: block;
  text-align: center;
  align-items: center;
  color: #333; }
  .counil-committee p {
    text-align: center;
    color: #242753;
    font-size: 24px;
    font-weight: 400; }
    .counil-committee p div hr.committeeLine {
      width: 50%;
      display: block;
      margin-top: 40px;
      margin-bottom: 40px;
      border: 1px solid #e5e5e5;
      border-collapse: collapse; }
    .counil-committee p div:last-child hr.committeeLine {
      display: none; }
    .counil-committee p div > span {
      margin-bottom: 20px; }
    @media screen and (max-width: 767px) {
      .counil-committee p div span {
        display: block; } }
  .counil-committee div {
    margin-top: 10px; }
    .counil-committee div:first-child {
      margin-top: 0; }
  .counil-committee a {
    border: 1px solid #fb5004;
    border-radius: 25px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 7px 40px;
    display: inline-flex; }
    .counil-committee a span {
      color: #fb5004;
      font-size: 16px; }
    .counil-committee a:hover {
      background-color: #fb5004;
      color: white; }
      .counil-committee a:hover span {
        color: #fff; }
      .counil-committee a:hover .download-icon:before {
        background-image: url("./assets/images/common/icons/svg/white/icon-download.svg"); }
    .counil-committee a .download-icon:before {
      content: "";
      width: 12px;
      height: 12px;
      display: inline-block;
      margin-right: 5px;
      background: url() no-repeat 50%;
      background-image: url("./assets/images/common/icons/svg/orange/icon-download.svg");
      background-size: 100%;
      fill: #F05323; }

@media screen and (max-width: 767px) {
  .management-onemen .wrapper,
  .management-twomen .wrapper,
  .management-threemen .wrapper,
  .management-manymen .wrapper {
    flex-direction: column;
    padding: 20px; }
  .management-onemen .wrapper .bio {
    width: 100%; }
  .management-onemen p.name {
    margin-top: 20px; }
  .management-twomen .wrapper .bio {
    width: 100%; }
  .management-twomen > .wrapper > div:first-child:not(.img-holder) {
    margin: 0;
    border-right: none;
    border-bottom: solid 1px #f2d5c6;
    padding: 20px 0px; }
  .management-twomen .wrapper > div:last-child {
    padding: 20px 0px; }
  .management-threemen .wrapper .bio {
    width: 100%; }
  .management-threemen button.ProfileBtn {
    position: initial; }
  .management-threemen .wrapper > div {
    margin-bottom: 60px;
    margin-right: 0;
    margin-left: 0; }
  .management-threemen .wrapper > div:last-child {
    margin-bottom: 0; }
  .management-threemen .wrapper {
    margin-bottom: 0; }
  .management-manymen .wrapper > div {
    width: 100%; }
  .management-manymen .wrapper:after {
    border: none; }
  .management-manymen .wrapper > div:last-child {
    margin-bottom: 40px; } }

/* Style for market-focus */
/* ------------------------------------------ */
.market-focus {
  position: relative; }

.market-focus__holder.animated.fadeIn {
  -webkit-animation: none;
  animation: none;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  will-change: transform;
  opacity: 1 !important; }

.market-focus__intro {
  text-align: center;
  margin-bottom: 45px; }

.market-focus__intro-title {
  margin: 0 0 15px;
  font-weight: 300;
  color: #fb5004; }
  .market-focus__holder.animated .market-focus__intro-title {
    opacity: 0; }
  .market-focus__holder.animated.fadeIn .market-focus__intro-title {
    -webkit-animation: mf-item-title 0.6s;
    animation: mf-item-title 0.6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }

@-webkit-keyframes mf-item-title {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes mf-item-title {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.market-focus__holder.animated .market-focus__intro-desc {
  opacity: 0; }

.market-focus__holder.animated.fadeIn .market-focus__intro-desc {
  -webkit-animation: mf-item-title 0.6s 0.3s;
  animation: mf-item-title 0.6s 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  will-change: transform; }

@-webkit-keyframes mf-item-title {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes mf-item-title {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.market-focus__items {
  position: relative; }
  .market-focus__items:before {
    content: '';
    position: absolute;
    display: block;
    width: 90%;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    transform: scale(0, 0);
    -webkit-transform-origin: right center;
    -ms-transform-origin: right center;
    transform-origin: right center;
    transition: 1s 0.3s;
    right: 0;
    top: 25%;
    height: 50%;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fb4805+0,fd8811+100 */
    background: #fb4805;
    /* Old browsers */
    background: -moz-linear-gradient(left, #fb4805 0%, #fd8811 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #fb4805 0%, #fd8811 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #fb4805 0%, #fd8811 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fb4805', endColorstr='#fd8811', GradientType=1);
    /* IE6-9 */ }
    .market-focus__holder.animated.fadeIn .market-focus__items:before {
      -webkit-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      transform: scale(1, 1); }
  .market-focus__items > .content-wrapper, .market-focus__items > .app-sec__inner, .market-focus__items > .header__wrapper, .market-focus__items > .search-nores, .market-focus__items > .sec-intro__detail-wrapper, .market-focus__items > .submenu__wrapper {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start; }
    @media screen and (max-width: 480px) {
      .market-focus__items > .content-wrapper, .market-focus__items > .app-sec__inner, .market-focus__items > .header__wrapper, .market-focus__items > .search-nores, .market-focus__items > .sec-intro__detail-wrapper, .market-focus__items > .submenu__wrapper {
        width: 100%; } }

.market-focus__item {
  position: relative;
  overflow: hidden;
  width: calc(33.3333% - 20px);
  margin-right: 30px;
  border-radius: 5px;
  opacity: 0;
  color: #FFF; }
  @media screen and (max-width: 480px) {
    .market-focus__item {
      background: #000;
      border-radius: 0; } }
  .market-focus__holder.animated.fadeIn .market-focus__item {
    -webkit-animation: mf-item 0.6s;
    animation: mf-item 0.6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }
    .market-focus__holder.animated.fadeIn .market-focus__item:hover {
      opacity: 0; }
    .market-focus__holder.animated.fadeIn .market-focus__item:nth-child(2) {
      -webkit-animation-delay: 0.72s;
      animation-delay: 0.72s; }
    .market-focus__holder.animated.fadeIn .market-focus__item:nth-child(3) {
      -webkit-animation-delay: 0.84s;
      animation-delay: 0.84s; }
    .market-focus__holder.animated.fadeIn .market-focus__item:nth-child(4) {
      -webkit-animation-delay: 0.96s;
      animation-delay: 0.96s; }
    .market-focus__holder.animated.fadeIn .market-focus__item:nth-child(5) {
      -webkit-animation-delay: 1.08s;
      animation-delay: 1.08s; }
    .market-focus__holder.animated.fadeIn .market-focus__item:nth-child(6) {
      -webkit-animation-delay: 1.2s;
      animation-delay: 1.2s; }
    .market-focus__holder.animated.fadeIn .market-focus__item:nth-child(7) {
      -webkit-animation-delay: 1.32s;
      animation-delay: 1.32s; }
    .market-focus__holder.animated.fadeIn .market-focus__item:nth-child(8) {
      -webkit-animation-delay: 1.44s;
      animation-delay: 1.44s; }

@-webkit-keyframes mf-item {
  0% {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@keyframes mf-item {
  0% {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }
  .market-focus__item:hover {
    opacity: 0;
    color: #FFF; }
  .market-focus__item:before {
    content: '';
    position: relative;
    padding-bottom: 100%;
    width: 100%;
    display: block; }
  .market-focus__item:nth-child(3n+3) {
    margin-right: 0; }
  .market-focus__item:nth-child(3n+2) {
    margin-top: 30px; }
    .market-focus__item:nth-child(3n+2):before {
      padding-top: 30px; }
  .market-focus__item:nth-child(2) {
    margin-top: 0; }
    .market-focus__item:nth-child(2):before {
      padding-top: 30px; }
  @media screen and (max-width: 767px) {
    .market-focus__item {
      width: calc(50% - 15px);
      margin-right: 30px;
      margin-bottom: 30px; }
      .market-focus__item:nth-child(3n+3) {
        margin-right: 30px; }
      .market-focus__item:nth-child(2n+2) {
        margin-right: 0; }
      .market-focus__item:nth-child(3n+2) {
        margin-top: 0; }
        .market-focus__item:nth-child(3n+2):before {
          padding-top: 0; } }
  @media screen and (max-width: 480px) {
    .market-focus__item {
      width: 100%;
      margin-right: 0;
      margin-bottom: 0; }
      .market-focus__item:nth-child(3n+3), .market-focus__item:nth-child(2n+2) {
        margin-right: 0; }
      .market-focus__item:before {
        padding-bottom: 50%; } }

.market-focus__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url() no-repeat center center;
  background-size: cover;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  transition: 0.4s; }
  @media screen and (max-width: 480px) {
    .market-focus__bg {
      opacity: 0.8; } }
  .market-focus__item:hover .market-focus__bg {
    -webkit-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1); }
  .market-focus__bg:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 45%;
    left: 0;
    bottom: 0;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
    /* IE6-9 */ }

.market-focus__info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 30px 15px; }

.market-focus__title {
  display: block;
  width: 100%;
  font-weight: 300;
  line-height: 1.2em;
  margin: 0 0 10px;
  text-transform: uppercase; }
  @media screen and (max-width: 991px) {
    .market-focus__title {
      font-size: 16px;
      margin: 0 0 5px; } }

.market-focus__arrow:before {
  content: '';
  width: 30px;
  height: 30px;
  display: block;
  background: url() no-repeat center center;
  background-image: url("./assets/images/common/icons/svg/white/icon-long-arrow.svg");
  background-size: 100%; }
  @media screen and (max-width: 991px) {
    .market-focus__arrow:before {
      width: 24px;
      height: 24px; } }
  .market-focus__item:hover .market-focus__arrow:before {
    -webkit-animation: mf-arrow 0.6s infinite;
    animation: mf-arrow 0.6s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }

@-webkit-keyframes mf-arrow {
  0% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  100% {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px); } }

@keyframes mf-arrow {
  0% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  100% {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px); } }

.market-focus__content {
  position: relative; }

/* END Style market-focus  */
/* ------------------------------------------ */
/* Style for main-banner */
/* ------------------------------------------ */
.main-banner {
  position: relative; }

.main-banner__slider {
  max-width: 1600px;
  margin: 0 auto;
  padding: 15px 0 45px; }
  @media screen and (max-width: 991px) {
    .main-banner__slider {
      padding: 0; } }
  @media screen and (min-width: 992px) {
    .main-banner__slider .swiper-wrapper {
      height: 100% !important; } }

.main-banner__slide {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: flex-end;
  -ms-flex-line-pack: end;
  align-content: flex-end;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch; }
  @media screen and (min-width: 992px) {
    .main-banner__slide {
      background-image: none !important; } }
  @media screen and (max-width: 991px) {
    .main-banner__slide {
      display: block; } }

.main-banner__img, .main-banner__vid {
  display: block;
  position: relative;
  overflow: hidden;
  width: calc(100% - 400px);
  -webkit-animation: main-banner-img-line 0s linear 1s;
  animation: main-banner-img-line 0s linear 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  will-change: transform; }

@-webkit-keyframes main-banner-img-line {
  0% {
    border-bottom: 6px solid #FFF; }
  100% {
    border-bottom: 6px solid #fb5004; } }

@keyframes main-banner-img-line {
  0% {
    border-bottom: 6px solid #FFF; }
  100% {
    border-bottom: 6px solid #fb5004; } }
  @media screen and (min-width: 992px) {
    .main-banner__img, .main-banner__vid {
      background: url() !important; } }
  @media screen and (max-width: 1580px) {
    .main-banner__img, .main-banner__vid {
      width: calc(100% - 350px); } }
  @media screen and (max-width: 1280px) {
    .main-banner__img, .main-banner__vid {
      width: calc(100% - 300px); } }
  @media screen and (max-width: 991px) {
    .main-banner__img, .main-banner__vid {
      position: relative;
      background: url() no-repeat center center;
      background-size: cover;
      width: 100%;
      height: 500px;
      left: 0;
      top: 0;
      border-bottom: 0; } }
  @media screen and (max-width: 767px) {
    .main-banner__img, .main-banner__vid {
      height: 420px; } }
  @media screen and (max-width: 480px) {
    .main-banner__img, .main-banner__vid {
      height: 360px; } }
  @media screen and (max-width: 380px) {
    .main-banner__img, .main-banner__vid {
      height: 300px; } }
  .main-banner__img:hover, .main-banner__vid:hover {
    opacity: 1; }
  .main-banner__img img, .main-banner__vid img {
    display: block;
    width: 100%; }
    .main-banner__img img.loading--close, .main-banner__vid img.loading--close {
      opacity: 0;
      display: none; }
    .main-banner__img img.loading--open, .main-banner__vid img.loading--open {
      opacity: 1;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: auto;
      height: auto; }
    .main-banner__img img.image--loading, .main-banner__vid img.image--loading {
      opacity: 0;
      display: none; }
    .main-banner__img img.image---loaded, .main-banner__vid img.image---loaded {
      opacity: 1;
      display: block; }
    .swiper-slide-active .main-banner__img img, .swiper-slide-active .main-banner__vid img {
      -webkit-animation: main-banner-img 5.5s linear;
      animation: main-banner-img 5.5s linear;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      will-change: transform; }

@-webkit-keyframes main-banner-img {
  0% {
    -webkit-transform: scale(1.08, 1.08);
    -ms-transform: scale(1.08, 1.08);
    transform: scale(1.08, 1.08); }
  100% {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1); } }

@keyframes main-banner-img {
  0% {
    -webkit-transform: scale(1.08, 1.08);
    -ms-transform: scale(1.08, 1.08);
    transform: scale(1.08, 1.08); }
  100% {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1); } }
    .swiper-slide-duplicate .main-banner__img img:last-child, .swiper-slide-duplicate .main-banner__vid img:last-child {
      opacity: 1 !important;
      display: block !important; }
    @media screen and (max-width: 991px) {
      .main-banner__img img, .main-banner__vid img {
        display: none; } }

.main-banner__vid {
  position: relative;
  overflow: hidden;
  display: block; }
  .main-banner__vid:before {
    content: '';
    position: relative;
    width: 100%;
    padding-bottom: 50%;
    display: block;
    z-index: -1; }
  .main-banner__vid video {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .is-ie .main-banner__vid > div {
    position: static !important; }
  .is-ie .main-banner__vid video {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; }

.main-banner__txt {
  padding: 0 40px;
  width: 400px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: flex-end;
  -ms-flex-line-pack: end;
  align-content: flex-end;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }
  .main-banner__txt > * {
    width: 100%; }
  .main-banner__txt:before {
    content: '';
    display: block;
    width: 100%; }
  @media screen and (max-width: 1580px) {
    .main-banner__txt {
      width: 350px; } }
  @media screen and (max-width: 1280px) {
    .main-banner__txt {
      width: 300px;
      padding: 0 30px; } }
  @media screen and (max-width: 991px) {
    .main-banner__txt {
      background: #fc5e08;
      background: -moz-linear-gradient(left, #fc5e08 0%, #fd8e14 100%);
      background: -webkit-linear-gradient(left, #fc5e08 0%, #fd8e14 100%);
      background: linear-gradient(to right, #fc5e08 0%, #fd8e14 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fc5e08', endColorstr='#fd8e14', GradientType=1);
      color: #FFF;
      position: relative;
      padding: 60px 5%;
      width: 100%;
      height: 100%;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start; } }
  @media screen and (max-width: 767px) {
    .main-banner__txt {
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      padding: 50px 5%; } }
  @media screen and (max-width: 480px) {
    .main-banner__txt {
      padding: 30px 5%; } }

.main-banner__title {
  width: calc(100% + 150px);
  margin: 45px 0 0;
  line-height: 1.2;
  -webkit-transform: translateX(-150px);
  -ms-transform: translateX(-150px);
  transform: translateX(-150px);
  opacity: 0;
  font-weight: 900;
  background: #fc5e08;
  background: -moz-linear-gradient(left, #fc5e08 0%, #fd8e14 100%);
  background: -webkit-linear-gradient(left, #fc5e08 0%, #fd8e14 100%);
  background: linear-gradient(to right, #fc5e08 0%, #fd8e14 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fc5e08', endColorstr='#fd8e14', GradientType=1);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text; }
  .swiper-slide-active .main-banner__title {
    -webkit-animation: main-banner-title 0.9s;
    animation: main-banner-title 0.9s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }
    @media screen and (max-width: 991px) {
      .swiper-slide-active .main-banner__title {
        -webkit-animation: main-banner-title-mb 0.4s;
        animation: main-banner-title-mb 0.4s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        will-change: transform; } }

@-webkit-keyframes main-banner-title {
  0% {
    opacity: 0;
    -webkit-transform: translateX(200px);
    -ms-transform: translateX(200px);
    transform: translateX(200px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    transform: translateX(-100px); } }

@keyframes main-banner-title {
  0% {
    opacity: 0;
    -webkit-transform: translateX(200px);
    -ms-transform: translateX(200px);
    transform: translateX(200px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    transform: translateX(-100px); } }

@-webkit-keyframes main-banner-title-mb {
  0% {
    opacity: 0;
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@keyframes main-banner-title-mb {
  0% {
    opacity: 0;
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }
  @media screen and (max-width: 1280px) {
    .main-banner__title {
      margin: 30px 0 0;
      font-size: 36px; } }
  @media screen and (max-width: 991px) {
    .main-banner__title {
      width: 100%;
      margin: 0;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0); } }
  @media screen and (max-width: 767px) {
    .main-banner__title {
      margin: 0 0 15px; } }
  @media screen and (max-width: 480px) {
    .main-banner__title {
      font-size: 28px; } }
  .is-ie .main-banner__title {
    background: transparent;
    color: #fb5004; }
    @media screen and (max-width: 991px) {
      .is-ie .main-banner__title {
        background: transparent;
        color: #FFF; } }
  @media screen and (max-width: 991px) {
    .main-banner__title {
      background: transparent;
      color: #FFF; } }

.main-banner__detail {
  position: relative;
  opacity: 0; }
  .swiper-slide-active .main-banner__detail {
    -webkit-animation: main-banner-detail 0.4s 0.2s;
    animation: main-banner-detail 0.4s 0.2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }

@-webkit-keyframes main-banner-detail {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@keyframes main-banner-detail {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }
  @media screen and (max-width: 991px) {
    .main-banner__detail {
      color: #FFF; } }

.main-banner__detail-title {
  font-size: 20px;
  color: #fb5004;
  margin: 0 0 15px; }
  @media screen and (max-width: 991px) {
    .main-banner__detail-title {
      color: #FFF; } }
  @media screen and (max-width: 767px) {
    .main-banner__detail-title {
      font-size: 18px;
      margin: 0 0 10px; } }

.main-banner__detail-desc {
  font-size: 16px;
  margin: 0 0 15px; }
  @media screen and (max-width: 991px) {
    .main-banner__detail-desc {
      color: #FFF; } }
  @media screen and (max-width: 767px) {
    .main-banner__detail-desc {
      font-size: 14px;
      margin: 0 0 10px; } }

.main-banner__detail-date {
  color: #1a1a1a;
  font-weight: 400; }
  @media screen and (max-width: 991px) {
    .main-banner__detail-date {
      display: block;
      color: #FFF;
      margin-top: 15px; } }

.main-banner__scrolldown {
  position: absolute;
  display: block;
  bottom: 0;
  left: 10%;
  opacity: 0;
  -webkit-animation: main-banner-scrolldown 0.4s 2s;
  animation: main-banner-scrolldown 0.4s 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  will-change: transform; }
  @media screen and (max-width: 1800px) {
    .main-banner__scrolldown {
      left: 5%; } }
  @media screen and (max-width: 1600px) {
    .main-banner__scrolldown {
      left: 2%; } }

@-webkit-keyframes main-banner-scrolldown {
  0% {
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@keyframes main-banner-scrolldown {
  0% {
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }
  .main-banner__scrolldown:before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background: url() no-repeat center center;
    background-size: 100%;
    background-image: url("./assets/images/common/icons/svg/black/icon-mouse.svg");
    margin-bottom: 8px; }
  .main-banner__scrolldown:after {
    content: '';
    display: block;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: #1a1a1a transparent transparent transparent;
    -webkit-animation: 0.7s scrolldown infinite;
    animation: 0.7s scrolldown infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }
  @media screen and (max-width: 991px) {
    .main-banner__scrolldown {
      display: none; } }

@-webkit-keyframes scrolldown {
  from {
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0); }
  to {
    -webkit-transform: translate(0, 60%);
    -ms-transform: translate(0, 60%);
    transform: translate(0, 60%); } }

@keyframes scrolldown {
  from {
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0); }
  to {
    -webkit-transform: translate(0, 60%);
    -ms-transform: translate(0, 60%);
    transform: translate(0, 60%); } }

.main-banner__thumbs {
  z-index: 50;
  display: block;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 45px;
  pointer-events: none;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%); }
  @media screen and (max-width: 991px) {
    .main-banner__thumbs {
      top: 440px;
      bottom: auto; } }
  @media screen and (max-width: 767px) {
    .main-banner__thumbs {
      top: 360px; } }
  @media screen and (max-width: 480px) {
    .main-banner__thumbs {
      top: 300px; } }
  @media screen and (max-width: 380px) {
    .main-banner__thumbs {
      top: 240px; } }
  .main-banner__thumbs .content-wrapper, .main-banner__thumbs .app-sec__inner, .main-banner__thumbs .header__wrapper, .main-banner__thumbs .search-nores, .main-banner__thumbs .sec-intro__detail-wrapper, .main-banner__thumbs .submenu__wrapper {
    padding: 0 30px; }
    @media screen and (max-width: 991px) {
      .main-banner__thumbs .content-wrapper, .main-banner__thumbs .app-sec__inner, .main-banner__thumbs .header__wrapper, .main-banner__thumbs .search-nores, .main-banner__thumbs .sec-intro__detail-wrapper, .main-banner__thumbs .submenu__wrapper {
        padding: 0;
        width: 90%; } }

.main-banner__thumb {
  pointer-events: all;
  position: relative;
  display: inline-block;
  width: 110px;
  margin-right: 10px;
  background-size: cover;
  -webkit-animation: main-banner-thumb 0.4s;
  animation: main-banner-thumb 0.4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  will-change: transform;
  opacity: 0; }
  .main-banner__thumb:nth-child(2) {
    -webkit-animation-delay: 0.12s;
    animation-delay: 0.12s; }
  .main-banner__thumb:nth-child(3) {
    -webkit-animation-delay: 0.24s;
    animation-delay: 0.24s; }
  .main-banner__thumb:nth-child(4) {
    -webkit-animation-delay: 0.36s;
    animation-delay: 0.36s; }
  .main-banner__thumb:nth-child(5) {
    -webkit-animation-delay: 0.48s;
    animation-delay: 0.48s; }
  .main-banner__thumb:nth-child(6) {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s; }
  .main-banner__thumb:nth-child(7) {
    -webkit-animation-delay: 0.72s;
    animation-delay: 0.72s; }
  .main-banner__thumb:nth-child(8) {
    -webkit-animation-delay: 0.84s;
    animation-delay: 0.84s; }

@-webkit-keyframes main-banner-thumb {
  0% {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@keyframes main-banner-thumb {
  0% {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }
  @media screen and (max-width: 1600px) {
    .main-banner__thumb {
      width: 85px; } }
  @media screen and (max-width: 1180px) {
    .main-banner__thumb {
      width: 65px;
      margin-right: 8px; } }
  @media screen and (max-width: 991px) {
    .main-banner__thumb {
      background: transparent !important;
      width: 30px; } }
  @media screen and (max-width: 767px) {
    .main-banner__thumb {
      width: 20px; } }
  .main-banner__thumb:last-child {
    margin-right: 0; }
  .main-banner__thumb:before {
    content: '';
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; }
    @media screen and (max-width: 991px) {
      .main-banner__thumb:before {
        padding: 0;
        height: 4px; } }
    @media screen and (max-width: 767px) {
      .main-banner__thumb:before {
        height: 3px; } }
  .main-banner__thumb:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 0;
    transition: 0.3s; }
    @media screen and (max-width: 991px) {
      .main-banner__thumb:after {
        background: rgba(255, 255, 255, 0.5); } }
  .main-banner__thumb.is-active, .main-banner__thumb:hover {
    opacity: 1; }
    .main-banner__thumb.is-active:after, .main-banner__thumb:hover:after {
      box-sizing: border-box;
      border: 5px solid #fb5004; }
      @media screen and (max-width: 1280px) {
        .main-banner__thumb.is-active:after, .main-banner__thumb:hover:after {
          border: 3px solid #fb5004; } }
      @media screen and (max-width: 991px) {
        .main-banner__thumb.is-active:after, .main-banner__thumb:hover:after {
          border: 0;
          background: #fb5004; } }

/* END Style main-banner  */
/* ------------------------------------------ */
/* Style for mob-lang */
/* ------------------------------------------ */
.mob-lang__headlink {
  display: block;
  margin: 0 0 20px; }

.mob-lang__link {
  display: block;
  margin: 0 auto 15px; }

/* END Style mob-lang  */
/* ------------------------------------------ */
/* Style for mf-detail */
/* ------------------------------------------ */
.mf-detail {
  color: #FFF;
  top: 0;
  width: 100%;
  position: absolute;
  opacity: 0;
  z-index: -1; }
  .mf-detail.is-active {
    position: relative;
    display: block;
    opacity: 1;
    z-index: 10; }

.mf-detail__top {
  position: relative;
  overflow: hidden;
  height: 680px;
  padding: 250px 0;
  background: #000 url() no-repeat 55% center; }
  @media screen and (max-width: 1200px) {
    .mf-detail__top {
      padding: 200px 0;
      background-size: contain;
      height: 500px; } }
  @media screen and (max-width: 767px) {
    .mf-detail__top {
      padding: 60px 0;
      background-size: cover;
      height: auto; }
      .mf-detail__top:before {
        content: '';
        width: 100%;
        height: 100%;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 0; } }
  .mf-detail__top .content-wrapper, .mf-detail__top .app-sec__inner, .mf-detail__top .header__wrapper, .mf-detail__top .search-nores, .mf-detail__top .sec-intro__detail-wrapper, .mf-detail__top .submenu__wrapper {
    position: relative;
    z-index: 10;
    height: 100%;
    padding: 0 60px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }

@-webkit-keyframes mf-item-fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes mf-item-fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.mf-detail__top-bg {
  z-index: 0;
  position: relative;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000 url() no-repeat 55% center;
  transition: 0.5s 0.1s;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  opacity: 0; }
  .mf-detail.is-active .mf-detail__top-bg {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1; }
  .mf-detail__top-bg:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1; }
    @media screen and (max-width: 767px) {
      .mf-detail__top-bg:after {
        background: rgba(0, 0, 0, 0.4); } }

.mf-detail__intro {
  display: inline-block;
  max-width: 45%;
  opacity: 0; }
  .mf-detail.is-active .mf-detail__intro {
    -webkit-animation: mf-item-fadein 0.6s;
    animation: mf-item-fadein 0.6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }
  @media screen and (max-width: 767px) {
    .mf-detail__intro {
      width: 100%;
      max-width: 100%;
      margin: 0 0 30px; } }

.mf-detail__intro-title {
  margin: 0 0 15px;
  font-weight: 200;
  text-transform: uppercase; }

.mf-detail__intro-desc {
  line-height: 1.5; }

.mf-detail__quicklinks {
  display: inline-block;
  width: 28%; }
  @media screen and (max-width: 767px) {
    .mf-detail__quicklinks {
      width: 100%;
      max-width: 100%; } }

.mf-detail__quicklinks-title {
  margin: 0 0 10px;
  font-weight: 300;
  color: #fb5004;
  opacity: 0; }
  .mf-detail.is-active .mf-detail__quicklinks-title {
    -webkit-animation: mf-item-quicklinks 0.6s;
    animation: mf-item-quicklinks 0.6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }

@-webkit-keyframes mf-item-quicklinks {
  0% {
    opacity: 0;
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@keyframes mf-item-quicklinks {
  0% {
    opacity: 0;
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

.mf-detail__list {
  line-height: 1.5;
  padding-left: 15px; }
  .mf-detail__list li {
    padding: 5px 0; }
    .mf-detail.is-active .mf-detail__list li .mf-detail__link {
      display: block;
      -webkit-animation: mf-item-quicklink 0.6s;
      animation: mf-item-quicklink 0.6s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      will-change: transform; }

@-webkit-keyframes mf-item-quicklink {
  0% {
    opacity: 0;
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@keyframes mf-item-quicklink {
  0% {
    opacity: 0;
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }
    .mf-detail.is-active .mf-detail__list li:nth-child(2) .mf-detail__link {
      -webkit-animation-delay: 0.1s;
      animation-delay: 0.1s; }
    .mf-detail.is-active .mf-detail__list li:nth-child(3) .mf-detail__link {
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s; }
    .mf-detail.is-active .mf-detail__list li:nth-child(4) .mf-detail__link {
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s; }
    .mf-detail.is-active .mf-detail__list li:nth-child(5) .mf-detail__link {
      -webkit-animation-delay: 0.4s;
      animation-delay: 0.4s; }
    .mf-detail.is-active .mf-detail__list li:nth-child(6) .mf-detail__link {
      -webkit-animation-delay: 0.5s;
      animation-delay: 0.5s; }
    .mf-detail.is-active .mf-detail__list li:nth-child(7) .mf-detail__link {
      -webkit-animation-delay: 0.6s;
      animation-delay: 0.6s; }
    .mf-detail.is-active .mf-detail__list li:nth-child(8) .mf-detail__link {
      -webkit-animation-delay: 0.7s;
      animation-delay: 0.7s; }
    .mf-detail.is-active .mf-detail__list li:nth-child(9) .mf-detail__link {
      -webkit-animation-delay: 0.8s;
      animation-delay: 0.8s; }
    .mf-detail.is-active .mf-detail__list li:nth-child(10) .mf-detail__link {
      -webkit-animation-delay: 0.9s;
      animation-delay: 0.9s; }

.mf-detail__link {
  color: #FFF;
  opacity: 0; }
  .mf-detail__link:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-left: 5px;
    width: 0.4em;
    height: 0.4em;
    border-top: 2px solid #FFF;
    border-right: 2px solid #FFF;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }
  .mf-detail__link:hover {
    color: #FFF; }

.mf-detail__control {
  position: absolute;
  top: 50%;
  height: 0;
  width: 100%;
  left: 0; }

.mf-detail__top-control {
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  pointer-events: none;
  z-index: 15; }
  @media screen and (max-width: 767px) {
    .mf-detail__top-control {
      top: 0; } }
  .mf-detail__top-control .content-wrapper, .mf-detail__top-control .app-sec__inner, .mf-detail__top-control .header__wrapper, .mf-detail__top-control .search-nores, .mf-detail__top-control .sec-intro__detail-wrapper, .mf-detail__top-control .submenu__wrapper {
    width: 100%; }
    @media screen and (max-width: 991px) {
      .mf-detail__top-control .content-wrapper, .mf-detail__top-control .app-sec__inner, .mf-detail__top-control .header__wrapper, .mf-detail__top-control .search-nores, .mf-detail__top-control .sec-intro__detail-wrapper, .mf-detail__top-control .submenu__wrapper {
        width: 90%; } }
    .mf-detail__top-control .content-wrapper *, .mf-detail__top-control .app-sec__inner *, .mf-detail__top-control .header__wrapper *, .mf-detail__top-control .search-nores *, .mf-detail__top-control .sec-intro__detail-wrapper *, .mf-detail__top-control .submenu__wrapper * {
      pointer-events: all; }

.mf-detail__btn-prev,
.mf-detail__btn-next {
  width: 36px;
  height: 36px;
  border: 1px solid #FFF;
  display: block;
  border-radius: 50%;
  position: absolute;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  top: 50%;
  margin-top: -18px; }
  .mf-detail__btn-prev:after,
  .mf-detail__btn-next:after {
    content: '';
    position: absolute;
    display: block;
    width: 7.2px;
    height: 7.2px;
    border-top: 1px solid #FFF;
    border-right: 1px solid #FFF;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -2.736px;
    margin-left: -5.4px; }

.mf-detail__btn-prev {
  left: -10px;
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg); }

.mf-detail__btn-next {
  right: -10px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.mf-detail__btn-close {
  display: block;
  width: 36px;
  height: 36px;
  position: absolute;
  overflow: hidden;
  top: 30px;
  right: 30px; }
  .mf-detail__btn-close:before, .mf-detail__btn-close:after {
    content: '';
    position: absolute;
    display: block;
    background: #FFF;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }
  .mf-detail__btn-close:before {
    width: 100%;
    height: 2px;
    left: 0;
    top: 50%;
    margin-top: -1px; }
  .mf-detail__btn-close:after {
    height: 100%;
    width: 2px;
    left: 50%;
    margin-left: -1px;
    top: 0; }

.mf-detail__boxes {
  /*
    position:absolute;
    width:100%;
    left:0;
    top:100%;
    */
  margin-top: -60px; }
  @media screen and (max-width: 767px) {
    .mf-detail__boxes {
      margin-top: 0; } }
  .mf-detail__boxes .content-wrapper, .mf-detail__boxes .app-sec__inner, .mf-detail__boxes .header__wrapper, .mf-detail__boxes .search-nores, .mf-detail__boxes .sec-intro__detail-wrapper, .mf-detail__boxes .submenu__wrapper {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.06); }
    @media screen and (max-width: 767px) {
      .mf-detail__boxes .content-wrapper, .mf-detail__boxes .app-sec__inner, .mf-detail__boxes .header__wrapper, .mf-detail__boxes .search-nores, .mf-detail__boxes .sec-intro__detail-wrapper, .mf-detail__boxes .submenu__wrapper {
        width: 100%;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch; } }

.mf-detail__box {
  position: relative;
  display: inline-block;
  width: 33.3333%;
  padding: 80px 60px 45px;
  opacity: 0; }
  .mf-detail.is-active .mf-detail__box {
    -webkit-animation: mf-item-box 0.6s;
    animation: mf-item-box 0.6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }

@-webkit-keyframes mf-item-box {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@keyframes mf-item-box {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }
  .mf-detail__box.mf-detail__box--1 {
    width: 36%;
    background: #1e1e1e; }
  .mf-detail__box.mf-detail__box--2 {
    width: 36%;
    color: #1a1a1a;
    background: #FFF; }
    .mf-detail.is-active .mf-detail__box.mf-detail__box--2 {
      -webkit-animation-delay: 0.1s;
      animation-delay: 0.1s; }
  .mf-detail__box.mf-detail__box--3 {
    width: 28%;
    background: #fb5004; }
    .mf-detail.is-active .mf-detail__box.mf-detail__box--3 {
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s; }
  @media screen and (max-width: 767px) {
    .mf-detail__box {
      width: 100%;
      padding: 45px; }
      .mf-detail__box.mf-detail__box--1, .mf-detail__box.mf-detail__box--2, .mf-detail__box.mf-detail__box--3 {
        width: 100%; } }

.mf-detail__box-img {
  display: block;
  width: 100%;
  position: absolute;
  bottom: 100%;
  left: 0; }
  @media screen and (max-width: 767px) {
    .mf-detail__box-img {
      position: relative;
      bottom: auto;
      left: auto;
      margin: 0 auto 30px;
      display: none; } }
  .mf-detail__box-img img {
    position: relative;
    padding: 0 60px;
    margin: 0 auto -60px;
    display: block;
    width: 100%; }
    @media screen and (max-width: 767px) {
      .mf-detail__box-img img {
        margin: 0 auto;
        padding: 0; } }

.mf-detail__btn-arrow:before {
  content: '';
  width: 30px;
  height: 30px;
  display: block;
  background: url() no-repeat center center;
  background-image: url("./assets/images/common/icons/svg/orange/icon-long-arrow.svg");
  background-size: 100%; }

.mf-detail__box-label {
  font-weight: 400;
  margin: 0 0 10px; }
  .mf-detail__box--1 .mf-detail__box-label {
    color: #fb5004; }

.mf-detail__box-title {
  font-size: 20px;
  font-weight: 300;
  margin: 0 0 10px;
  line-height: 1.5; }

.mf-detail__box-date,
.mf-detail__box-desc {
  margin: 0 0 10px;
  line-height: 1.5; }

/* END Style mf-detail  */
/* ------------------------------------------ */
/* Style for nav-link */
/* ------------------------------------------ */
.nav-link__holder {
  padding-left: 15px; }
  @media screen and (max-width: 991px) {
    .nav-link__holder {
      padding-left: 0;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000;
      background: rgba(255, 255, 255, 0.85);
      width: 100vw;
      height: 100%;
      padding-top: 120px;
      display: none; }
      .mob-menu-open .nav-link__holder {
        display: block;
        overflow: auto; } }

.nav-link__subholder {
  display: none; }
  @media screen and (min-width: 992px) {
    .header--sub-portal .nav-link__subholder#mob-menu {
      text-align: right; } }
  .search-panel-open .nav-link__subholder#mob-menu {
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    filter: blur(2px); }
  @media screen and (min-width: 992px) {
    .nav-link__subholder {
      display: none; }
      .nav-link__subholder#mob-menu {
        display: block; } }
  @media screen and (max-width: 991px) {
    .nav-link__subholder {
      display: none; }
      .nav-link__subholder.is-active {
        display: block; } }
  .nav-link__subholder#mob-share {
    width: 90%;
    margin: 0 auto;
    color: #1a1a1a;
    text-align: center; }
    .nav-link__subholder#mob-share .button-share__holder {
      display: block; }
    .nav-link__subholder#mob-share .button-share__title {
      display: block;
      margin: 0 0 20px; }
    .nav-link__subholder#mob-share .button-share {
      margin: 0 10px 20px; }
  .nav-link__subholder#mob-lang {
    width: 90%;
    margin: 0 auto;
    color: #1a1a1a;
    text-align: center; }

.nav-link {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  color: #1a1a1a;
  padding: 0 20px; }
  @media screen and (min-width: 992px) and (max-width: 1280px) {
    .nav-link {
      padding: 0 15px; } }
  @media screen and (max-width: 991px) {
    .nav-link {
      position: relative;
      display: block;
      margin-bottom: 30px; }
      .on-submenu .nav-link {
        display: none; }
        .on-submenu .nav-link.is-open {
          display: block; } }
  @media screen and (min-width: 992px) {
    .header--sub-portal .nav-link {
      padding: 0;
      margin-left: 20px;
      position: relative;
      opacity: 1; }
      .header--sub-portal .nav-link.is-last {
        padding-right: 0; } }

.nav-link__mob-toggle {
  display: none; }
  @media screen and (max-width: 991px) {
    .nav-link__mob-toggle {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 30; }
      .nav-link.is-open .nav-link__mob-toggle {
        display: none; } }

.nav-link__link {
  z-index: 10;
  position: relative;
  display: block;
  color: #595857;
  /*
    @include fontSize(14);
    @media screen and (max-width: 1580px) {
        @include fontSize(12);
    }
    */ }
  .nav-link__link:hover {
    opacity: 1;
    color: #fb5004; }
  .nav-link.is-hover .nav-link__link {
    z-index: 15; }
  .nav-link__link.is-active {
    color: #fb5004; }
    .nav-link__link.is-active[href="/"], .nav-link__link.is-active[href="/en"], .nav-link__link.is-active[href="/en/"], .nav-link__link.is-active[href="/tc"], .nav-link__link.is-active[href="/sc"], .nav-link__link.is-active[href="/tc/"], .nav-link__link.is-active[href="/sc/"] {
      color: #595857; }
      [data-page="aboutHome"] .nav-link__link.is-active[href="/"], [data-page="aboutHome"] .nav-link__link.is-active[href="/en"], [data-page="aboutHome"] .nav-link__link.is-active[href="/en/"], [data-page="aboutHome"] .nav-link__link.is-active[href="/tc"], [data-page="aboutHome"] .nav-link__link.is-active[href="/sc"], [data-page="aboutHome"] .nav-link__link.is-active[href="/tc/"], [data-page="aboutHome"] .nav-link__link.is-active[href="/sc/"] {
        color: #fb5004; }
  .nav-link__link .nav-link__hidden {
    display: none; }
    .nav-link__link .nav-link__hidden + a {
      color: #595857; }
      .nav-link__link .nav-link__hidden + a:hover {
        opacity: 1;
        color: #fb5004; }
    .nav-link__link .nav-link__hidden.is-active + a {
      color: #fb5004; }
      .nav-link__link .nav-link__hidden.is-active + a strong {
        font-weight: bold; }
      .nav-link__link .nav-link__hidden.is-active + a[href="/"], .nav-link__link .nav-link__hidden.is-active + a[href="/en"], .nav-link__link .nav-link__hidden.is-active + a[href="/en/"], .nav-link__link .nav-link__hidden.is-active + a[href="/tc"], .nav-link__link .nav-link__hidden.is-active + a[href="/sc"], .nav-link__link .nav-link__hidden.is-active + a[href="/tc/"], .nav-link__link .nav-link__hidden.is-active + a[href="/sc/"], .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus"], .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/"], .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/en"], .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/en/"], .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/tc"], .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/sc"], .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/tc/"], .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/sc/"] {
        color: #595857; }
        .nav-link__link .nav-link__hidden.is-active + a[href="/"] strong, .nav-link__link .nav-link__hidden.is-active + a[href="/en"] strong, .nav-link__link .nav-link__hidden.is-active + a[href="/en/"] strong, .nav-link__link .nav-link__hidden.is-active + a[href="/tc"] strong, .nav-link__link .nav-link__hidden.is-active + a[href="/sc"] strong, .nav-link__link .nav-link__hidden.is-active + a[href="/tc/"] strong, .nav-link__link .nav-link__hidden.is-active + a[href="/sc/"] strong, .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus"] strong, .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/"] strong, .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/en"] strong, .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/en/"] strong, .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/tc"] strong, .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/sc"] strong, .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/tc/"] strong, .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/sc/"] strong {
          font-weight: normal; }
        [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/"], [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/en"], [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/en/"], [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/tc"], [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/sc"], [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/tc/"], [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/sc/"], [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus"], [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/"], [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/en"], [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/en/"], [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/tc"], [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/sc"], [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/tc/"], [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/sc/"] {
          color: #fb5004; }
          [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/"] strong, [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/en"] strong, [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/en/"] strong, [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/tc"] strong, [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/sc"] strong, [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/tc/"] strong, [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/sc/"] strong, [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus"] strong, [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/"] strong, [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/en"] strong, [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/en/"] strong, [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/tc"] strong, [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/sc"] strong, [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/tc/"] strong, [data-page="aboutHome"] .nav-link__link .nav-link__hidden.is-active + a[href="/preview/aboutus/sc/"] strong {
            font-weight: bold; }
  @media screen and (min-width: 992px) and (max-width: 1280px) {
    .nav-link__link {
      font-size: 12px; } }
  .nav-link__link strong {
    text-transform: uppercase;
    display: block;
    margin: 0 auto 5px;
    font-weight: 400;
    font-size: 22px; }
    @media screen and (max-width: 1580px) {
      .nav-link__link strong {
        font-size: 18px; } }
    [lang="zh-hk"] .nav-link__link strong,
    [lang="zh-cn"] .nav-link__link strong {
      display: inline; }
    @media screen and (min-width: 992px) {
      .header--sub-portal .nav-link__link strong {
        font-size: 14px;
        margin: 5px auto 0; }
      [lang="zh-hk"] .nav-link__link strong,
      [lang="zh-cn"] .nav-link__link strong {
        font-size: 16px; } }
  [lang="zh-hk"] .nav-link__link,
  [lang="zh-cn"] .nav-link__link {
    font-size: 22px;
    font-weight: bold; }
    @media screen and (max-width: 1580px) {
      [lang="zh-hk"] .nav-link__link,
      [lang="zh-cn"] .nav-link__link {
        font-size: 18px; } }
  .nav-link__link:before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 30px;
    top: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
    .nav-link.is-hover .nav-link__link:before {
      width: 300%; }
  .nav-link.is-hover .nav-link__link:after {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    background: #FFF;
    border-right: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    top: 100%;
    left: 50%;
    margin-top: 25px;
    margin-left: -10px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }
    @media screen and (max-width: 991px) {
      .nav-link.is-hover .nav-link__link:after {
        display: none; } }
    @media screen and (min-width: 992px) {
      .header--sub-portal .nav-link.is-hover .nav-link__link:after {
        background: #fb5004;
        border-right: 1px solid #fb5004;
        border-bottom: 1px solid #fb5004;
        margin-top: 5px; } }

/* END Style nav-link  */
/* ------------------------------------------ */
/* Style for organic-listing */
/* ------------------------------------------ */
.organic-listing {
  position: relative; }
  .organic-listing em {
    font-style: normal;
    font-weight: bold;
    color: #fb5004; }

.organic-listing__items {
  position: relative;
  background: #FFF;
  padding: 15px 5%; }

.organic-listing__item {
  padding: 25px 0;
  width: 100%;
  border-bottom: 1px solid #777; }
  .organic-listing__item:last-child {
    border-bottom: 0; }

.organic-listing__item-big-title {
  display: inline-block;
  margin: 0 0 15px;
  margin-right: 10px; }
  .organic-listing__item-big-title + .organic-listing__note-icons {
    margin: 5px 0; }

.organic-listing__item-row {
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  @media screen and (max-width: 991px) {
    .organic-listing__item-row.organic-listing__item-row--multiimg {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; } }
  @media screen and (max-width: 767px) {
    .organic-listing__item-row {
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start; } }
  @media screen and (max-width: 480px) {
    .organic-listing__item-row {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; } }

.organic-listing__img {
  position: relative;
  padding-right: 20px;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; }
  @media screen and (max-width: 480px) {
    .organic-listing__img {
      width: 100%;
      margin-bottom: 10px; } }
  @media screen and (max-width: 991px) {
    .organic-listing__item-row--multiimg .organic-listing__img {
      width: 100%;
      margin-bottom: 10px; } }

.organic-listing__thumb {
  display: inline-block;
  border: 1px solid #777;
  margin-right: 10px;
  width: 160px;
  cursor: zoom-in; }
  .organic-listing__thumb:last-child {
    margin-right: 0; }
  @media screen and (max-width: 991px) {
    .organic-listing__thumb {
      width: 120px; } }
  @media screen and (max-width: 640px) {
    .organic-listing__thumb {
      width: 100px; } }

.organic-listing__img-holder {
  position: relative;
  display: inline-block; }
  .organic-listing__img-holder:hover .organic-listing__pop {
    display: block; }
  .organic-listing__img-holder:hover:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 30px;
    left: 0;
    bottom: 100%;
    z-index: 500; }

.organic-listing__pop {
  position: absolute;
  background: #FFF;
  font-size: 0;
  width: 480px;
  z-index: 1000;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  left: 0;
  bottom: 100%;
  margin-bottom: 30px;
  display: none; }
  .organic-listing__pop:hover {
    display: block; }

.organic-listing__popcap {
  background: #fb5004;
  color: #FFF;
  padding: 15px 30px; }

.organic-listing__popcap-title em {
  color: #FFF; }

.organic-listing__popcap-btns {
  margin-top: 10px; }
  .organic-listing__popcap-btns .organic-listing__btn {
    color: #FFF;
    border-color: #FFF;
    margin: 0;
    margin-right: 10px;
    margin-bottom: 10px; }

.organic-listing__item-head {
  display: block;
  margin: 0 0 5px; }

.organic-listing__item-title {
  display: inline-block;
  margin: 0;
  margin-right: 10px;
  font-size: 15px;
  font-weight: bold; }

.organic-listing__infos {
  position: relative;
  font-size: 0;
  margin: 0 0 5px; }

.organic-listing__info {
  display: inline-block;
  font-size: 12px;
  margin-right: 10px;
  padding-right: 10px;
  position: relative; }
  .organic-listing__info:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    height: 16px;
    margin-top: -8px;
    width: 1px;
    background: #1a1a1a; }
  .organic-listing__info:last-child:after {
    display: none;
    margin-right: 0;
    padding-right: 0; }

.organic-listing__specs {
  position: relative;
  font-size: 0;
  margin: 0 0 5px; }

.organic-listing__spec {
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;
  padding-right: 10px;
  position: relative; }
  .organic-listing__spec:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    height: 16px;
    margin-top: -8px;
    width: 1px;
    background: #1a1a1a; }
  .organic-listing__spec:last-child:after {
    display: none;
    margin-right: 0;
    padding-right: 0; }

.organic-listing__desc {
  font-size: 14px;
  line-height: 1.5;
  margin: 0 0 5px; }

.organic-listing__btn {
  margin: 0;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 8px 20px; }
  @media screen and (max-width: 480px) {
    .organic-listing__btn {
      margin-left: 0; }
      .organic-listing__btn + .organic-listing__btn {
        margin-left: 0; } }

.organic-listing__note-icons {
  display: inline-block;
  vertical-align: middle; }

.organic-listing__icons {
  margin: 0 0 10px; }

.organic-listing__icon {
  display: inline-block;
  vertical-align: middle;
  width: 70px;
  height: 22px;
  margin-right: 10px;
  background: url("./assets/images/common/icons/img-search-icons.png") no-repeat;
  background-position-x: 0;
  background-position-y: 0; }
  .organic-listing__icon:last-child {
    margin-right: 0; }
  .organic-listing__icon--booth {
    width: 62px;
    height: 24px;
    background-size: 120px auto;
    background-position-x: 0;
    background-position-y: -542px; }
  .organic-listing__icon--small-order {
    height: 24px;
    background-size: 120px auto;
    background-position-x: 0;
    background-position-y: -1022px; }
  .organic-listing__icon--db-checked {
    width: 62px;
    height: 20px;
    background-size: 120px auto;
    background-position-x: 0;
    background-position-y: -744px; }
  .organic-listing__icon--cert-verified {
    width: 62px;
    height: 20px;
    background-size: 120px auto;
    background-position-x: 0;
    background-position-y: -947px; }
  .organic-listing__icon--premier-adv {
    width: 62px;
    height: 20px;
    background-size: 120px auto;
    background-position-x: 0;
    background-position-y: -246px; }
  .organic-listing__icon--supplier-ref {
    width: 62px;
    height: 20px;
    background-size: 120px auto;
    background-position-x: 0;
    background-position-y: -280px;
    background-position-y: -273px; }

.organic-listing__more {
  padding: 30px 0; }

.organic-listing__more-link {
  display: inline-block;
  position: relative;
  color: #fb5004;
  padding-right: 50px; }
  .organic-listing__more-link:after {
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -20px;
    background: url("./assets/images/common/icons/svg/orange/icon-long-arrow.svg") no-repeat center center;
    background-size: 100%; }

/* END Style organic-listing  */
/* ------------------------------------------ */
/* Style for pagination */
/* ------------------------------------------ */
.pagination {
  padding: 60px 0;
  color: #1a1a1a;
  text-align: center;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  @media screen and (max-width: 480px) {
    .pagination {
      padding: 30px 0; } }
  .pagination.mobile {
    display: none; }
    @media screen and (max-width: 480px) {
      .pagination.mobile {
        display: block; } }
  @media screen and (max-width: 480px) {
    .pagination.desktop {
      display: none; } }
  .pagination > .content-wrapper, .pagination > .app-sec__inner, .pagination > .header__wrapper, .pagination > .search-nores, .pagination > .sec-intro__detail-wrapper, .pagination > .submenu__wrapper {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }

.pagination__btn-prev,
.pagination__btn-next {
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle; }
  @media screen and (max-width: 640px) {
    .pagination__btn-prev,
    .pagination__btn-next {
      font-size: 14px; } }
  @media screen and (max-width: 480px) {
    .pagination__btn-prev,
    .pagination__btn-next {
      position: relative;
      font-size: 0px;
      width: 30px;
      height: 30px;
      padding: 0;
      border-radius: 50%; }
      .pagination__btn-prev:before,
      .pagination__btn-next:before {
        content: '';
        position: absolute;
        display: block;
        width: 7.2px;
        height: 7.2px;
        border-top: 1px solid #fb5004;
        border-right: 1px solid #fb5004;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -4px; }
      .pagination__btn-prev:hover:before,
      .pagination__btn-next:hover:before {
        border-top: 1px solid #FFF;
        border-right: 1px solid #FFF; } }
  .pagination__btn-prev:hover,
  .pagination__btn-next:hover {
    opacity: 1; }

@media screen and (max-width: 480px) {
  .pagination__btn-prev:before {
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg);
    margin-left: -2px; } }

@media screen and (max-width: 480px) {
  .pagination__btn-next:before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-left: -6px; } }

.pagination__pages {
  vertical-align: middle;
  font-weight: bold;
  margin: 0 20px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  @media screen and (max-width: 767px) {
    .pagination__pages {
      margin: 0 10px; } }
  @media screen and (max-width: 480px) {
    .pagination__pages {
      margin: 10px; } }
  .pagination__pages > span,
  .pagination__pages > div {
    font-size: 16px;
    display: inline-block;
    vertical-align: middle; }
    @media screen and (max-width: 640px) {
      .pagination__pages > span,
      .pagination__pages > div {
        font-size: 14px; } }
    @media screen and (max-width: 480px) {
      .pagination__pages > span,
      .pagination__pages > div {
        font-size: 12px; } }

.pagination__current {
  margin: 0 10px; }
  .pagination__current .pagination__current-pg {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 38px;
    padding: 0;
    text-align: center;
    color: #1a1a1a;
    border: 1px solid #1a1a1a;
    border-radius: 50%;
    font-weight: bold; }
    @media screen and (max-width: 480px) {
      .pagination__current .pagination__current-pg {
        font-size: 12px; } }
    .pagination__current .pagination__current-pg:focus {
      outline: none;
      border-color: #fb5004; }

.pagination__total {
  display: inline-block;
  margin-left: 10px; }

.pagination__page {
  margin: 0 5px; }
  @media screen and (max-width: 767px) {
    .pagination__page {
      margin: 0 4px; } }
  .pagination__page.is-active {
    color: #fb5004; }

/* END Style pagination  */
/* ------------------------------------------ */
/* Style for search-nores */
/* ------------------------------------------ */
.search-nores {
  position: relative; }

.search-nores__txt {
  font-weight: 300;
  position: relative;
  padding: 60px 5%;
  line-height: 1.8em; }

.search-nores__suggestion {
  position: relative;
  display: inline-block;
  margin-right: 10px; }
  .search-nores__suggestion:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 2px;
    background: #1a1a1a; }

/* END Style search-nores  */
/* ------------------------------------------ */
/* Style for .search-panel */
/* ------------------------------------------ */
.search-panel {
  position: relative;
  display: block; }

.search-panel__btn {
  position: relative;
  display: inline-block;
  color: #FFF;
  background: #fb5004;
  padding: 12px 35px;
  padding-left: 25px;
  border-radius: 30px;
  white-space: nowrap;
  z-index: 120; }
  .search-panel__btn:hover {
    color: #FFF; }
  @media screen and (max-width: 991px) {
    .search-panel__btn {
      padding: 12px 20px;
      padding-left: 15px; } }
  @media screen and (max-width: 767px) {
    .search-panel__btn {
      padding: 8px 15px;
      padding-left: 12px; } }
  @media screen and (max-width: 350px) {
    .search-panel__btn {
      padding: 8px 12px;
      padding-left: 8px; } }
  .search-panel__btn:before {
    content: '';
    width: 18px;
    height: 18px;
    background: url("./assets/images/common/icons/svg/white/icon-search.svg") no-repeat center center;
    background-size: 100%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px; }
    @media screen and (max-width: 991px) {
      .search-panel__btn:before {
        width: 16px;
        height: 16px; } }
    @media screen and (max-width: 767px) {
      .search-panel__btn:before {
        width: 14px;
        height: 14px; } }
    @media screen and (max-width: 350px) {
      .search-panel__btn:before {
        margin-right: 5px; } }
  .search-panel-open .search-panel__btn:before {
    background-image: url("./assets/images/common/icons/svg/white/icon-close.svg"); }

.search-panel__input {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: #FFF;
  background: #fb5004;
  border: 0; }
  @media screen and (max-width: 991px) {
    .search-panel__input {
      font-size: 14px; } }
  @media screen and (max-width: 767px) {
    .search-panel__input {
      font-size: 12px; } }
  .search-panel__input:focus {
    outline: none; }
  .search-panel__input::-webkit-input-placeholder {
    color: #FFF; }
  .search-panel__input:-moz-placeholder {
    /* Firefox 18- */
    color: #FFF; }
  .search-panel__input::-moz-placeholder {
    /* Firefox 19+ */
    color: #FFF; }
  .search-panel__input:-ms-input-placeholder {
    color: #FFF; }

.search-panel__panel {
  padding: 0 0 15px;
  position: relative; }

.search-panel__wrapper {
  text-align: center;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  @media screen and (max-width: 480px) {
    .search-panel__wrapper {
      display: block; } }

.search-panel__title {
  color: #fb5004;
  display: block;
  margin: 0;
  text-align: center;
  font-weight: 200;
  letter-spacing: 2px;
  text-transform: uppercase; }

.search-panel__txt-close {
  display: none;
  vertical-align: middle; }
  .search-panel-open .search-panel__txt-close {
    display: inline-block; }

.search-panel__txt-open {
  display: inline-block;
  vertical-align: middle; }
  .search-panel-open .search-panel__txt-open {
    display: none; }

.search-panel__input-holder {
  display: block;
  position: relative;
  width: 100%;
  max-width: 480px;
  margin: 0 30px; }
  @media screen and (max-width: 480px) {
    .search-panel__input-holder {
      margin: 10px auto 0; } }

.search-panel__input-btn {
  position: absolute;
  width: 100px;
  height: 50px;
  right: 0;
  top: 0;
  background: transparent;
  border: 0;
  z-index: 50;
  cursor: pointer; }
  @media screen and (max-width: 991px) {
    .search-panel__input-btn {
      height: 40px; } }
  .search-panel__input-btn:before {
    content: '';
    width: 20px;
    height: 20px;
    background: url("./assets/images/common/icons/svg/orange/icon-search.svg") no-repeat center center;
    background-size: 100%;
    display: inline-block;
    vertical-align: middle; }

.search-panel__input {
  position: relative;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  border: 0;
  background: #ececec;
  z-index: 15;
  color: #777;
  padding-left: 50px;
  padding-right: 60px; }
  @media screen and (max-width: 991px) {
    .search-panel__input {
      height: 40px;
      border-radius: 20px; } }
  .search-panel__input::-webkit-input-placeholder {
    color: #777; }
  .search-panel__input:-moz-placeholder {
    /* Firefox 18- */
    color: #777; }
  .search-panel__input::-moz-placeholder {
    /* Firefox 19+ */
    color: #777; }
  .search-panel__input:-ms-input-placeholder {
    color: #777; }
  .search-panel__input::-webkit-search-cancel-button {
    display: none; }
  .search-panel__input:focus {
    outline: none; }
  .search-panel__input + .search-panel__autocomplete {
    display: none; }
  .search-panel__input:focus + .search-panel__autocomplete,
  .search-panel__input + .search-panel__autocomplete:focus,
  .search-panel__input + .search-panel__autocomplete:hover {
    display: block; }

.search-panel__filters {
  padding: 15px 0;
  background: #242655;
  font-size: 0;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .search-panel__filters {
      padding: 15px 0; } }

.search-panel__filter {
  display: inline-block;
  margin-right: 45px; }
  @media screen and (max-width: 767px) {
    .search-panel__filter {
      margin-right: 30px; } }
  .search-panel__filter:last-child {
    margin-right: 0; }
  .search-panel__filter input {
    display: none; }
    .search-panel__filter input + label {
      position: relative;
      display: block;
      cursor: pointer;
      font-size: 12px;
      line-height: 2em;
      color: #FFF; }
    .search-panel__filter input:checked + label {
      font-weight: bold;
      padding: 5px 0; }
      .search-panel__filter input:checked + label:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3px;
        background: #fb5004; }

.search-panel__topsearch {
  margin-top: 25px;
  font-size: 12px;
  line-height: 1.5em; }

.search-panel__topsearch-title {
  display: inline-block;
  vertical-align: middle;
  color: #fb5004;
  font-weight: bold;
  margin: 0;
  margin-right: 25px; }

.search-panel__topsearch-item {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  margin-right: 15px; }

.search-panel__autocomplete {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  background: #ececec;
  text-align: left;
  padding-bottom: 25px;
  margin-top: -25px;
  padding-top: 25px;
  border-radius: 0 0 25px 25px;
  z-index: 5; }

.search-panel__autocomplet-grp {
  padding: 0 50px; }

.search-panel__autocomplet-item {
  display: block;
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 14px; }

.search-panel__autocomplet-subitem {
  display: block;
  padding-left: 15px;
  font-size: 12px;
  margin: 0 0 14px; }

.search-panel__keywords {
  position: relative;
  padding: 5px 0; }

.search-panel__keywords-title {
  font-size: 16px;
  line-height: 16px;
  font-weight: 900;
  display: inline-block;
  vertical-align: baseline;
  font-weight: bold;
  margin: 0;
  margin-right: 10px; }
  @media screen and (max-width: 767px) {
    .search-panel__keywords-title {
      font-size: 14px; } }

.search-panel__keywords-holder {
  display: inline-block;
  vertical-align: baseline;
  font-size: 14px;
  line-height: 16px; }
  @media screen and (max-width: 767px) {
    .search-panel__keywords-holder {
      font-size: 12px; } }

.search-panel__keywords-words {
  display: inline-block;
  vertical-align: baseline;
  font-weight: 900;
  color: #fb5004;
  line-height: 16px;
  margin: 0;
  margin-right: 5px; }

.search-panel__result-num {
  display: inline-block;
  vertical-align: baseline;
  line-height: 16px; }

.search-panel__list-control {
  position: relative;
  padding: 15px 0; }

.search-panel__list-control-holder {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0 5%; }
  @media screen and (max-width: 640px) {
    .search-panel__list-control-holder {
      display: block; } }

.search-panel__sorting {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.search-panel__sorting-title {
  font-size: 14px;
  line-height: 16px;
  font-weight: 900;
  display: inline-block;
  margin: 0;
  margin-right: 20px; }
  @media screen and (max-width: 767px) {
    .search-panel__sorting-title {
      font-size: 12px;
      margin-right: 10px; } }

.search-panel__sorting-items {
  position: relative; }

.search-panel__sorting-item {
  position: relative;
  border: 0;
  box-shadow: none;
  background: transparent;
  padding: 0;
  font-size: 14px;
  margin-right: 20px; }
  @media screen and (max-width: 767px) {
    .search-panel__sorting-item {
      margin-right: 10px;
      font-size: 12px; } }
  .search-panel__sorting-item:last-child {
    margin-right: 0; }
  .search-panel__sorting-item.is-active {
    color: #fb5004;
    font-weight: bold;
    padding: 5px 0; }
    .search-panel__sorting-item.is-active::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 3px;
      background: #fb5004; }

/* END Style .search-panel  */
/* ------------------------------------------ */
/* Style for sec-break */
/* ------------------------------------------ */
.sec-break {
  position: relative;
  margin-left: 10%;
  width: 90%;
  height: 2px;
  background: #e5e5e5; }
  .sec-break:before {
    content: '';
    width: 30px;
    height: 2px;
    background: #fb5004;
    display: block;
    position: absolute;
    left: 0;
    top: 0; }
  .sec-break.animated {
    opacity: 0;
    -webkit-transform-origin: right center;
    -ms-transform-origin: right center;
    transform-origin: right center; }
    .sec-break.animated.fadeIn {
      -webkit-animation: sec-break 0.4s;
      animation: sec-break 0.4s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      will-change: transform; }

@-webkit-keyframes sec-break {
  0% {
    -webkit-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    transform: scale(0, 1);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1; } }

@keyframes sec-break {
  0% {
    -webkit-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    transform: scale(0, 1);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1; } }

/* END Style sec-break  */
/* ------------------------------------------ */
/* Style for sec-intro */
/* ------------------------------------------ */
.sec-intro {
  text-align: center; }
  .sec-intro .button-share__title {
    opacity: 0;
    -webkit-animation: sec-intro-faderight 0.6s;
    animation: sec-intro-faderight 0.6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }
  .sec-intro .button-share {
    opacity: 0;
    -webkit-animation: sec-intro-fadeleft 0.6s;
    animation: sec-intro-fadeleft 0.6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform;
    opacity: 0; }
    .sec-intro .button-share:nth-child(2) {
      -webkit-animation-delay: 0.12s;
      animation-delay: 0.12s; }
    .sec-intro .button-share:nth-child(3) {
      -webkit-animation-delay: 0.24s;
      animation-delay: 0.24s; }
    .sec-intro .button-share:nth-child(4) {
      -webkit-animation-delay: 0.36s;
      animation-delay: 0.36s; }
    .sec-intro .button-share:nth-child(5) {
      -webkit-animation-delay: 0.48s;
      animation-delay: 0.48s; }
    .sec-intro .button-share:nth-child(6) {
      -webkit-animation-delay: 0.6s;
      animation-delay: 0.6s; }
    .sec-intro .button-share:nth-child(7) {
      -webkit-animation-delay: 0.72s;
      animation-delay: 0.72s; }
    .sec-intro .button-share:nth-child(8) {
      -webkit-animation-delay: 0.84s;
      animation-delay: 0.84s; }
    .sec-intro .button-share:nth-child(9) {
      -webkit-animation-delay: 0.96s;
      animation-delay: 0.96s; }
    .sec-intro .button-share:nth-child(10) {
      -webkit-animation-delay: 1.08s;
      animation-delay: 1.08s; }

main .sec-intro:first-child {
  /*background:imgurl('common/bg-sec-intro.jpg') no-repeat center center;*/
  background-size: cover;
  /*
    padding-top: 175px;
    @media screen and (max-width: 1580px) {
        padding-top:115px;
    }
    @media screen and (max-width: 991px) {
        padding-top: 115px;
    }
    margin-top:-130px;
    @media screen and (max-width: 1580px) {
        margin-top: -125px;
    }
    @media screen and (max-width: 991px) {
        margin-top: -70px;
    }
    @media screen and (max-width: 767px) {
        margin-top: -100px;
    }
    */
  padding-top: 110px;
  padding-bottom: 30px;
  margin-top: -110px; }
  main .sec-intro:first-child .fix-background-sec {
    /*background:imgurl('common/bg-sec-intro.jpg') no-repeat center center;*/
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: block;
    max-height: 1200px; }
  @media screen and (max-width: 1580px) {
    main .sec-intro:first-child {
      padding-top: 90px; } }
  @media screen and (max-width: 991px) {
    main .sec-intro:first-child {
      padding-top: 30px; } }
  @media screen and (max-width: 767px) {
    main .sec-intro:first-child {
      padding-top: 40px;
      padding-bottom: 20px; } }
  @media screen and (max-width: 1580px) {
    main .sec-intro:first-child {
      margin-top: -90px; } }
  @media screen and (max-width: 991px) {
    main .sec-intro:first-child {
      margin-top: -70px; } }

main .sec-intro.no-background:first-child {
  background: none; }

.sec-intro__title {
  opacity: 0;
  -webkit-animation: sec-intro-fadetop 0.6s;
  animation: sec-intro-fadetop 0.6s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  will-change: transform;
  display: block;
  max-width: 850px;
  font-weight: 100;
  margin: 40px auto 30px;
  background: #fc5e08;
  background: -moz-linear-gradient(left, #fc5e08 0%, #fd8e14 100%);
  background: -webkit-linear-gradient(left, #fc5e08 0%, #fd8e14 100%);
  background: linear-gradient(to right, #fc5e08 0%, #fd8e14 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fc5e08', endColorstr='#fd8e14', GradientType=1);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  text-transform: uppercase; }
  .is-ie .sec-intro__title {
    background: transparent;
    color: #fb5004; }

.sec-intro__desc {
  max-width: 768px;
  display: block;
  line-height: 1.5em;
  margin: 0 auto 30px;
  opacity: 0;
  -webkit-animation: sec-intro-fadetop 0.6s 0.3s;
  animation: sec-intro-fadetop 0.6s 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  will-change: transform; }
  .sec-intro__desc p {
    line-height: 1.5em; }

.sec-intro__detail {
  display: block;
  margin: 0 auto;
  padding: 100px 0;
  background: #f5f5f5; }
  @media screen and (max-width: 991px) {
    .sec-intro__detail {
      padding: 80px 0; } }
  @media screen and (max-width: 640px) {
    .sec-intro__detail {
      padding: 60px 0; } }

.sec-intro__detail-wrapper {
  max-width: 960px; }

.sec-intro__detail-item {
  display: block;
  margin: 0 auto 30px; }
  .sec-intro__detail-item:last-child {
    margin-bottom: 0; }

.sec-intro__detail-title {
  font-weight: bold;
  margin: 0 auto 20px; }

.sec-intro__detail-subtitle {
  text-decoration: underline;
  font-weight: normal;
  margin: 0 auto 10px; }

.sec-intro__detail-desc {
  line-height: 1.5em; }

.sec-menu {
  background-color: #f5f5f5;
  margin-top: 45px;
  display: block;
  width: 80%;
  position: relative;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (max-width: 991px) {
    .sec-menu {
      display: none; } }

.sec-menu.fullscreen {
  width: 100%; }

.sec-menu > div {
  width: 100%;
  margin: auto;
  display: block;
  text-align: center; }

.sec-menu > div > div {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px; }

.sec-menu .separator {
  display: none;
  border-top: none;
  width: 60%;
  display: block; }

.sec-menu .top-bar__link {
  font-size: 14px;
  padding: 20px 0px;
  margin-right: 0px; }

.sec-menu .top-bar__link.active {
  border-bottom: 2px solid #fb5004; }

.sec-menu .top-bar__link.active .top-bar__txt {
  color: #fb5004; }

.sec-menu .top-bar__link:hover .top-bar__txt {
  color: #fb5004; }

.sec-menu > div > div:hover .sec-submenu {
  display: block; }

.sec-menu .sec-submenu {
  display: none;
  position: absolute;
  min-width: 300px;
  text-align: left;
  background-color: #fb5004;
  border-top: 2px solid white;
  padding: 30px 20px;
  z-index: 5; }
  .sec-menu .sec-submenu .top-bar__link {
    padding: 12px 0; }
    .sec-menu .sec-submenu .top-bar__link .top-bar__txt {
      color: white;
      opacity: 1;
      font-size: 12px; }
  .sec-menu .sec-submenu .top-bar__link.active .top-bar__txt {
    opacity: 1; }

.header__item .sec-intro .sec-menu {
  display: none; }

@media screen and (max-width: 991px) {
  .header__item .sec-intro {
    padding: 0; }
    .header__item .sec-intro .sec-menu {
      display: block;
      background-color: transparent;
      margin-top: 0px; }
      .header__item .sec-intro .sec-menu > div > div {
        display: block; }
        .header__item .sec-intro .sec-menu > div > div .top-bar__txt {
          color: #1a1a1a;
          font-size: 18px;
          font-weight: bold; }
      .header__item .sec-intro .sec-menu .sec-submenu {
        display: block;
        position: relative;
        min-width: 0;
        text-align: center;
        background-color: transparent;
        padding: 0;
        border-top: none; }
        .header__item .sec-intro .sec-menu .sec-submenu .top-bar__txt {
          color: #1a1a1a;
          font-size: 16px;
          font-weight: normal;
          opacity: 1; }
        .header__item .sec-intro .sec-menu .sec-submenu .top-bar__link {
          padding: 8px 0; }
        .header__item .sec-intro .sec-menu .sec-submenu div:first-child .top-bar__link {
          padding-top: 0; } }

p.sec-intro__desc {
  padding-bottom: 30px; }

@-webkit-keyframes sec-intro-fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes sec-intro-fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes sec-intro-fadetop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@keyframes sec-intro-fadetop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes sec-intro-fadeleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@keyframes sec-intro-fadeleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes sec-intro-faderight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@keyframes sec-intro-faderight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

/* END Style sec-intro  */
/* ------------------------------------------ */
/* Style for side-tabs */
/* ------------------------------------------ */
.side-tabs {
  padding-bottom: 30px; }

.side-tabs__title {
  position: relative;
  color: #fb5004;
  text-align: center;
  font-weight: 300;
  margin: 0 0 30px; }

.side-tabs__items {
  position: relative;
  overflow: hidden; }
  @media screen and (max-width: 991px) {
    .side-tabs__items {
      height: auto !important; } }

.side-tabs__item .side-tabs__content {
  opacity: 0;
  z-index: -1;
  pointer-events: none;
  display: block; }
  @media screen and (max-width: 991px) {
    .side-tabs__item .side-tabs__content {
      display: none; } }

.side-tabs__item.is-active .side-tabs__content {
  opacity: 1;
  z-index: 10;
  pointer-events: all; }
  @media screen and (max-width: 991px) {
    .side-tabs__item.is-active .side-tabs__content {
      display: block; } }

.side-tabs .content-wrapper.animated .side-tabs__item .side-tabs__tab, .side-tabs .animated.app-sec__inner .side-tabs__item .side-tabs__tab, .side-tabs .animated.header__wrapper .side-tabs__item .side-tabs__tab, .side-tabs .animated.search-nores .side-tabs__item .side-tabs__tab, .side-tabs .animated.sec-intro__detail-wrapper .side-tabs__item .side-tabs__tab, .side-tabs .animated.submenu__wrapper .side-tabs__item .side-tabs__tab {
  opacity: 0; }

.side-tabs .content-wrapper.animated.fadeIn .side-tabs__item .side-tabs__tab, .side-tabs .animated.fadeIn.app-sec__inner .side-tabs__item .side-tabs__tab, .side-tabs .animated.fadeIn.header__wrapper .side-tabs__item .side-tabs__tab, .side-tabs .animated.fadeIn.search-nores .side-tabs__item .side-tabs__tab, .side-tabs .animated.fadeIn.sec-intro__detail-wrapper .side-tabs__item .side-tabs__tab, .side-tabs .animated.fadeIn.submenu__wrapper .side-tabs__item .side-tabs__tab {
  -webkit-animation: st-fadein 0.6s;
  animation: st-fadein 0.6s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards; }

.side-tabs .content-wrapper.animated.fadeIn .side-tabs__item:nth-child(2) .side-tabs__tab, .side-tabs .animated.fadeIn.app-sec__inner .side-tabs__item:nth-child(2) .side-tabs__tab, .side-tabs .animated.fadeIn.header__wrapper .side-tabs__item:nth-child(2) .side-tabs__tab, .side-tabs .animated.fadeIn.search-nores .side-tabs__item:nth-child(2) .side-tabs__tab, .side-tabs .animated.fadeIn.sec-intro__detail-wrapper .side-tabs__item:nth-child(2) .side-tabs__tab, .side-tabs .animated.fadeIn.submenu__wrapper .side-tabs__item:nth-child(2) .side-tabs__tab {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s; }

.side-tabs .content-wrapper.animated.fadeIn .side-tabs__item:nth-child(3) .side-tabs__tab, .side-tabs .animated.fadeIn.app-sec__inner .side-tabs__item:nth-child(3) .side-tabs__tab, .side-tabs .animated.fadeIn.header__wrapper .side-tabs__item:nth-child(3) .side-tabs__tab, .side-tabs .animated.fadeIn.search-nores .side-tabs__item:nth-child(3) .side-tabs__tab, .side-tabs .animated.fadeIn.sec-intro__detail-wrapper .side-tabs__item:nth-child(3) .side-tabs__tab, .side-tabs .animated.fadeIn.submenu__wrapper .side-tabs__item:nth-child(3) .side-tabs__tab {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s; }

.side-tabs .content-wrapper.animated.fadeIn .side-tabs__item:nth-child(4) .side-tabs__tab, .side-tabs .animated.fadeIn.app-sec__inner .side-tabs__item:nth-child(4) .side-tabs__tab, .side-tabs .animated.fadeIn.header__wrapper .side-tabs__item:nth-child(4) .side-tabs__tab, .side-tabs .animated.fadeIn.search-nores .side-tabs__item:nth-child(4) .side-tabs__tab, .side-tabs .animated.fadeIn.sec-intro__detail-wrapper .side-tabs__item:nth-child(4) .side-tabs__tab, .side-tabs .animated.fadeIn.submenu__wrapper .side-tabs__item:nth-child(4) .side-tabs__tab {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s; }

.side-tabs .content-wrapper.animated.fadeIn .side-tabs__item:nth-child(5) .side-tabs__tab, .side-tabs .animated.fadeIn.app-sec__inner .side-tabs__item:nth-child(5) .side-tabs__tab, .side-tabs .animated.fadeIn.header__wrapper .side-tabs__item:nth-child(5) .side-tabs__tab, .side-tabs .animated.fadeIn.search-nores .side-tabs__item:nth-child(5) .side-tabs__tab, .side-tabs .animated.fadeIn.sec-intro__detail-wrapper .side-tabs__item:nth-child(5) .side-tabs__tab, .side-tabs .animated.fadeIn.submenu__wrapper .side-tabs__item:nth-child(5) .side-tabs__tab {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s; }

.side-tabs .content-wrapper.animated.fadeIn .side-tabs__item:nth-child(6) .side-tabs__tab, .side-tabs .animated.fadeIn.app-sec__inner .side-tabs__item:nth-child(6) .side-tabs__tab, .side-tabs .animated.fadeIn.header__wrapper .side-tabs__item:nth-child(6) .side-tabs__tab, .side-tabs .animated.fadeIn.search-nores .side-tabs__item:nth-child(6) .side-tabs__tab, .side-tabs .animated.fadeIn.sec-intro__detail-wrapper .side-tabs__item:nth-child(6) .side-tabs__tab, .side-tabs .animated.fadeIn.submenu__wrapper .side-tabs__item:nth-child(6) .side-tabs__tab {
  -webkit-animation-delay: 1s;
  animation-delay: 1s; }

.side-tabs .content-wrapper.animated.fadeIn .side-tabs__item:nth-child(7) .side-tabs__tab, .side-tabs .animated.fadeIn.app-sec__inner .side-tabs__item:nth-child(7) .side-tabs__tab, .side-tabs .animated.fadeIn.header__wrapper .side-tabs__item:nth-child(7) .side-tabs__tab, .side-tabs .animated.fadeIn.search-nores .side-tabs__item:nth-child(7) .side-tabs__tab, .side-tabs .animated.fadeIn.sec-intro__detail-wrapper .side-tabs__item:nth-child(7) .side-tabs__tab, .side-tabs .animated.fadeIn.submenu__wrapper .side-tabs__item:nth-child(7) .side-tabs__tab {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s; }

.side-tabs .content-wrapper.animated.fadeIn .side-tabs__item:nth-child(8) .side-tabs__tab, .side-tabs .animated.fadeIn.app-sec__inner .side-tabs__item:nth-child(8) .side-tabs__tab, .side-tabs .animated.fadeIn.header__wrapper .side-tabs__item:nth-child(8) .side-tabs__tab, .side-tabs .animated.fadeIn.search-nores .side-tabs__item:nth-child(8) .side-tabs__tab, .side-tabs .animated.fadeIn.sec-intro__detail-wrapper .side-tabs__item:nth-child(8) .side-tabs__tab, .side-tabs .animated.fadeIn.submenu__wrapper .side-tabs__item:nth-child(8) .side-tabs__tab {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s; }

.side-tabs .content-wrapper.animated.fadeIn .side-tabs__item:nth-child(9) .side-tabs__tab, .side-tabs .animated.fadeIn.app-sec__inner .side-tabs__item:nth-child(9) .side-tabs__tab, .side-tabs .animated.fadeIn.header__wrapper .side-tabs__item:nth-child(9) .side-tabs__tab, .side-tabs .animated.fadeIn.search-nores .side-tabs__item:nth-child(9) .side-tabs__tab, .side-tabs .animated.fadeIn.sec-intro__detail-wrapper .side-tabs__item:nth-child(9) .side-tabs__tab, .side-tabs .animated.fadeIn.submenu__wrapper .side-tabs__item:nth-child(9) .side-tabs__tab {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s; }

.side-tabs .content-wrapper.animated.fadeIn .side-tabs__item:nth-child(10) .side-tabs__tab, .side-tabs .animated.fadeIn.app-sec__inner .side-tabs__item:nth-child(10) .side-tabs__tab, .side-tabs .animated.fadeIn.header__wrapper .side-tabs__item:nth-child(10) .side-tabs__tab, .side-tabs .animated.fadeIn.search-nores .side-tabs__item:nth-child(10) .side-tabs__tab, .side-tabs .animated.fadeIn.sec-intro__detail-wrapper .side-tabs__item:nth-child(10) .side-tabs__tab, .side-tabs .animated.fadeIn.submenu__wrapper .side-tabs__item:nth-child(10) .side-tabs__tab {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s; }

@-webkit-keyframes st-fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes st-fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.side-tabs__item-title {
  position: relative;
  color: #fb5004;
  font-weight: 300;
  margin: 0 0 15px; }
  @media screen and (max-width: 991px) {
    .side-tabs__item-title {
      display: none; } }

.side-tabs__tab {
  display: block;
  background: #242655;
  color: #FFF;
  padding: 30px 45px;
  border-bottom: 1px solid #FFF;
  width: 32%; }
  @media screen and (max-width: 1580px) {
    .side-tabs__tab {
      width: 35%; } }
  @media screen and (max-width: 1280px) {
    .side-tabs__tab {
      width: 40%; } }
  @media screen and (max-width: 991px) {
    .side-tabs__tab {
      width: 100%;
      padding: 30px; } }
  @media screen and (max-width: 380px) {
    .side-tabs__tab {
      padding: 20px; } }
  .side-tabs__tab:last-child {
    border-bottom: 0; }
  .side-tabs__tab:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-left: 5px;
    width: 0.4em;
    height: 0.4em;
    border-top: 2px solid #fb5004;
    border-right: 2px solid #fb5004;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }
  .side-tabs__tab:hover {
    background: #fb5004;
    color: #FFF;
    opacity: 1; }
    .side-tabs__tab:hover:after {
      border-top: 2px solid #FFF;
      border-right: 2px solid #FFF; }
      @media screen and (min-width: 992px) {
        .is-active .side-tabs__tab:hover:after {
          border-top: 2px solid #fb5004;
          border-right: 2px solid #fb5004; } }
  @media screen and (min-width: 992px) {
    .is-active .side-tabs__tab {
      background: #f5f5f5;
      color: #1a1a1a;
      font-weight: bold; } }

.side-tabs__content {
  position: absolute;
  display: block;
  width: 68%;
  left: 32%;
  top: 0;
  padding: 60px;
  background: #f5f5f5;
  -webkit-transform-origin: center top;
  -ms-transform-origin: center top;
  transform-origin: center top;
  transition: 0.3s; }
  @media screen and (min-width: 992px) {
    .side-tabs__content:before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 10000px;
      left: 0;
      top: 0;
      z-index: 0;
      background: #f5f5f5; } }
  .side-tabs__content .side-tabs__item-title,
  .side-tabs__content .accordion {
    position: relative;
    z-index: 10; }
  @media screen and (max-width: 1580px) {
    .side-tabs__content {
      width: 65%;
      left: 35%; } }
  @media screen and (max-width: 1280px) {
    .side-tabs__content {
      width: 60%;
      left: 40%; } }
  @media screen and (max-width: 991px) {
    .side-tabs__content {
      position: relative;
      width: 100%;
      left: auto;
      top: auto;
      padding: 0; } }

/* END Style side-tabs  */
/* ------------------------------------------ */
/* Style for side-title */
/* ------------------------------------------ */
.side-title {
  position: absolute;
  left: 105%;
  bottom: 100%;
  padding: 4px 0;
  white-space: nowrap;
  -webkit-transform-origin: left bottom;
  -ms-transform-origin: left bottom;
  transform-origin: left bottom;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  transition: 0.4s;
  font-weight: 500; }
  .animated .side-title {
    -webkit-transform: translateY(-60px) rotate(90deg);
    -ms-transform: translateY(-60px) rotate(90deg);
    transform: translateY(-60px) rotate(90deg);
    opacity: 0; }
  .animated.fadeIn .side-title {
    -webkit-transform: translateX(0) rotate(90deg);
    -ms-transform: translateX(0) rotate(90deg);
    transform: translateX(0) rotate(90deg);
    opacity: 1; }
  .side-title:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: #000;
    transition: 1s; }
    .animated .side-title:after {
      opacity: 0;
      -webkit-transform: scale(0, 1);
      -ms-transform: scale(0, 1);
      transform: scale(0, 1); }
    .animated.fadeIn .side-title:after {
      opacity: 1;
      -webkit-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      transform: scale(1, 1); }
  @media screen and (max-width: 991px) {
    .side-title {
      display: none; } }

/* END Style side-title  */
/* ------------------------------------------ */
/* Style for sitemap */
/* ------------------------------------------ */
.sitemap {
  position: relative; }
  @media screen and (max-width: 991px) {
    .sitemap {
      display: none; } }

.sitemap__row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }

.sitemap__col {
  display: inline-block;
  width: 20%;
  padding: 0 30px; }
  @media screen and (max-width: 1200px) {
    .sitemap__col {
      padding: 0 15px; } }

.sitemap__head {
  display: block;
  margin-bottom: 15px;
  font-weight: normal;
  line-height: 1.3; }
  .sitemap__head strong {
    font-weight: 900;
    display: block; }

.sitemap__link {
  display: block;
  margin-bottom: 8px;
  font-weight: lighter; }

.sitemap__quicklinks {
  position: relative; }

.sitemap__quicklink {
  display: block;
  margin-bottom: 8px; }

/* END Style sitemap  */
/* ------------------------------------------ */
.socialMedia {
  padding-bottom: 60px; }

.socialMedia .sec-intro:first-child {
  padding-top: 0;
  margin-top: 0; }
  .socialMedia .sec-intro:first-child p {
    margin: auto; }

.socialMedia .sec-menu {
  display: none; }

.socialMedia p, .socialMedia ul {
  margin: 0;
  padding: 0; }

.socialMedia ul {
  margin-bottom: 20px;
  margin-left: 15px; }

.socialMedia .wrapper {
  max-width: 1440px;
  margin: auto;
  justify-content: center; }

.socialMedia .wrapper:after {
  content: '';
  flex: auto; }

.socialMedia p.category {
  font-size: 14px;
  line-height: 1.5;
  font-weight: bold; }

.socialMedia li {
  font-size: 14px;
  line-height: 1.5;
  color: #7F7F7F;
  margin-top: 4px; }

.socialMedia li a {
  color: #7F7F7F; }

.socialMedia p.platform {
  color: #fb5004;
  font-size: 18px;
  line-height: 1.5;
  font-weight: bold;
  padding: 10px 0; }

.socialMedia .mediaBlock {
  padding: 40px;
  background-color: #F7F7F7;
  width: 350px;
  /*margin: 25px 20px;*/
  border-radius: 10px; }
  @media screen and (max-width: 767px) {
    .socialMedia .mediaBlock {
      width: 90%;
      margin: auto; } }
  .socialMedia .mediaBlock button {
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    color: #7F7F7F;
    border: 0;
    padding: 0;
    background: transparent; }

/* Style for success-stories */
/* ------------------------------------------ */
.success-stories {
  position: relative;
  padding: 90px 0;
  background: #f5f5f5; }
  .success-stories .content-wrapper:before, .success-stories .app-sec__inner:before, .success-stories .header__wrapper:before, .success-stories .search-nores:before, .success-stories .sec-intro__detail-wrapper:before, .success-stories .submenu__wrapper:before {
    content: '';
    position: absolute;
    display: block;
    width: 90px;
    height: 90px;
    background: url("./assets/images//common/icons/icon-quote.png") no-repeat center center;
    background-size: 100%;
    left: 0;
    top: -30px;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }
    @media screen and (max-width: 991px) {
      .success-stories .content-wrapper:before, .success-stories .app-sec__inner:before, .success-stories .header__wrapper:before, .success-stories .search-nores:before, .success-stories .sec-intro__detail-wrapper:before, .success-stories .submenu__wrapper:before {
        width: 60px;
        height: 60px; } }
    @media screen and (max-width: 767px) {
      .success-stories .content-wrapper:before, .success-stories .app-sec__inner:before, .success-stories .header__wrapper:before, .success-stories .search-nores:before, .success-stories .sec-intro__detail-wrapper:before, .success-stories .submenu__wrapper:before {
        width: 45px;
        height: 45px;
        top: -45px; } }
  .success-stories .content-wrapper:after, .success-stories .app-sec__inner:after, .success-stories .header__wrapper:after, .success-stories .search-nores:after, .success-stories .sec-intro__detail-wrapper:after, .success-stories .submenu__wrapper:after {
    z-index: 10;
    content: '';
    position: absolute;
    display: block;
    width: 180px;
    height: 180px;
    background: url("./assets/images//common/icons/icon-quote.png") no-repeat center center;
    background-size: 100%;
    right: 30px;
    bottom: -30px; }
    @media screen and (max-width: 991px) {
      .success-stories .content-wrapper:after, .success-stories .app-sec__inner:after, .success-stories .header__wrapper:after, .success-stories .search-nores:after, .success-stories .sec-intro__detail-wrapper:after, .success-stories .submenu__wrapper:after {
        width: 140px;
        height: 140px; } }
    @media screen and (max-width: 767px) {
      .success-stories .content-wrapper:after, .success-stories .app-sec__inner:after, .success-stories .header__wrapper:after, .success-stories .search-nores:after, .success-stories .sec-intro__detail-wrapper:after, .success-stories .submenu__wrapper:after {
        width: 90px;
        height: 90px;
        right: 0; } }
  .success-stories .content-wrapper.animated:before, .success-stories .animated.app-sec__inner:before, .success-stories .animated.header__wrapper:before, .success-stories .animated.search-nores:before, .success-stories .animated.sec-intro__detail-wrapper:before, .success-stories .animated.submenu__wrapper:before, .success-stories .content-wrapper.animated:after, .success-stories .animated.app-sec__inner:after, .success-stories .animated.header__wrapper:after, .success-stories .animated.search-nores:after, .success-stories .animated.sec-intro__detail-wrapper:after, .success-stories .animated.submenu__wrapper:after {
    opacity: 0; }
  .success-stories .content-wrapper.animated.fadeIn, .success-stories .animated.fadeIn.app-sec__inner, .success-stories .animated.fadeIn.header__wrapper, .success-stories .animated.fadeIn.search-nores, .success-stories .animated.fadeIn.sec-intro__detail-wrapper, .success-stories .animated.fadeIn.submenu__wrapper {
    opacity: 1; }
    .success-stories .content-wrapper.animated.fadeIn:before, .success-stories .animated.fadeIn.app-sec__inner:before, .success-stories .animated.fadeIn.header__wrapper:before, .success-stories .animated.fadeIn.search-nores:before, .success-stories .animated.fadeIn.sec-intro__detail-wrapper:before, .success-stories .animated.fadeIn.submenu__wrapper:before {
      -webkit-animation: ss-quote-open 0.6s;
      animation: ss-quote-open 0.6s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      will-change: transform; }
    .success-stories .content-wrapper.animated.fadeIn:after, .success-stories .animated.fadeIn.app-sec__inner:after, .success-stories .animated.fadeIn.header__wrapper:after, .success-stories .animated.fadeIn.search-nores:after, .success-stories .animated.fadeIn.sec-intro__detail-wrapper:after, .success-stories .animated.fadeIn.submenu__wrapper:after {
      -webkit-animation: ss-quote-close 0.6s;
      animation: ss-quote-close 0.6s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      will-change: transform; }

@-webkit-keyframes ss-quote-open {
  0% {
    -webkit-transform: rotate(180deg) translate(45px, 45px);
    -ms-transform: rotate(180deg) translate(45px, 45px);
    transform: rotate(180deg) translate(45px, 45px);
    opacity: 0; }
  100% {
    -webkit-transform: rotate(180deg) translate(0, 0);
    -ms-transform: rotate(180deg) translate(0, 0);
    transform: rotate(180deg) translate(0, 0);
    opacity: 1; } }

@keyframes ss-quote-open {
  0% {
    -webkit-transform: rotate(180deg) translate(45px, 45px);
    -ms-transform: rotate(180deg) translate(45px, 45px);
    transform: rotate(180deg) translate(45px, 45px);
    opacity: 0; }
  100% {
    -webkit-transform: rotate(180deg) translate(0, 0);
    -ms-transform: rotate(180deg) translate(0, 0);
    transform: rotate(180deg) translate(0, 0);
    opacity: 1; } }

@-webkit-keyframes ss-quote-close {
  0% {
    -webkit-transform: translate(45px, 45px);
    -ms-transform: translate(45px, 45px);
    transform: translate(45px, 45px);
    opacity: 0; }
  100% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1; } }

@keyframes ss-quote-close {
  0% {
    -webkit-transform: translate(45px, 45px);
    -ms-transform: translate(45px, 45px);
    transform: translate(45px, 45px);
    opacity: 0; }
  100% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1; } }

.success-stories__title {
  position: relative;
  color: #fb5004;
  text-align: center;
  font-weight: 300;
  margin: 0 0 30px; }

.success-stories__slider {
  position: relative;
  overflow: hidden; }
  .success-stories .content-wrapper.animated .success-stories__slider, .success-stories .animated.app-sec__inner .success-stories__slider, .success-stories .animated.header__wrapper .success-stories__slider, .success-stories .animated.search-nores .success-stories__slider, .success-stories .animated.sec-intro__detail-wrapper .success-stories__slider, .success-stories .animated.submenu__wrapper .success-stories__slider {
    opacity: 0; }
  .success-stories .content-wrapper.animated.fadeIn .success-stories__slider, .success-stories .animated.fadeIn.app-sec__inner .success-stories__slider, .success-stories .animated.fadeIn.header__wrapper .success-stories__slider, .success-stories .animated.fadeIn.search-nores .success-stories__slider, .success-stories .animated.fadeIn.sec-intro__detail-wrapper .success-stories__slider, .success-stories .animated.fadeIn.submenu__wrapper .success-stories__slider {
    -webkit-animation: ss-fadein 0.6s 0.3s;
    animation: ss-fadein 0.6s 0.3s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }

@-webkit-keyframes ss-fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes ss-fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.success-stories__slide {
  font-size: 0; }

.success-stories__img {
  display: block;
  vertical-align: middle;
  width: 100%;
  margin: 0 auto; }
  .success-stories__img img {
    width: 100%; }
  @media screen and (max-width: 640px) {
    .success-stories__img {
      width: 100%;
      margin-bottom: 30px; } }

.success-stories__info {
  display: block;
  vertical-align: middle;
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
  opacity: 0; }
  @media screen and (max-width: 640px) {
    .success-stories__info {
      width: 100%;
      padding: 0; } }
  .swiper-slide-active .success-stories__info {
    -webkit-animation: ss-fadeleft 0.6s 0.2s;
    animation: ss-fadeleft 0.6s 0.2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }

@-webkit-keyframes ss-fadeleft {
  0% {
    -webkit-transform: translateX(-10%);
    -ms-transform: translateX(-10%);
    transform: translateX(-10%);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@keyframes ss-fadeleft {
  0% {
    -webkit-transform: translateX(-10%);
    -ms-transform: translateX(-10%);
    transform: translateX(-10%);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }
  .swiper-slide-next .success-stories__info {
    -webkit-animation: ss-fadeleft 0.6s 0.2s;
    animation: ss-fadeleft 0.6s 0.2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }

.success-stories__info-title {
  font-weight: bold;
  margin: 0 0 10px; }

.success-stories__info-desc {
  line-height: 1.5em;
  margin: 0; }
  .success-stories__info-desc a {
    text-decoration: underline;
    color: #fb5004;
    margin-left: 5px;
    font-weight: bold; }

.success-stories__top {
  position: relative;
  padding: 0 60px; }

.success-stories__control {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 0; }

.success-stories__btn-prev,
.success-stories__btn-next {
  width: 36px;
  height: 36px;
  border: 1px solid #777;
  display: block;
  border-radius: 50%;
  position: absolute;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  top: 50%;
  margin-top: -18px; }
  .success-stories__btn-prev:after,
  .success-stories__btn-next:after {
    content: '';
    position: absolute;
    display: block;
    width: 7.2px;
    height: 7.2px;
    border-top: 1px solid #777;
    border-right: 1px solid #777;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -2.736px;
    margin-left: -5.4px; }

.success-stories__btn-prev {
  left: 0;
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg); }

.success-stories__btn-next {
  right: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.success-stories__bottom {
  position: relative; }

.success-stories__pagination {
  padding: 15px 0;
  text-align: center; }
  .success-stories .content-wrapper.animated .success-stories__pagination, .success-stories .animated.app-sec__inner .success-stories__pagination, .success-stories .animated.header__wrapper .success-stories__pagination, .success-stories .animated.search-nores .success-stories__pagination, .success-stories .animated.sec-intro__detail-wrapper .success-stories__pagination, .success-stories .animated.submenu__wrapper .success-stories__pagination {
    opacity: 0; }
  .success-stories .content-wrapper.animated.fadeIn .success-stories__pagination, .success-stories .animated.fadeIn.app-sec__inner .success-stories__pagination, .success-stories .animated.fadeIn.header__wrapper .success-stories__pagination, .success-stories .animated.fadeIn.search-nores .success-stories__pagination, .success-stories .animated.fadeIn.sec-intro__detail-wrapper .success-stories__pagination, .success-stories .animated.fadeIn.submenu__wrapper .success-stories__pagination {
    -webkit-animation: ss-fadein 0.6s 0.4s;
    animation: ss-fadein 0.6s 0.4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }
  .success-stories__pagination .swiper-pagination-bullet {
    cursor: pointer;
    width: 15px;
    height: 3px;
    border: 0;
    border-radius: 0;
    margin: 0 3px; }
    .success-stories__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background: #fb5004; }

.success-stories__btn-row {
  margin-top: 30px;
  text-align: center; }
  .success-stories .content-wrapper.animated .success-stories__btn-row, .success-stories .animated.app-sec__inner .success-stories__btn-row, .success-stories .animated.header__wrapper .success-stories__btn-row, .success-stories .animated.search-nores .success-stories__btn-row, .success-stories .animated.sec-intro__detail-wrapper .success-stories__btn-row, .success-stories .animated.submenu__wrapper .success-stories__btn-row {
    opacity: 0; }
  .success-stories .content-wrapper.animated.fadeIn .success-stories__btn-row, .success-stories .animated.fadeIn.app-sec__inner .success-stories__btn-row, .success-stories .animated.fadeIn.header__wrapper .success-stories__btn-row, .success-stories .animated.fadeIn.search-nores .success-stories__btn-row, .success-stories .animated.fadeIn.sec-intro__detail-wrapper .success-stories__btn-row, .success-stories .animated.fadeIn.submenu__wrapper .success-stories__btn-row {
    -webkit-animation: ss-fadein 0.6s 0.6s;
    animation: ss-fadein 0.6s 0.6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }

/* END Style success-stories  */
/* ------------------------------------------ */
/* Style for submenu */
/* ------------------------------------------ */
.submenu {
  display: none;
  position: absolute;
  overflow: hidden;
  width: 100vw;
  top: 100%;
  margin-top: 15px;
  left: calc(720px - 50vw);
  background: #FFF; }
  @media screen and (min-width: 992px) {
    .submenu {
      display: block;
      max-height: 0;
      pointer-events: none;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.08); }
      .nav-link.is-hover .submenu {
        pointer-events: all;
        max-height: 640px;
        transition: max-height 0.8s; } }
  @media screen and (max-width: 1529px) {
    .submenu {
      left: -3.1948%; } }
  @media screen and (max-width: 991px) {
    .submenu {
      position: relative;
      background: transparent;
      top: auto;
      left: auto;
      width: 100%;
      margin-top: 0; }
      .nav-link.is-open .submenu {
        display: block; } }
  @media screen and (min-width: 992px) {
    .header--sub-portal .submenu {
      left: 50%;
      width: 250px;
      margin-left: -125px;
      background: #fb5004;
      color: #FFF; } }

.submenu__wrapper {
  padding: 30px 45px;
  max-width: 1440px;
  border-top: 1px solid #e5e5e5; }
  @media screen and (max-width: 991px) {
    .submenu__wrapper {
      border-top: 0;
      margin-top: 15px;
      padding: 15px 0; }
      .submenu__wrapper:before {
        content: '';
        position: absolute;
        top: 10px;
        left: 50%;
        display: block;
        width: 60px;
        height: 1px;
        margin-left: -30px;
        background: #e5e5e5; } }
    @media screen and (max-width: 991px) and (max-width: 991px) {
      .header--sub-portal .submenu__wrapper:before {
        top: 0; } }
  @media screen and (min-width: 992px) {
    .header--sub-portal .submenu__wrapper {
      border-top: 0;
      padding: 30px 15px; } }
  @media screen and (max-width: 991px) {
    .header--sub-portal .submenu__wrapper {
      margin-top: 25px;
      padding-top: 30px; } }

.submenu__body {
  position: relative;
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.submenu__intro {
  width: 33.3333%;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  font-size: 0;
  padding-right: 30px;
  -webkit-transform: translateX(-60px);
  -ms-transform: translateX(-60px);
  transform: translateX(-60px);
  transition: 0.4s 0.3s; }
  @media screen and (max-width: 991px) {
    .submenu__intro {
      display: none; } }
  @media screen and (min-width: 992px) {
    .submenu__intro {
      opacity: 0; }
      .nav-link.is-hover .submenu__intro {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0); } }

.submenu__intro-title {
  margin: 0 0 15px;
  font-weight: 900; }
  :lang(en) .submenu__intro-title {
    font-weight: 300; }
  .submenu__intro-title strong {
    font-weight: 900;
    margin-bottom: 5px; }
    :lang(en) .submenu__intro-title strong {
      display: block; }
  .submenu__intro-title:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-left: 5px;
    width: 0.4em;
    height: 0.4em;
    border-top: 2px solid #fb5004;
    border-right: 2px solid #fb5004;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }

.submenu__intro-desc {
  line-height: 1.5; }

.submenu__intro-img {
  position: relative;
  width: 100%; }

.submenu__intro-btns .button, .submenu__intro-btns .feature-listing__btn, .submenu__intro-btns .feedback-panel__btn, .submenu__intro-btns .organic-listing__btn, .submenu__intro-btns .pagination__btn-prev,
.submenu__intro-btns .pagination__btn-next, .submenu__intro-btns .child-item__link {
  margin-top: 15px; }

.submenu__intro-links a:first-child:before {
  content: '';
  display: block;
  width: 60px;
  height: 1px;
  margin-bottom: 30px;
  background: #fb5004; }

.submenu__items {
  width: 66.6666%;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }
  @media screen and (max-width: 991px) {
    .submenu__items {
      width: 100%; } }
  @media screen and (min-width: 992px) {
    .submenu__items {
      opacity: 0;
      -webkit-transform: translateY(30px);
      -ms-transform: translateY(30px);
      transform: translateY(30px);
      transition: 0.4s 0.3s; }
      .nav-link.is-hover .submenu__items {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0); } }

.submenu__col {
  width: 33.3333%;
  display: inline-block;
  vertical-align: middle;
  padding: 0 30px;
  margin: 20px 0; }
  .submenu__col div,
  .submenu__col a {
    line-height: 1.15; }
  @media screen and (max-width: 991px) {
    .submenu__col {
      padding: 0;
      width: 100%;
      text-align: center;
      margin: 5px 0; } }

.submenu__event {
  width: 50%;
  display: inline-block;
  vertical-align: middle;
  padding: 0 30px;
  margin: 20px 0; }
  @media screen and (max-width: 991px) {
    .submenu__event {
      display: none; } }
  @media screen and (max-width: 767px) {
    .submenu__event {
      padding: 0 15px; } }
  @media screen and (max-width: 480px) {
    .submenu__event {
      max-width: 420px;
      width: 100%;
      padding: 0; } }

.submenu__event-img {
  position: relative; }
  .submenu__event:hover .submenu__event-img {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }

.submenu__event-title {
  margin: 10px 0; }
  .submenu__event-title:first-child {
    margin-top: 0; }

.submenu__event-desc {
  line-height: 1.5; }

.submenu__event-info {
  position: relative;
  font-size: 0; }

.submenu__event-break {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 14px;
  width: 1px;
  background: #1a1a1a;
  margin: 0 8px; }

.submenu__event-label {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.5;
  color: #fb5004;
  font-weight: bold;
  margin: 0; }

.submenu__event-date {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.5;
  margin: 0; }

.submenu__headlink {
  display: block;
  margin-bottom: 5px;
  font-weight: bold; }
  .submenu__headlink + .submenu__headlink {
    margin-top: 20px; }
  .submenu__headlink:hover {
    color: #fb5004; }
  @media screen and (min-width: 992px) {
    .header--sub-portal .submenu__headlink {
      text-align: left;
      font-size: 14px;
      color: #FFF;
      font-weight: normal;
      margin-bottom: 0; }
      .header--sub-portal .submenu__headlink:hover {
        color: #FFF; } }

.submenu__head {
  display: block;
  margin-bottom: 10px;
  font-weight: bold; }

.submenu__link {
  display: block;
  margin-bottom: 10px; }
  .submenu__link:hover {
    color: #fb5004; }
  @media screen and (max-width: 991px) {
    .submenu__link {
      display: none; } }

.submenu__col-more {
  display: block;
  font-weight: bold; }
  .submenu__col-more:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-left: 5px;
    width: 0.4em;
    height: 0.4em;
    border-top: 2px solid #fb5004;
    border-right: 2px solid #fb5004;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }

.submenu__bar {
  padding: 0;
  text-align: right; }
  @media screen and (max-width: 991px) {
    .submenu__bar {
      text-align: center; } }

.submenu__more-link {
  font-weight: bold; }
  .submenu__more-link:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-left: 5px;
    width: 0.4em;
    height: 0.4em;
    border-top: 2px solid #fb5004;
    border-right: 2px solid #fb5004;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }

/* END Style submenu  */
/* ------------------------------------------ */
/* Style for top-bar */
/* ------------------------------------------ */
.top-bar {
  position: relative;
  display: block;
  width: 100%;
  white-space: nowrap;
  text-align: right;
  font-size: 14px;
  z-index: 30; }
  .top-bar.top-bar--mob {
    width: 90%;
    margin: 60px auto 15px;
    text-align: center;
    display: none; }
    @media screen and (max-width: 991px) {
      .top-bar.top-bar--mob {
        display: block; } }

.top-bar__holder {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 0 14px; }
  .top-bar__holder:after {
    content: '';
    position: absolute;
    display: block;
    width: 1px;
    height: 15px;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: #777; }
  .top-bar__holder:last-child {
    padding-right: 0; }
    .top-bar__holder:last-child:after {
      display: none; }

.top-bar__subholder {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  padding: 15px 20px;
  background: #FFF;
  box-shadow: 0px 3px 4px rgba(150, 150, 150, 0.5);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%); }
  .top-bar__holder:hover .top-bar__subholder {
    display: block; }
  @media screen and (max-width: 1480px) {
    .top-bar__subholder {
      right: -30px;
      left: auto;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0); } }
  .top-bar__subholder .button-share__title {
    display: none; }
  .top-bar__subholder .button-share {
    margin-right: 12px;
    margin-bottom: 0; }
    .top-bar__subholder .button-share:last-child {
      margin-right: 0; }

.top-bar__link {
  display: inline-block;
  vertical-align: middle;
  margin-right: 14px;
  color: #777;
  font-size: 13px; }
  .top-bar__link:last-child {
    margin-right: 0; }
  .top-bar__link span {
    display: inline-block;
    vertical-align: middle; }

.top-bar__txt {
  color: #777; }

.top-bar__icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  width: 17px;
  height: 17px;
  background: url("") no-repeat center center;
  background-size: 90%;
  opacity: .6; }
  .top-bar__icon:last-child {
    margin-right: 0; }
  .top-bar__icon.top-bar__icon--login {
    background-image: url("./assets/images/common/icons/svg/black/icon-profile.svg"); }
  .top-bar__icon.top-bar__icon--share {
    background-image: url("./assets/images/common/icons/svg/black/icon-share.svg"); }

.top-bar__lang {
  text-transform: uppercase; }

/* END Style top-bar  */
/* ------------------------------------------ */
/* Style for page-about-home */
/* ------------------------------------------ */
* {
  line-height: 1.15; }

.page-abouthome-container {
  position: relative;
  line-height: 1.5; }

.home-navigation {
  position: absolute;
  transform: rotate(90deg);
  right: 0; }
  .home-navigation a span {
    font-size: 12px;
    padding-bottom: 5px;
    line-height: 1.5; }
  @media screen and (max-width: 767px) {
    .home-navigation {
      display: none; } }

.home-arrow:before {
  content: "";
  width: 36px;
  height: 36px;
  display: block;
  background: url() no-repeat 50%;
  background-image: url("./assets/images/common/icons/svg/orange/icon-long-arrow.svg");
  background-size: 100%;
  fill: #F05323; }

.icon-right-arrow-angle:before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-left: 10px;
  background: url() no-repeat 50%;
  background-image: url("./assets/images/common/icons/svg/orange/icon-right-arrow-angle.svg");
  background-size: 100%;
  fill: #F05323; }

.about-home-banner {
  max-height: 568px;
  display: block;
  object-fit: cover;
  width: 100%;
  min-height: 200px; }

.home-mission-container {
  background: linear-gradient(90deg, #fb4805 0, #fd8811);
  padding: 80px;
  text-align: center;
  color: white;
  position: relative; }
  .home-mission-container .home-navigation a {
    border-bottom: 1px solid white; }
    .home-mission-container .home-navigation a span {
      color: white; }
  .home-mission-container .home-mission-content {
    width: 90%;
    display: block;
    margin: auto;
    text-align: center; }
    .home-mission-container .home-mission-content p {
      line-height: 1.5em; }
  .home-mission-container h4 {
    font-weight: lighter;
    margin-bottom: 20px;
    line-height: 1.5; }
  .home-mission-container p {
    line-height: 1.5; }
  .home-mission-container .mission-item-container {
    display: block;
    margin-top: 40px; }
    .home-mission-container .mission-item-container .mission-item {
      display: inline-block;
      vertical-align: top;
      height: 100%;
      min-height: 200px;
      width: 22%;
      margin: 0px; }
      .home-mission-container .mission-item-container .mission-item .left-border {
        display: block;
        position: absolute;
        margin-top: 70px;
        height: 60px;
        border-left: 1px solid white; }
      .home-mission-container .mission-item-container .mission-item .right-content {
        display: block;
        margin: 20px; }
        .home-mission-container .mission-item-container .mission-item .right-content img {
          margin-bottom: 10px; }
        .home-mission-container .mission-item-container .mission-item .right-content p {
          font-weight: normal;
          line-height: 1.5; }
    .home-mission-container .mission-item-container .mission-item.first {
      border: none; }
      .home-mission-container .mission-item-container .mission-item.first .left-border {
        display: none; }
  .home-mission-container .mission-structure-container {
    display: block;
    width: 100%;
    text-align: center;
    margin: auto; }
    .home-mission-container .mission-structure-container .structure-item {
      display: inline-block;
      min-width: 200px;
      border: 1px solid white;
      border-radius: 25px;
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 0;
      margin-top: 40px;
      padding: 15px 20px; }
      .home-mission-container .mission-structure-container .structure-item a {
        color: white; }
      .home-mission-container .mission-structure-container .structure-item:hover {
        background-color: white; }
        .home-mission-container .mission-structure-container .structure-item:hover a {
          color: #fb4805; }
  @media screen and (max-width: 767px) {
    .home-mission-container {
      padding: 40px 0px;
      text-align: left; }
      .home-mission-container .home-mission-content {
        width: 100%;
        text-align: left; }
        .home-mission-container .home-mission-content > h4,
        .home-mission-container .home-mission-content > p,
        .home-mission-container .home-mission-content > .mission-item-container {
          margin-left: 20px;
          margin-right: 20px; }
      .home-mission-container .mission-item-container .mission-item {
        width: 50%;
        text-align: left;
        min-height: 150px; }
        .home-mission-container .mission-item-container .mission-item .left-border {
          display: none; }
        .home-mission-container .mission-item-container .mission-item .right-content {
          margin: 0px;
          padding-right: 20px; }
        .home-mission-container .mission-item-container .mission-item img {
          width: 25%; }
      .home-mission-container .mission-structure-container {
        margin-top: 0px; }
        .home-mission-container .mission-structure-container .structure-item {
          min-width: 160px;
          margin-left: 10px;
          margin-right: 10px;
          padding: 15px 6px; } }

.home-pledge-container {
  background-color: #f5f5f5;
  padding: 40px 0; }

.home-pledge-content {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left; }
  .home-pledge-content .left-panel {
    width: 50%;
    text-align: right;
    display: block; }
    .home-pledge-content .left-panel img {
      width: 50%;
      max-width: 480px;
      margin: 40px; }
  .home-pledge-content .right-panel {
    width: 50%;
    display: block;
    vertical-align: middle;
    align-items: center;
    justify-content: center; }
    .home-pledge-content .right-panel .item-content {
      display: block;
      margin: auto; }
    .home-pledge-content .right-panel h4 {
      font-weight: lighter;
      padding-bottom: 0px; }
    .home-pledge-content .right-panel .report-download-btn {
      margin-top: 10px;
      color: #F05323;
      border: 1px solid #F05323;
      border-radius: 25px;
      padding: 15px 25px; }
      .home-pledge-content .right-panel .report-download-btn .download-icon:before {
        content: "";
        width: 12px;
        height: 12px;
        display: inline-block;
        margin-right: 5px;
        background: url() no-repeat 50%;
        background-image: url("./assets/images/common/icons/svg/orange/icon-download.svg");
        background-size: 100%;
        fill: #F05323; }
      .home-pledge-content .right-panel .report-download-btn:hover {
        color: white;
        background-color: #F05323; }
        .home-pledge-content .right-panel .report-download-btn:hover .download-icon:before {
          background-image: url("./assets/images/common/icons/svg/white/icon-download.svg"); }
  @media screen and (max-width: 767px) {
    .home-pledge-content {
      flex-direction: column; }
      .home-pledge-content .left-panel {
        width: 100%;
        text-align: center;
        display: block; }
        .home-pledge-content .left-panel img {
          width: 60%;
          margin: 40px auto;
          border: 1px solid #f5f5f5; }
      .home-pledge-content .right-panel {
        width: 100%;
        margin-bottom: 40px;
        text-align: center; } }

.home-pages-container {
  width: 90%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-align: stretch;
  align-items: stretch;
  text-align: center;
  margin: 0px auto;
  padding: 40px 0px; }
  .home-pages-container .home-navigation {
    margin-top: 80px; }
    .home-pages-container .home-navigation a {
      border-bottom: 1px solid black; }
      .home-pages-container .home-navigation a span {
        color: black; }
  .home-pages-container .home-page-item {
    display: block;
    position: relative;
    align-self: stretch;
    min-width: 200px;
    width: 30%;
    margin: 80px 16px 20px 16px;
    vertical-align: top; }
    .home-pages-container .home-page-item img {
      position: relative;
      display: block;
      width: 80%;
      margin: auto;
      margin-bottom: -120px; }
    .home-pages-container .home-page-item .page-content {
      display: block;
      padding: 40px;
      padding-top: 140px;
      background-color: #f5f5f5;
      text-align: left; }
      .home-pages-container .home-page-item .page-content div {
        min-height: 200px; }
      .home-pages-container .home-page-item .page-content h4 {
        font-size: 30px;
        margin-bottom: 10px;
        font-weight: lighter;
        line-height: 1.15; }
      .home-pages-container .home-page-item .page-content p {
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.5em; }
      .home-pages-container .home-page-item .page-content a {
        display: block; }
  @media screen and (max-width: 767px) {
    .home-pages-container {
      flex-direction: column; }
      .home-pages-container .home-page-item {
        width: 100%;
        margin: 10px auto; }
        .home-pages-container .home-page-item img {
          width: 200px; }
        .home-pages-container .home-page-item .page-content div {
          min-height: 0; }
        .home-pages-container .home-page-item .page-content h4 {
          text-align: left; } }

.ourwork-container .home-pages-container {
  margin-top: 0px;
  padding-top: 0; }
  .ourwork-container .home-pages-container .home-page-item {
    margin-top: 0px; }

.home-network-container {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-align: stretch;
  align-items: stretch;
  text-align: left;
  margin: 40px auto 0px auto; }
  .home-network-container .left-panel {
    display: block;
    width: 50%;
    background-color: #242655;
    color: white;
    padding-top: 100px;
    padding-bottom: 100px; }
    .home-network-container .left-panel .left-content {
      width: 60%;
      margin: auto; }
      .home-network-container .left-panel .left-content h4 {
        font-weight: lighter;
        margin-bottom: 10px; }
      .home-network-container .left-panel .left-content span.desc {
        font-size: 28px;
        font-weight: lighter;
        line-height: 1.5; }
      .home-network-container .left-panel .left-content span.desc.hight-light {
        color: #F05323;
        font-weight: bold;
        font-size: 28px;
        line-height: 1.5; }
      .home-network-container .left-panel .left-content span.desc strong,
      .home-network-container .left-panel .left-content span.desc b {
        color: #F05323;
        font-weight: bold;
        font-size: 28px;
        line-height: 1.5; }
      .home-network-container .left-panel .left-content p {
        margin-top: 20px;
        margin-bottom: 0px;
        line-height: 1.5em; }
      .home-network-container .left-panel .left-content a, .home-network-container .left-panel .left-content .committees-item-nourl {
        display: block;
        color: white;
        margin-top: 20px;
        line-height: 1.5em;
        font-weight: bold; }
    .home-network-container .left-panel .committees-content {
      display: block;
      position: relative; }
      .home-network-container .left-panel .committees-content .committees-item {
        display: inline-block;
        border: 1px solid #F05323;
        margin-right: 10px;
        margin-top: 10px;
        padding: 5px;
        font-size: 12px; }
        .home-network-container .left-panel .committees-content .committees-item a, .home-network-container .left-panel .committees-content .committees-item > .committees-item-nourl {
          margin-top: 0px; }
    .home-network-container .left-panel a.discover-btn {
      margin-top: 40px; }
    .home-network-container .left-panel .discover-btn span {
      border: 1px solid white;
      border-radius: 25px;
      padding: 15px 45px;
      text-align: center;
      line-height: 1.5; }
      .home-network-container .left-panel .discover-btn span:hover {
        background-color: white;
        color: #242655; }
  .home-network-container .right-panel {
    display: block;
    position: relative;
    width: 50%; }
    .home-network-container .right-panel .map {
      display: block;
      width: 100%;
      height: 100%; }
  @media screen and (max-width: 767px) {
    .home-network-container {
      flex-direction: column;
      text-align: center; }
      .home-network-container .left-panel {
        width: 100%;
        padding: 40px 20px; }
        .home-network-container .left-panel .left-content {
          width: 100%; }
      .home-network-container .right-panel {
        width: 100%;
        height: 405px; } }

.home-what-we-do {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #f5f5f5;
  text-align: left;
  padding: 80px; }
  .home-what-we-do .left-panel {
    display: block;
    width: 50%;
    margin: auto;
    padding-right: 20px;
    text-align: right; }
    .home-what-we-do .left-panel img {
      width: 100%;
      border: 4px solid #F05323; }
    .home-what-we-do .left-panel > div {
      width: 70%;
      display: block;
      margin: auto;
      margin-right: 0; }
      @media screen and (max-width: 991px) {
        .home-what-we-do .left-panel > div {
          width: 100%;
          padding: 40px 40px 0; } }
    .home-what-we-do .left-panel a {
      display: block;
      position: relative; }
    .home-what-we-do .left-panel .icon-play-btn {
      position: absolute;
      text-align: center;
      display: block;
      left: 50%;
      top: 50%;
      margin-left: -18px;
      margin-top: -18px; }
      .home-what-we-do .left-panel .icon-play-btn:before {
        content: "";
        width: 36px;
        height: 36px;
        display: block;
        background: url() no-repeat 50%;
        background-image: url("./assets/images/common/icons/svg/white/icon-play-button.svg");
        background-size: 100%; }
  .home-what-we-do .right-panel {
    display: block;
    width: 50%;
    margin: auto;
    padding-left: 20px;
    text-align: left; }
    .home-what-we-do .right-panel h4 {
      color: #F05323;
      font-weight: normal;
      margin-bottom: 0px; }
    .home-what-we-do .right-panel .subTitle {
      font-weight: normal;
      margin-bottom: 10px; }
  @media screen and (max-width: 767px) {
    .home-what-we-do {
      flex-direction: column;
      padding: 20px 0; }
      .home-what-we-do .left-panel {
        width: 100%;
        padding-right: 0px; }
        .home-what-we-do .left-panel img {
          width: 100%; }
      .home-what-we-do .right-panel {
        width: 100%;
        padding: 40px; } }

.home-corporate-information {
  display: block;
  width: 100%;
  text-align: center;
  padding: 40px; }
  .home-corporate-information .home-navigation {
    margin-top: 80px; }
    .home-corporate-information .home-navigation a {
      border-bottom: 1px solid black; }
      .home-corporate-information .home-navigation a span {
        color: black; }
    @media screen and (max-width: 991px) {
      .home-corporate-information .home-navigation {
        margin-top: 40px; } }
  .home-corporate-information .top-panel {
    width: 50%;
    display: block;
    margin: 20px auto; }
    .home-corporate-information .top-panel h4 {
      color: #F05323;
      font-weight: normal;
      margin-bottom: 0px; }
  .home-corporate-information .bottom-panel {
    font-size: 0;
    width: 80%;
    display: flex;
    align-items: flex-start;
    margin: 40px auto; }
    @media screen and (max-width: 1200px) {
      .home-corporate-information .bottom-panel {
        width: 100%; } }
    .home-corporate-information .bottom-panel .left-panel {
      border-right: 1px solid #f5f5f5; }
    .home-corporate-information .bottom-panel .left-panel,
    .home-corporate-information .bottom-panel .right-panel {
      display: flex;
      align-items: flex-start;
      width: 50%;
      padding: 8px 40px; }
      .home-corporate-information .bottom-panel .left-panel .poster,
      .home-corporate-information .bottom-panel .right-panel .poster {
        width: 50%;
        max-width: 180px;
        border: 2px solid #f5f5f5; }
        @media screen and (max-width: 991px) {
          .home-corporate-information .bottom-panel .left-panel .poster,
          .home-corporate-information .bottom-panel .right-panel .poster {
            max-width: 150px; } }
      .home-corporate-information .bottom-panel .left-panel .annualreport-item-content,
      .home-corporate-information .bottom-panel .right-panel .annualreport-item-content {
        width: calc(100% - 180px - 40px);
        display: inline-block;
        vertical-align: middle;
        text-align: left;
        margin-left: 40px; }
        @media screen and (max-width: 991px) {
          .home-corporate-information .bottom-panel .left-panel .annualreport-item-content,
          .home-corporate-information .bottom-panel .right-panel .annualreport-item-content {
            width: calc(100% - 150px - 20px);
            margin-left: 20px; } }
        .home-corporate-information .bottom-panel .left-panel .annualreport-item-content .title,
        .home-corporate-information .bottom-panel .right-panel .annualreport-item-content .title {
          font-weight: bold;
          margin-bottom: 0px; }
        .home-corporate-information .bottom-panel .left-panel .annualreport-item-content .period,
        .home-corporate-information .bottom-panel .right-panel .annualreport-item-content .period {
          font-size: 18px; }
        .home-corporate-information .bottom-panel .left-panel .annualreport-item-content .content-container,
        .home-corporate-information .bottom-panel .right-panel .annualreport-item-content .content-container {
          margin-top: 16px;
          margin-bottom: 40px; }
        .home-corporate-information .bottom-panel .left-panel .annualreport-item-content .content,
        .home-corporate-information .bottom-panel .right-panel .annualreport-item-content .content {
          line-height: 1.5em; }
        .home-corporate-information .bottom-panel .left-panel .annualreport-item-content .home-downarrow,
        .home-corporate-information .bottom-panel .right-panel .annualreport-item-content .home-downarrow {
          display: flex;
          align-items: center;
          padding: 0 8px; }
          .home-corporate-information .bottom-panel .left-panel .annualreport-item-content .home-downarrow:before,
          .home-corporate-information .bottom-panel .right-panel .annualreport-item-content .home-downarrow:before {
            content: "";
            width: 7px;
            height: 5px;
            display: flex;
            background: url() no-repeat 50%;
            background-image: url("./assets/images/common/icons/icon-arrow-down.png");
            background-size: 100%;
            fill: #F05323;
            display: none; }
        .home-corporate-information .bottom-panel .left-panel .annualreport-item-content .report-download-btn,
        .home-corporate-information .bottom-panel .right-panel .annualreport-item-content .report-download-btn {
          color: #F05323;
          border: 1px solid #F05323;
          border-radius: 25px;
          padding: 15px 25px;
          display: inline-block; }
          .home-corporate-information .bottom-panel .left-panel .annualreport-item-content .report-download-btn .download-icon:before,
          .home-corporate-information .bottom-panel .right-panel .annualreport-item-content .report-download-btn .download-icon:before {
            content: "";
            width: 12px;
            height: 12px;
            display: inline-block;
            margin-right: 5px;
            background: url() no-repeat 50%;
            background-image: url("./assets/images/common/icons/svg/orange/icon-download.svg");
            background-size: 100%;
            fill: #F05323; }
          .home-corporate-information .bottom-panel .left-panel .annualreport-item-content .report-download-btn:hover,
          .home-corporate-information .bottom-panel .right-panel .annualreport-item-content .report-download-btn:hover {
            color: white;
            background-color: #F05323; }
            .home-corporate-information .bottom-panel .left-panel .annualreport-item-content .report-download-btn:hover .download-icon:before,
            .home-corporate-information .bottom-panel .right-panel .annualreport-item-content .report-download-btn:hover .download-icon:before {
              background-image: url("./assets/images/common/icons/svg/white/icon-download.svg"); }
    .home-corporate-information .bottom-panel .more-btn {
      background: transparent;
      border: 0;
      color: inherit;
      padding: 0;
      cursor: pointer;
      color: #F05323;
      margin-top: 4px; }
      @media screen and (max-width: 767px) {
        .home-corporate-information .bottom-panel .more-btn {
          margin-top: 0px; } }
  @media screen and (max-width: 991px) {
    .home-corporate-information .bottom-panel .left-panel,
    .home-corporate-information .bottom-panel .right-panel {
      padding: 8px 20px; } }
  @media screen and (max-width: 767px) {
    .home-corporate-information {
      padding: 40px 0px; }
      .home-corporate-information .top-panel {
        width: 100%; }
        .home-corporate-information .top-panel p {
          margin: auto 20px; }
      .home-corporate-information .bottom-panel {
        flex-direction: column;
        width: 100%; }
        .home-corporate-information .bottom-panel .left-panel {
          width: 100%;
          border-right: 0; }
          .home-corporate-information .bottom-panel .left-panel img {
            margin: 0; }
        .home-corporate-information .bottom-panel .right-panel {
          width: 100%;
          margin-top: 10px;
          text-align: center; }
          .home-corporate-information .bottom-panel .right-panel .link-item {
            display: inline-block;
            margin: 10px; } }
  .home-corporate-information .additional-content {
    display: none; }

.home-business-container {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-align: stretch;
  align-items: stretch; }
  .home-business-container .home-mean-business {
    background-color: #3a3532;
    display: block;
    width: 50%;
    padding: 80px 10% 40px 10%;
    text-align: left;
    color: white;
    min-height: 468px;
    vertical-align: top; }
    .home-business-container .home-mean-business .business-logo {
      width: 50%; }
    .home-business-container .home-mean-business h4 {
      font-weight: lighter;
      margin-bottom: 10px; }
    .home-business-container .home-mean-business p {
      line-height: 1.5em; }
    .home-business-container .home-mean-business a {
      display: block; }
  .home-business-container .home-career {
    background-color: #f5f5f5;
    display: block;
    width: 50%;
    padding: 80px 10% 40px 10%;
    text-align: left;
    min-height: 468px;
    vertical-align: top; }
    .home-business-container .home-career h4 {
      font-weight: normal;
      margin-bottom: 10px; }
    .home-business-container .home-career p {
      line-height: 1.5; }
    .home-business-container .home-career .additional-item {
      margin: 10px 0px; }
      .home-business-container .home-career .additional-item .item-text {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5; }
    .home-business-container .home-career a {
      display: block; }
  @media screen and (max-width: 767px) {
    .home-business-container {
      flex-direction: column; }
      .home-business-container .home-mean-business {
        width: 100%;
        padding: 40px 10% 40px 10%;
        min-height: 0; }
      .home-business-container .home-career {
        width: 100%;
        padding: 40px 10% 40px 10%;
        min-height: 0; } }

.careerPopup {
  background-color: #000;
  color: #fff;
  padding: 10px; }
  .careerPopup .bodyContent {
    height: 400px;
    overflow-y: scroll;
    display: block; }
  .careerPopup .button, .careerPopup .feature-listing__btn, .careerPopup .feedback-panel__btn, .careerPopup .organic-listing__btn, .careerPopup .pagination__btn-prev,
  .careerPopup .pagination__btn-next, .careerPopup .child-item__link {
    border: 3px solid #FFF;
    font-size: 18px;
    display: inline-block;
    padding: 5px 20px;
    margin-top: 20px;
    margin-right: 20px;
    width: inherit;
    height: inherit;
    color: #fff; }
    .careerPopup .button a, .careerPopup .feature-listing__btn a, .careerPopup .feedback-panel__btn a, .careerPopup .organic-listing__btn a, .careerPopup .pagination__btn-prev a, .careerPopup .pagination__btn-next a, .careerPopup .child-item__link a {
      color: #fff; }

/* END Style page-about-home  */
/* ------------------------------------------ */
/* adhoc delete google map */
.home-network-container {
  display: none; }

/* Style for sample */
/* ------------------------------------------ */
.our-mission-pledge-container {
  background-color: #ffffff;
  padding: 40px 0; }

.our-mission-container {
  background-color: #242655;
  padding: 80px;
  text-align: center;
  color: white;
  position: relative; }
  .our-mission-container .home-navigation a {
    border-bottom: 1px solid white; }
    .our-mission-container .home-navigation a span {
      color: white; }
  .our-mission-container .home-mission-content {
    width: 90%;
    display: block;
    margin: auto;
    text-align: center; }
  .our-mission-container h4 {
    font-weight: lighter;
    margin-bottom: 20px; }
  .our-mission-container .mission-item-container {
    display: block;
    margin-top: 40px; }
    .our-mission-container .mission-item-container .mission-item {
      display: inline-block;
      vertical-align: top;
      height: 100%;
      min-height: 200px;
      width: 22%;
      margin: 0px; }
      .our-mission-container .mission-item-container .mission-item .left-border {
        display: block;
        position: absolute;
        margin-top: 70px;
        height: 60px;
        border-left: 1px solid white; }
      .our-mission-container .mission-item-container .mission-item .right-content {
        display: block;
        margin: 20px; }
        .our-mission-container .mission-item-container .mission-item .right-content img {
          margin-bottom: 10px; }
        .our-mission-container .mission-item-container .mission-item .right-content p {
          font-weight: normal;
          font-size: 15px;
          line-height: 1.5em; }
    .our-mission-container .mission-item-container .mission-item.first {
      border: none; }
      .our-mission-container .mission-item-container .mission-item.first .left-border {
        display: none; }
  .our-mission-container .mission-structure-container {
    display: block;
    width: 100%;
    text-align: center;
    margin: auto; }
    .our-mission-container .mission-structure-container .structure-item {
      display: inline-block;
      min-width: 200px;
      border: 1px solid #F05323;
      border-radius: 25px;
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 15px;
      padding: 15px 20px; }
      .our-mission-container .mission-structure-container .structure-item a {
        color: white; }
      .our-mission-container .mission-structure-container .structure-item:hover {
        background-color: white; }
        .our-mission-container .mission-structure-container .structure-item:hover a {
          color: #fb4805; }
  @media screen and (max-width: 767px) {
    .our-mission-container {
      padding: 40px 0px; }
      .our-mission-container .home-mission-content {
        width: 100%; }
        .our-mission-container .home-mission-content p:first-of-type {
          margin: 20px; }
      .our-mission-container .mission-item-container {
        margin-left: 20px;
        margin-right: 20px; }
        .our-mission-container .mission-item-container .mission-item {
          width: 100%;
          min-height: 150px; }
          .our-mission-container .mission-item-container .mission-item .left-border {
            display: none; }
          .our-mission-container .mission-item-container .mission-item img {
            width: 20%;
            max-width: 100px; }
      .our-mission-container .mission-structure-container .structure-item {
        min-width: 160px;
        margin-left: 10px;
        margin-right: 10px;
        padding: 15px 6px;
        margin-bottom: 15px; } }

/* END Style sample  */
/* ------------------------------------------ */
/* Style for ourwork */
/* ------------------------------------------ */
.home-ourwork-container {
  width: 100%;
  text-align: center;
  margin: 0px auto;
  margin-bottom: -40px;
  padding: 80px 0px 80px 0px; }
  .home-ourwork-container .home-navigation {
    margin-top: 80px; }
    .home-ourwork-container .home-navigation a {
      border-bottom: 1px solid black; }
      .home-ourwork-container .home-navigation a span {
        color: black; }
  @media screen and (max-width: 767px) {
    .home-ourwork-container {
      padding: 20px 0px 20px 0px;
      margin-bottom: 0px; } }

.home-ourwork-container--expaned {
  padding: 100px 0px 0px 0px; }

@media screen and (max-width: 767px) {
  .ourwork {
    margin-left: 20px;
    margin-right: 20px; } }

.ourwork-nav {
  margin: 0 auto 0 auto;
  width: 80%; }
  @media screen and (max-width: 767px) {
    .ourwork-nav {
      width: 100%; } }

.home-ourwork-container .ourwork-nav {
  margin: 60px auto 0 auto; }
  @media screen and (max-width: 767px) {
    .home-ourwork-container .ourwork-nav {
      margin: 20px auto 0 auto; } }

.ourwork-container {
  padding-bottom: 40px; }

.ourwork-nav__inner {
  margin-left: -20px;
  margin-right: -20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-align: stretch;
  align-items: stretch; }
  @media screen and (max-width: 767px) {
    .ourwork-nav__inner {
      display: block;
      margin: 0 auto 0 auto; } }

.ourwork-head-wrapper {
  display: block;
  position: relative;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  min-width: 200px;
  vertical-align: top;
  float: left;
  margin-bottom: 40px;
  transition: margin-bottom 0.5s ease-out;
  width: 100%;
  cursor: pointer; }
  @media screen and (max-width: 767px) {
    .ourwork-head-wrapper {
      display: block;
      float: none;
      margin-top: 20px;
      margin-bottom: 20px; } }

.ourwork-head {
  margin-bottom: 20px;
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
  height: 100%; }
  .ourwork-head img {
    position: relative;
    display: block;
    width: 100%;
    margin: auto;
    height: auto; }
  @media screen and (max-width: 767px) {
    .ourwork-head {
      margin-left: 0;
      margin-right: 0;
      height: auto; } }

.ourwork-head__items {
  background-color: #3a3532;
  position: relative;
  margin-top: 0;
  transition: margin-top 0.2s ease-out;
  overflow: hidden; }
  .ourwork-head__items:before {
    display: table;
    line-height: 0;
    content: ""; }
  .ourwork-head__items:after {
    display: table;
    line-height: 0;
    content: "";
    clear: both; }

.ourwork-head__items--expanded {
  margin-top: 20px; }

.ourwork-head__item {
  margin: 5px 20px;
  background-color: #fff;
  padding: 40px 20px 40px 20px; }

.ourwork-head__item:last-child {
  margin-bottom: 60px; }

.ourwork-head__item__thumb {
  background-repeat: no-repeat;
  padding-bottom: 40%;
  height: auto;
  background-size: auto 100%;
  background-position: 50% 50%;
  overflow: hidden;
  width: 100%; }

.ourwork-head__item__overlay {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 66.66%, rgba(0, 0, 0, 0.7) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 66.66%, rgba(0, 0, 0, 0.7) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 66.66%, rgba(0, 0, 0, 0.7) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=0);
  height: 100%;
  position: absolute;
  width: 100%; }

.ourwork-head__items__title {
  font-weight: 300;
  text-align: left;
  color: #fff;
  padding: 60px 40px 55px 20px; }

.ourwork-head__items__line {
  height: 5px;
  background-color: transparent;
  width: 100%;
  position: absolute;
  top: 0; }

.ourwork-head__item__title {
  text-align: left;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px; }

.ourwork-head__title {
  font-size: 16px;
  font-weight: lighter;
  line-height: 1.15;
  vertical-align: top;
  text-align: center;
  padding: 20px 0 0; }
  @media screen and (max-width: 767px) {
    .ourwork-head__title {
      padding: 40px 40px 120px 40px;
      margin-bottom: 0px; } }

.ourwork-head__item__text {
  text-align: left; }
  .ourwork-head__item__text a:link,
  .ourwork-head__item__text a:active,
  .ourwork-head__item__text a:visited,
  .ourwork-head__item__text a:hover {
    text-decoration: underline; }

.ourwork-head__line {
  height: 5px;
  width: 100%; }

.ourwork-head__arrow-down,
.ourwork-head__items__arrow-down {
  width: 0px;
  height: 0px;
  border-left: 30px solid transparent;
  border-right: 30px solid;
  border-top: 40px solid #fff;
  color: transparent;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  z-index: 11;
  transition: bottom 0.2s ease-out, top 0.2s ease-out; }

.ourwork-head__arrow-down {
  bottom: -40px; }

.ourwork-head__items__arrow-down {
  top: -50px; }

@media screen and (max-width: 767px) {
  .ourwork-head__arrow-down {
    display: none; } }

.ourwork-head-wrapper--active .ourwork-head__items__arrow-down {
  top: -10px; }

.ourwork-head-wrapper--active .ourwork-head__arrow-down {
  bottom: -70px; }

.ourwork-head-wrapper--active .ourwork-head__items {
  margin-top: 20px; }

/* .ourwork-head-wrapper--active:last-child .ourwork-head__items{
	margin-bottom:-100px;
} */
.ourwork-head__button {
  display: inline-block;
  border: 1px solid white;
  border-radius: 25px;
  padding: 15px 45px;
  outline: none;
  cursor: pointer;
  background-color: #fff;
  font-size: 16px;
  color: #fb5004;
  border-color: #fb5004;
  position: absolute;
  bottom: 40px;
  left: 40px; }

.ourwork-head__button--active {
  background-color: #fb5004;
  color: #fff; }

.ourwork-slider {
  width: 100%;
  background-color: #f5f5f5;
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
  transition: margin-bottom 0.5s ease-out; }

.ourwork-slider--expanded {
  margin-bottom: -80px; }

/* .ourwork-slider__line{
	height:5px;
	width:100%;
	position: absolute;
	top: 0;
	transition: background-color 0.5s ease-out;
} */
.ourwork-slider__slide {
  transition: background-color 0.2s ease-out; }

.ourwork-content {
  text-align: center;
  margin: 60px auto 120px auto; }
  .ourwork-content a:link,
  .ourwork-content a:active,
  .ourwork-content a:visited,
  .ourwork-content a:hover {
    text-decoration: underline; }

.ourwork-content__title {
  font-weight: lighter;
  margin: 0 auto 20px;
  text-align: left;
  color: #fb5004;
  width: 80%;
  line-height: 1.5; }
  @media screen and (max-width: 767px) {
    .ourwork-content__title {
      width: 100%; } }

button.ourwork-head__button.showmoreToggle {
  position: absolute;
  left: 50%;
  width: auto;
  bottom: 0%;
  padding: 15px 25px;
  transform: translate(-50%, -80%);
  min-width: 150px;
  cursor: pointer; }

.mobileContent {
  width: 100%; }

.ourwork-details__mobile {
  cursor: default;
  background-color: #fff;
  min-width: 100%;
  margin: 0px -40px; }
  .ourwork-details__mobile .detailIcon {
    max-width: 120px;
    display: inline-block;
    width: 100%; }
  .ourwork-details__mobile .detailBody {
    width: 100%;
    display: inline-block; }
    .ourwork-details__mobile .detailBody .detailTitle {
      font-size: 1.5em;
      color: #333;
      margin: 0px 0px 20px 0px; }

.ourwork__caption {
  color: #242753;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer; }
  .ourwork__caption:hover {
    opacity: 0.8; }
  @media screen and (max-width: 767px) {
    .ourwork__caption {
      font-size: 24px; } }

.ourwork-content__items {
  width: 80%;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .ourwork-content__items {
      width: 100%; } }

.ourwork-item {
  position: relative;
  text-align: left;
  padding-left: 0;
  margin-bottom: 20px; }

.ourwork-item__content {
  background-color: #fff;
  padding: 40px 60px 40px 220px;
  background-repeat: no-repeat;
  background-size: 140px;
  background-position: 40px center;
  min-height: 140px; }

.ourwork-item:nth-child(2n) {
  padding-right: 0; }

.ourwork-item__title {
  font-weight: normal;
  margin-bottom: 20px; }

/* 
.ourwork-item__dim{
	position: absolute;
	top:100%;
	left:0;
	right:0;
	bottom:0;
	background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(255,0,0,1));
}
.ourwork-item__overlay{
	position: absolute;
	top:100%;
	left:0;
	right:0;
	bottom:0;
	padding:60px 40px;
	transition: top 0.5s ease-out;
}

.ourwork-item:hover .ourwork-item__overlay{
	top:0;
}

.ourwork-item__title{
	@extend .title-lv4;
	font-weight: normal;
	margin-bottom:20px;
} */
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .ourwork-nav {
    width: 85%; }
  .ourwork-nav__inner {
    margin-left: -10px;
    margin-right: -10px; }
  .ourwork-content__title,
  .ourwork-content__items {
    width: 85%; }
  .ourwork-head__title {
    font-size: 24px;
    padding: 20px 20px 80px 20px; }
  .ourwork-head {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 40px; }
  .ourwork-head__button {
    left: 20px; }
  .ourwork-item__overlay {
    padding: 40px 20px; }
  .ourwork-item {
    padding-right: 0; }
  .ourwork-item:nth-child(2n) {
    padding-left: 0; } }

.ourwork h3 {
  color: #f05323;
  font-size: 42px;
  font-weight: lighter;
  margin-bottom: 20px;
  line-height: 1.5; }

/* END Style ourwork  */
/* ------------------------------------------ */
.icon-right-arrow-angle--active:before {
  transform: rotate(90deg); }

/* Style for page-corporate-information */
/* ------------------------------------------ */
.period-dropdown {
  display: none; }

.corporate-information {
  display: block;
  width: 100%;
  text-align: center; }
  .corporate-information .corporate-information-content {
    background-color: #f5f5f5;
    background-image: url("https://img.hktdc.com/eyJidWNrZXQiOiJjbXMtaW1nbGliLXByZCIsImtleSI6IkFib3V0IFVzLzIwMjUwMTIxLzUybnRjbnktUVdKdmRYUlZjeUJ1WlhjZ1ltRmphMmR5YjNWdVpGOHhPVEl3ZURVME1BPT0uanBlZyIsInJlZ2lvbiI6ImFwLXNvdXRoZWFzdC0xIiwiZm9ybWF0IjoianBlZyIsInF1YWxpdHkiOjEwMCwiZWRpdHMiOnt9fQ==");
    /*background-position: center;*/
    padding: 40px;
    background-size: cover; }
  .corporate-information .home-navigation {
    margin-top: 80px; }
    .corporate-information .home-navigation a {
      border-bottom: 1px solid black; }
      .corporate-information .home-navigation a span {
        color: black; }
  .corporate-information .top-panel {
    width: 50%;
    display: none;
    margin: 20px auto; }
    .corporate-information .top-panel h4 {
      color: #F05323;
      font-weight: normal;
      margin-bottom: 0px; }
  .corporate-information .bottom-panel {
    width: 80%;
    display: flex;
    align-items: flex-start;
    margin: 20px auto; }
    @media screen and (max-width: 1200px) {
      .corporate-information .bottom-panel {
        width: 100%; } }
    .corporate-information .bottom-panel .left-panel {
      border-right: 1px solid #ccc; }
    .corporate-information .bottom-panel .left-panel,
    .corporate-information .bottom-panel .right-panel {
      display: flex;
      align-items: flex-start;
      width: 50%;
      padding: 8px 40px; }
      @media screen and (max-width: 991px) {
        .corporate-information .bottom-panel .left-panel,
        .corporate-information .bottom-panel .right-panel {
          padding: 8px 20px; } }
      .corporate-information .bottom-panel .left-panel .poster,
      .corporate-information .bottom-panel .right-panel .poster {
        width: 50%;
        max-width: 180px;
        border: 2px solid #f5f5f5;
        display: flex;
        flex-shrink: 0; }
        @media screen and (max-width: 991px) {
          .corporate-information .bottom-panel .left-panel .poster,
          .corporate-information .bottom-panel .right-panel .poster {
            max-width: 150px; } }
      .corporate-information .bottom-panel .left-panel .annualreport-item-content,
      .corporate-information .bottom-panel .right-panel .annualreport-item-content {
        width: calc(100% - 180px - 40px);
        display: inline-block;
        text-align: left;
        vertical-align: top;
        margin-left: 40px;
        color: #000; }
        @media screen and (max-width: 991px) {
          .corporate-information .bottom-panel .left-panel .annualreport-item-content,
          .corporate-information .bottom-panel .right-panel .annualreport-item-content {
            width: calc(100% - 150px - 20px);
            margin-left: 20px; } }
        .corporate-information .bottom-panel .left-panel .annualreport-item-content .title,
        .corporate-information .bottom-panel .right-panel .annualreport-item-content .title {
          font-weight: bold;
          margin-bottom: 0px;
          color: #242655;
          margin-bottom: 10px; }
        .corporate-information .bottom-panel .left-panel .annualreport-item-content .period-container,
        .corporate-information .bottom-panel .right-panel .annualreport-item-content .period-container {
          position: relative;
          cursor: pointer;
          color: #242655;
          max-width: 132px;
          margin-top: 2px;
          border-radius: 50px; }
        .corporate-information .bottom-panel .left-panel .annualreport-item-content .period,
        .corporate-information .bottom-panel .right-panel .annualreport-item-content .period {
          font-size: 14px;
          background-color: #F05323;
          display: flex;
          justify-content: space-between;
          border-radius: 50px; }
          .corporate-information .bottom-panel .left-panel .annualreport-item-content .period .years,
          .corporate-information .bottom-panel .right-panel .annualreport-item-content .period .years {
            padding: 5px 10px;
            margin: 1px;
            background-color: #fff;
            border-radius: 50px;
            width: 100px; }
          .corporate-information .bottom-panel .left-panel .annualreport-item-content .period.active .home-downarrow::before,
          .corporate-information .bottom-panel .right-panel .annualreport-item-content .period.active .home-downarrow::before {
            transform: rotate(180deg); }
        .corporate-information .bottom-panel .left-panel .annualreport-item-content .period-dropdown,
        .corporate-information .bottom-panel .right-panel .annualreport-item-content .period-dropdown {
          display: grid;
          max-height: 250px;
          overflow: hidden;
          position: relative;
          transition: grid-template-rows 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          background-color: white;
          grid-template-rows: 0fr;
          position: absolute;
          top: calc(100% + 4px);
          width: 100%;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; }
          .corporate-information .bottom-panel .left-panel .annualreport-item-content .period-dropdown.active,
          .corporate-information .bottom-panel .right-panel .annualreport-item-content .period-dropdown.active {
            grid-template-rows: 1fr; }
            .corporate-information .bottom-panel .left-panel .annualreport-item-content .period-dropdown.active > div,
            .corporate-information .bottom-panel .right-panel .annualreport-item-content .period-dropdown.active > div {
              overflow-y: auto; }
          .corporate-information .bottom-panel .left-panel .annualreport-item-content .period-dropdown > div,
          .corporate-information .bottom-panel .right-panel .annualreport-item-content .period-dropdown > div {
            overflow: hidden; }
          .corporate-information .bottom-panel .left-panel .annualreport-item-content .period-dropdown p,
          .corporate-information .bottom-panel .right-panel .annualreport-item-content .period-dropdown p {
            margin-bottom: 0;
            padding: 4px 6px;
            cursor: pointer;
            font-size: 14px; }
        .corporate-information .bottom-panel .left-panel .annualreport-item-content .content-container,
        .corporate-information .bottom-panel .right-panel .annualreport-item-content .content-container {
          margin-top: 16px;
          margin-bottom: 40px; }
        .corporate-information .bottom-panel .left-panel .annualreport-item-content .content,
        .corporate-information .bottom-panel .right-panel .annualreport-item-content .content {
          line-height: 1.5em;
          overflow: hidden; }
        .corporate-information .bottom-panel .left-panel .annualreport-item-content .more-btn,
        .corporate-information .bottom-panel .right-panel .annualreport-item-content .more-btn {
          background: transparent;
          border: 0;
          color: inherit;
          padding: 0;
          cursor: pointer;
          color: #F05323;
          margin-top: 4px; }
        .corporate-information .bottom-panel .left-panel .annualreport-item-content .home-downarrow,
        .corporate-information .bottom-panel .right-panel .annualreport-item-content .home-downarrow {
          display: flex;
          align-items: center;
          padding: 0 5px;
          margin-right: 5px;
          background-color: #F05323;
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px; }
          .corporate-information .bottom-panel .left-panel .annualreport-item-content .home-downarrow:before,
          .corporate-information .bottom-panel .right-panel .annualreport-item-content .home-downarrow:before {
            content: "";
            width: 14px;
            height: 10px;
            display: flex;
            background: url() no-repeat 50%;
            background-image: url("./assets/images/common/icons/icon-caret-down-white.png");
            background-size: 100%;
            transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1); }
        .corporate-information .bottom-panel .left-panel .annualreport-item-content .report-download-btn,
        .corporate-information .bottom-panel .right-panel .annualreport-item-content .report-download-btn {
          margin-top: 10px;
          color: #F05323;
          background-color: transparent;
          display: inline-block;
          border: 1px solid #F05323;
          border-radius: 25px;
          padding: 7px 40px; }
          .corporate-information .bottom-panel .left-panel .annualreport-item-content .report-download-btn .download-icon:before,
          .corporate-information .bottom-panel .right-panel .annualreport-item-content .report-download-btn .download-icon:before {
            content: "";
            width: 12px;
            height: 12px;
            display: inline-block;
            margin-right: 5px;
            background: url() no-repeat 50%;
            background-image: url("./assets/images/common/icons/svg/orange/icon-download.svg");
            background-size: 100%;
            fill: #F05323; }
          .corporate-information .bottom-panel .left-panel .annualreport-item-content .report-download-btn:hover,
          .corporate-information .bottom-panel .right-panel .annualreport-item-content .report-download-btn:hover {
            color: #fff;
            background-color: #F05323; }
            .corporate-information .bottom-panel .left-panel .annualreport-item-content .report-download-btn:hover .download-icon:before,
            .corporate-information .bottom-panel .right-panel .annualreport-item-content .report-download-btn:hover .download-icon:before {
              background-image: url("./assets/images/common/icons/svg/white/icon-download.svg"); }
  @media screen and (max-width: 767px) {
    .corporate-information .corporate-information-content {
      padding: 40px 0px; }
    .corporate-information .top-panel {
      width: 100%; }
    .corporate-information .bottom-panel {
      flex-direction: column;
      width: 100%; }
      .corporate-information .bottom-panel .left-panel,
      .corporate-information .bottom-panel .right-panel {
        width: 100%;
        border: none; }
        .corporate-information .bottom-panel .left-panel .annualreport-item-content,
        .corporate-information .bottom-panel .right-panel .annualreport-item-content {
          width: 100%;
          margin-left: 20px; } }

.additional-content {
  width: 60vw;
  margin: 20px auto;
  text-align: center; }
  .additional-content p {
    padding: 40px 0px;
    font-size: 16px;
    margin-bottom: 0;
    border-top: 1px solid #e3e3e3; }
    .additional-content p a {
      color: #242753;
      font-weight: 700; }
    .additional-content p:last-child {
      border-bottom: 1px solid #e3e3e3; }
  @media screen and (max-width: 1200px) {
    .additional-content {
      width: 100%; } }
  @media screen and (max-width: 767px) {
    .additional-content {
      text-align: center; } }

/* END Style page-corporate-information  */
/* ------------------------------------------ */
/* Style for company-profile */
/* ------------------------------------------ */
.company-banner-container {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
  padding: 0px 100px;
  background-color: #242753;
  vertical-align: top;
  color: white; }
  .company-banner-container .left-panel {
    width: 50%;
    margin: 80px auto 80px auto; }
    .company-banner-container .left-panel h4 {
      text-align: center; }
    .company-banner-container .left-panel h5 {
      line-height: 1.5em; }
    .company-banner-container .left-panel p {
      line-height: 1.5em; }
  .company-banner-container .right-panel {
    width: 40%; }
  .company-banner-container img {
    width: 70%;
    min-width: 300px; }
  @media screen and (max-width: 767px) {
    .company-banner-container {
      padding-left: 0px;
      padding-right: 0px;
      align-items: center; }
      .company-banner-container .left-panel {
        margin: 20px;
        width: 100%;
        margin-right: 0; }
      .company-banner-container .right-panel {
        width: 50%; }
        .company-banner-container .right-panel img {
          width: 100%;
          min-width: 0;
          margin-left: 20%; } }

.showcasing-container .company-banner-container .right-panel {
  margin-top: -40px;
  margin-bottom: 20px; }

.company-whatwedo-container {
  display: block;
  width: 100%;
  margin: auto;
  text-align: center;
  padding: 40px;
  vertical-align: top; }
  .company-whatwedo-container h4 {
    font-weight: normal;
    color: #f05323;
    margin-bottom: 10px; }
  .company-whatwedo-container p {
    line-height: 1.5em; }
  .company-whatwedo-container .bottom-panel {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -ms-flex-align: stretch;
    align-items: stretch;
    margin: auto;
    width: 100%; }
    .company-whatwedo-container .bottom-panel .left-panel {
      text-align: right;
      width: 33%; }
      .company-whatwedo-container .bottom-panel .left-panel > div > a {
        position: relative;
        width: 100%;
        display: block; }
      .company-whatwedo-container .bottom-panel .left-panel img {
        border: 4px solid #F05323;
        width: 100%;
        display: block;
        position: relative; }
      .company-whatwedo-container .bottom-panel .left-panel .icon-play-btn {
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        margin-left: -18px;
        margin-top: -18px; }
        .company-whatwedo-container .bottom-panel .left-panel .icon-play-btn:before {
          content: "";
          width: 36px;
          height: 36px;
          display: block;
          background: url() no-repeat 50%;
          background-image: url("./assets/images/common/icons/svg/white/icon-play-button.svg");
          background-size: 100%; }
    .company-whatwedo-container .bottom-panel .right-panel {
      text-align: left;
      width: 50%;
      padding-left: 20px;
      vertical-align: top; }
  @media screen and (max-width: 767px) {
    .company-whatwedo-container {
      min-width: 0;
      width: 100%;
      padding: 0;
      padding-left: 0;
      padding-right: 0; }
      .company-whatwedo-container h4, .company-whatwedo-container p {
        padding-left: 20px;
        padding-right: 20px; }
      .company-whatwedo-container h4 {
        padding-top: 20px; }
      .company-whatwedo-container p {
        padding-bottom: 20px; }
      .company-whatwedo-container .bottom-panel {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        flex-direction: column; }
        .company-whatwedo-container .bottom-panel .left-panel {
          padding: 0;
          width: 100%; }
          .company-whatwedo-container .bottom-panel .left-panel img {
            width: 100%; }
          .company-whatwedo-container .bottom-panel .left-panel .icon-play-btn {
            margin-left: 50%;
            margin-top: -33%; }
        .company-whatwedo-container .bottom-panel .right-panel {
          width: 100%;
          padding: 20px; } }

.company-items-container {
  margin-bottom: 100px; }
  .company-items-container > p:last-of-type {
    width: 80vw;
    margin: 20px auto; }

/* END Style company-profile  */
/* ------------------------------------------ */
/* Style for our-business */
/* ------------------------------------------ */
.expandableItem .imageWrapper {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: left;
  justify-content: left;
  -ms-flex-line-pack: left;
  align-content: left;
  -ms-flex-align: stretch;
  align-items: stretch;
  text-align: left; }

@media screen and (max-width: 767px) {
  .expandableItem .wrapper {
    width: 90%; } }

.thumb-item-container {
  width: calc(25% - 10px);
  margin: 20px 10px 20px 0px;
  text-align: left; }
  .thumb-item-container div {
    position: relative; }
    .thumb-item-container div a {
      display: block;
      position: relative; }
  .thumb-item-container p {
    margin: 10px auto;
    line-height: 1.5em; }
  .thumb-item-container .thumb-item-remark {
    font-size: 12px; }
  .thumb-item-container .item-image {
    width: 100%; }
  .thumb-item-container.is-full-width {
    width: 100%;
    min-height: 300px;
    background-color: white; }
    .thumb-item-container.is-full-width p {
      margin: 0;
      padding: 10px 20px; }
    .thumb-item-container.is-full-width .icon-play-btn {
      position: absolute;
      text-align: center;
      display: block;
      top: 50%;
      left: 50%;
      margin-left: -18px;
      margin-top: -18px; }
      .thumb-item-container.is-full-width .icon-play-btn:before {
        content: "";
        width: 36px;
        height: 36px;
        display: block;
        background: url() no-repeat 50%;
        background-image: url("./assets/images/common/icons/svg/white/icon-play-button.svg");
        background-size: 100%; }
  @media screen and (max-width: 767px) {
    .thumb-item-container {
      width: calc(100%); }
      .thumb-item-container .icon-play-btn {
        left: 50%;
        top: 40%; } }

.business-container {
  width: 100%;
  background-color: #f5f5f5;
  padding: 40px; }
  .business-container .nav-container {
    position: absolute;
    width: 80%;
    left: 10%;
    z-index: 100;
    margin-top: -240px; }
  .business-container .item-slider__btn {
    position: absolute; }
  .business-container .item-slider__btn--prev {
    left: -30px; }
    .business-container .item-slider__btn--prev:before {
      content: "";
      width: 36px;
      height: 36px;
      display: block;
      background: url() no-repeat 50%;
      background-image: url("./assets/images/common/icons/svg/icon-arrow-back.svg");
      background-size: 100%;
      fill: #F05323; }
  .business-container .item-slider__btn--next {
    right: -30px; }
    .business-container .item-slider__btn--next:before {
      content: "";
      width: 36px;
      height: 36px;
      display: block;
      background: url() no-repeat 50%;
      background-image: url("./assets/images/common/icons/svg/icon-arrow-next.svg");
      background-size: 100%;
      fill: #F05323; }
  @media screen and (max-width: 767px) {
    .business-container {
      padding-top: 0;
      padding-bottom: 0; } }

.business-slider-container {
  width: 80%;
  margin: auto;
  overflow: hidden;
  padding: 0px;
  display: block;
  position: relative; }
  .business-slider-container .swiper-wrapper {
    padding: 50px 0px; }
  @media screen and (max-width: 767px) {
    .business-slider-container .swiper-wrapper {
      padding: 20px 0px; } }

/* END Style our-business  */
/* ------------------------------------------ */
/* Style for page-items */
/* ------------------------------------------ */
.items-container {
  max-width: 1920px;
  margin: 0 auto 50px auto; }
  @media screen and (max-width: 1240px) {
    .items-container {
      width: 100%; } }

.items-row:last-of-type {
  border-bottom: 1px solid #d2d2d2; }

.items-row .row-container {
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch; }
  .items-row .row-container.row-container--slider .item-container {
    padding: 120px 0; }
    @media screen and (max-width: 1240px) {
      .items-row .row-container.row-container--slider .item-container {
        padding: 80px 0; } }
    @media screen and (max-width: 767px) {
      .items-row .row-container.row-container--slider .item-container {
        padding: 45px 0; } }
  @media screen and (max-width: 1240px) {
    .items-row .row-container.row-container--slider {
      height: auto; } }
  @media screen and (max-width: 767px) {
    .items-row .row-container {
      height: auto; } }
  .items-row .row-container > .item-container {
    padding: 80px 0;
    float: inherit;
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    .items-row .row-container > .item-container.item-container--bg {
      top: 0;
      left: 0;
      background: no-repeat center center;
      background-size: cover; }
      .items-row .row-container > .item-container.item-container--bg.item-container--bg--x-100 {
        background-position-x: 100%; }
      .items-row .row-container > .item-container.item-container--bg.item-container--bg--y-100 {
        background-position-y: 100%; }
      .items-row .row-container > .item-container.item-container--bg.item-container--bg--x-0 {
        background-position-x: 0%; }
      .items-row .row-container > .item-container.item-container--bg.item-container--bg--y-0 {
        background-position-y: 0%; }
    @media screen and (max-width: 767px) {
      .items-row .row-container > .item-container {
        padding: 45px 0; }
        .items-row .row-container > .item-container:nth-of-type(n) .item-content {
          left: initial;
          text-align: center; } }

@media screen and (max-width: 767px) {
  .item-content {
    position: relative; } }

.item-content .side-title--white {
  color: #ffffff; }
  .item-content .side-title--white:after {
    background: #ffffff; }

.item-content .side-title--orange {
  color: #fb5004; }
  .item-content .side-title--orange:after {
    background: #fb5004; }

.item-content .item-icon {
  margin-bottom: 10px; }

.item-content .item-content__title--white {
  color: #ffffff; }

.item-content .item-content__title--orange {
  color: #fb5004; }

.item-content .item-content__title {
  margin-bottom: 10px;
  font-weight: 200; }

.item-content .item-content__desc--white {
  color: #ffffff; }

.item-content .item-content__desc--orange {
  color: #fb5004; }

.item-content .item-content__desc {
  line-height: 25px;
  text-align: left; }

.item-content .item-tips .item-tips__title--white {
  color: #ffffff; }

.item-content .item-tips .item-tips__title--orange {
  color: #fb5004; }

.item-content .item-tips .item-tips__title {
  font-weight: 300;
  padding-bottom: 5px; }
  .item-content .item-tips .item-tips__title:after {
    content: ":"; }

.item-content .item-tips .item-tips__desc {
  white-space: pre-line;
  line-height: 25px;
  text-align: left; }

.item-links .item-links__title--white {
  color: #ffffff; }

.item-links .item-links__title--orange {
  color: #fb5004; }

.item-links .item-links__title {
  margin-bottom: 8px;
  font-weight: 300; }

.item-links .item-links-content--col {
  left: 0;
  padding-top: 20px;
  column-count: 1;
  column-fill: balance;
  overflow: hidden;
  column-rule: 0.5px solid;
  column-rule-color: transparent;
  z-index: inherit;
  list-style-type: none;
  padding-top: 0;
  padding-left: 0;
  margin-bottom: 10px; }
  @media screen and (max-width: 767px) {
    .item-links .item-links-content--col {
      column-count: 1; } }

.item-links .item-links-content--cols {
  left: 0;
  padding-top: 20px;
  column-count: 2;
  column-fill: balance;
  overflow: hidden;
  column-rule: 0.5px solid;
  column-rule-color: transparent;
  z-index: inherit;
  list-style-type: none;
  padding-top: 0;
  padding-left: 0;
  margin-bottom: 10px; }
  @media screen and (max-width: 767px) {
    .item-links .item-links-content--cols {
      column-count: 1; } }

.item-links .item-links-content li {
  margin-bottom: 5px;
  text-align: left; }
  .item-links .item-links-content li a:after {
    content: ">";
    padding-left: 5px; }

.item-links__more {
  text-align: left; }
  .item-links__more.item-links__more--white a {
    color: #ffffff; }
  .item-links__more.item-links__more--orange a {
    color: #fb5004; }

.item-arrow {
  margin-top: 15px; }
  .item-arrow .item-arrow__link--white:before {
    content: '';
    width: 50px;
    height: 50px;
    display: block;
    background: url() no-repeat center center;
    background-image: url("./assets/images/common/icons/svg/white/icon-long-arrow.svg");
    background-size: 100%; }
  .item-arrow .item-arrow__link--orange:before {
    content: '';
    width: 50px;
    height: 50px;
    display: block;
    background: url() no-repeat center center;
    background-image: url("./assets/images/common/icons/svg/orange/icon-long-arrow.svg");
    background-size: 100%; }
  .item-arrow a {
    display: block; }

.items-row--full .item-content {
  width: 87%;
  position: relative; }
  .items-row--full .item-content .side-title {
    left: 98%;
    margin-bottom: 30px; }
    @media screen and (max-width: 1240px) {
      .items-row--full .item-content .side-title {
        margin-bottom: 50px; } }
  .items-row--full .item-content.item--full__links--col .item-icon {
    width: 30%; }
    @media screen and (max-width: 1240px) {
      .items-row--full .item-content.item--full__links--col .item-icon {
        width: 45%; } }
    @media screen and (max-width: 768px) {
      .items-row--full .item-content.item--full__links--col .item-icon {
        width: initial; } }
  .items-row--full .item-content.item--full__links--col .item-content__title {
    width: 30%; }
    @media screen and (max-width: 1240px) {
      .items-row--full .item-content.item--full__links--col .item-content__title {
        width: 45%; } }
    @media screen and (max-width: 768px) {
      .items-row--full .item-content.item--full__links--col .item-content__title {
        width: initial; } }
  .items-row--full .item-content.item--full__child--cols .item-icon {
    width: initial; }
  .items-row--full .item-content.item--full__child--cols .item-content__title {
    width: initial; }
  .items-row--full .item-content.item--full__links--cols .item-content__desc {
    width: 45%; }
    @media screen and (max-width: 767px) {
      .items-row--full .item-content.item--full__links--cols .item-content__desc {
        width: 100%; } }
  .items-row--full .item-content.item--full__links--cols .item-links {
    right: 0%;
    left: 50%;
    transform: translate(0%, -50%); }
    @media screen and (max-width: 767px) {
      .items-row--full .item-content.item--full__links--cols .item-links {
        transform: translate(0%, 0%); } }
  .items-row--full .item-content.item--full__slider--cols .side-title {
    margin-bottom: 60px; }
  .items-row--full .item-content.item--full__slider--cols .item-content__desc {
    width: 45%; }
  @media screen and (max-width: 1240px) {
    .items-row--full .item-content.item--full__slider--cols {
      position: relative;
      transform: translate(4%, 0%);
      left: initial; }
      .items-row--full .item-content.item--full__slider--cols .side-title {
        margin-bottom: -25px; }
      .items-row--full .item-content.item--full__slider--cols .item-content__desc {
        width: 95%; } }
  .items-row--full .item-content.item--search-bar .item-links {
    top: 80%; }

.items-row--full .item-content__desc {
  width: 55%; }
  @media screen and (max-width: 767px) {
    .items-row--full .item-content__desc {
      width: 100%; } }

.items-row--full .item-links {
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px auto; }
  @media screen and (max-width: 1240px) {
    .items-row--full .item-links {
      right: 0%;
      transform: translate(-25%, -50%); } }
  @media screen and (max-width: 767px) {
    .items-row--full .item-links {
      position: initial;
      right: initial;
      top: initial;
      transform: initial;
      margin: initial; } }

@media screen and (max-width: 767px) {
  .items-row--cols .row-container {
    display: block; } }

.items-row--cols .row-container > .item-container:first-child:nth-last-child(1) {
  width: 100%; }

.items-row--cols .row-container > .item-container:first-child:nth-last-child(2),
.items-row--cols .row-container > .item-container:first-child:nth-last-child(2) ~ div {
  width: 50%; }

.items-row--cols .row-container > .item-container:first-child:nth-last-child(3),
.items-row--cols .row-container > .item-container:first-child:nth-last-child(3) ~ div {
  width: 33.33333%; }

.items-row--cols .row-container > .item-container:first-child:nth-last-child(4),
.items-row--cols .row-container > .item-container:first-child:nth-last-child(4) ~ div {
  width: 25%; }

.items-row--cols .row-container > .item-container:first-child:nth-last-child(5),
.items-row--cols .row-container > .item-container:first-child:nth-last-child(5) ~ div {
  width: 20%; }

.items-row--cols .row-container > .item-container:first-child:nth-last-child(6),
.items-row--cols .row-container > .item-container:first-child:nth-last-child(6) ~ div {
  width: 16.66667%; }

.items-row--cols .row-container > .item-container:first-child:nth-last-child(7),
.items-row--cols .row-container > .item-container:first-child:nth-last-child(7) ~ div {
  width: 14.28571%; }

.items-row--cols .row-container > .item-container:first-child:nth-last-child(8),
.items-row--cols .row-container > .item-container:first-child:nth-last-child(8) ~ div {
  width: 12.5%; }

.items-row--cols .row-container > .item-container:first-child:nth-last-child(9),
.items-row--cols .row-container > .item-container:first-child:nth-last-child(9) ~ div {
  width: 11.11111%; }

@media screen and (max-width: 767px) {
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(2),
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(2) ~ div {
    width: 100%; }
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(3),
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(3) ~ div {
    width: 100%; }
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(4),
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(4) ~ div {
    width: 100%; }
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(5),
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(5) ~ div {
    width: 100%; }
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(6),
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(6) ~ div {
    width: 100%; }
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(7),
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(7) ~ div {
    width: 100%; }
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(8),
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(8) ~ div {
    width: 100%; }
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(9),
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(9) ~ div {
    width: 100%; } }

@media screen and (max-width: 767px) {
  .items-row--cols .row-container > .item-container:nth-of-type(n) .item-content {
    left: initial;
    text-align: center; } }

.items-row--cols .row-container > .item-container .item-content {
  width: 70%;
  height: auto;
  overflow: hidden;
  margin: auto;
  top: 50%;
  margin: 0px auto; }

/* END Style page-items  */
/* ------------------------------------------ */
/* Style for page-ads */
/* ------------------------------------------ */
.sec-page-ads .ads-container {
  width: 80%;
  margin: 50px auto; }
  .sec-page-ads .ads-container .ad-container {
    text-align: center; }

.sec-page-ads .ad-container a:hover {
  opacity: 1; }

/* END Style page-ads  */
/* ------------------------------------------ */
/* Style for .page-item-search */
/* ------------------------------------------ */
.item-search {
  position: absolute;
  left: 50%;
  top: 10%;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  margin: 0px auto;
  width: 100%; }
  @media screen and (max-width: 767px) {
    .item-search {
      position: initial;
      transform: initial;
      margin-top: 20px; } }
  .item-search .content-wrapper, .item-search .app-sec__inner, .item-search .header__wrapper, .item-search .search-nores, .item-search .sec-intro__detail-wrapper, .item-search .submenu__wrapper {
    margin-left: 0;
    width: 45%; }
    @media screen and (max-width: 767px) {
      .item-search .content-wrapper, .item-search .app-sec__inner, .item-search .header__wrapper, .item-search .search-nores, .item-search .sec-intro__detail-wrapper, .item-search .submenu__wrapper {
        width: 100%;
        margin: 0 auto; } }

.item-search__input-holder {
  display: block;
  position: relative;
  width: 100%;
  max-width: 720px;
  margin: 0;
  overflow: hidden;
  border-radius: 32.5px;
  border: 0;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.04), 0 1px 10px rgba(0, 0, 0, 0.06); }

.item-search__input {
  position: relative;
  width: 100%;
  height: 65px;
  border: 0;
  padding-left: 25px;
  padding-right: 60px; }
  .item-search__input::placeholder {
    color: #fb5004;
    opacity: 1; }
  .item-search__input::-webkit-search-cancel-button {
    display: none; }
  .item-search__input:focus {
    outline: none; }
  .item-search__input + .item-search__autocomplete {
    display: none; }
  .item-search__input:focus + .item-search__autocomplete,
  .item-search__input + .item-search__autocomplete:focus,
  .item-search__input + .item-search__autocomplete:hover {
    display: block; }

.item-search__input-btn {
  position: absolute;
  width: 100px;
  height: 65px;
  right: -25px;
  top: 0;
  background: transparent;
  border: 0;
  cursor: pointer; }
  .item-search__input-btn:before {
    content: '';
    width: 24px;
    height: 24px;
    background: url("./assets/images/common/icons/svg/orange/icon-search.svg") no-repeat center center;
    background-size: 100%;
    display: inline-block;
    vertical-align: middle; }

.item-search__topsearch {
  margin-top: 5px;
  margin-bottom: 15px;
  font-size: 12px;
  text-align: center; }

.item-search__topsearch-title {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  font-weight: 700;
  margin: 0;
  margin-right: 25px; }

.item-search__topsearch-item {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  margin-right: 15px;
  color: #fff; }

/* END Style .page-item-search  */
/* ------------------------------------------ */
/* Style for .page-item-search */
/* ------------------------------------------ */
.item-content.item--full__child--col > .item-content__desc {
  width: 30%; }
  @media screen and (max-width: 1240px) {
    .item-content.item--full__child--col > .item-content__desc {
      width: 45%; } }
  @media screen and (max-width: 767px) {
    .item-content.item--full__child--col > .item-content__desc {
      width: 100%; } }

.item-content.item--full__child--col > .item-links {
  left: 40%;
  transform: translate(0%, -50%); }
  @media screen and (max-width: 1240px) {
    .item-content.item--full__child--col > .item-links {
      position: initial;
      right: initial;
      top: initial;
      transform: translate(0%, 0%); } }

@media screen and (max-width: 767px) {
  .item-content.item--full__child--col .item-arrow .item-arrow__link {
    display: inline-block; } }

.item-content.item--full__child--cols .item-icon {
  text-align: center; }

.item-content.item--full__child--cols .item-content__title {
  text-align: center; }

.item-content.item--full__child--cols .item-content__desc {
  width: 100%;
  text-align: center; }

.child-item-container .child-item__img {
  max-height: 195px;
  overflow: hidden; }
  .child-item-container .child-item__img a:hover {
    opacity: 1; }
  .child-item-container .child-item__img img {
    width: 100%; }
    @media screen and (max-width: 767px) {
      .child-item-container .child-item__img img {
        width: 60%; } }

.child-item-container .child-item__title {
  font-weight: 300;
  margin: 10px 0;
  line-height: 1.5em; }
  .child-item-container .child-item__title a:hover {
    opacity: 1; }

.child-item-container .child-item__title--white {
  color: #ffffff; }

.child-item-container .child-item__title--orange {
  color: #fb5004; }

.child-item-content .child-item__desc--white {
  color: #ffffff; }

.child-item-content .child-item__desc--orange {
  color: #fb5004; }

.child-item-content .child-item__link-container {
  text-align: left; }
  @media screen and (max-width: 767px) {
    .child-item-content .child-item__link-container {
      text-align: center; } }

.child-item-arrow {
  margin-top: 15px; }
  .child-item-arrow .child-item-arrow__link--white:before {
    content: '';
    width: 40px;
    height: 40px;
    display: block;
    background: url() no-repeat center center;
    background-image: url("./assets/images/common/icons/svg/white/icon-long-arrow.svg");
    background-size: 100%; }
    @media screen and (max-width: 767px) {
      .child-item-arrow .child-item-arrow__link--white:before {
        margin: 0 auto; } }
  .child-item-arrow .child-item-arrow__link--orange:before {
    content: '';
    width: 40px;
    height: 40px;
    display: block;
    background: url() no-repeat center center;
    background-image: url("./assets/images/common/icons/svg/orange/icon-long-arrow.svg");
    background-size: 100%; }
    @media screen and (max-width: 767px) {
      .child-item-arrow .child-item-arrow__link--orange:before {
        margin: 0 auto; } }

.child-more-item {
  text-align: center; }
  .child-more-item .child-more-item-text__link--white {
    color: #ffffff;
    border: 1px solid #ffffff;
    padding: 12px 35px;
    border-radius: 20px; }
  .child-more-item .child-more-item-text__link--orange {
    color: #fb5004;
    border: 1px solid #fb5004;
    padding: 12px 35px;
    border-radius: 20px; }

.item-child-item--col {
  position: relative; }
  .item-child-item--col .child-item-container {
    width: 20%;
    position: absolute;
    margin: 0px auto;
    left: 70%;
    top: -265px; }
    .item-child-item--col .child-item-container:before {
      content: '';
      display: block;
      position: absolute;
      left: -100%;
      top: 140px;
      width: 120%;
      height: 1px;
      background: #efefef;
      transform: rotate(90deg); }
    @media screen and (max-width: 1240px) {
      .item-child-item--col .child-item-container {
        width: 40%;
        position: absolute;
        margin: 0px auto;
        left: 52%;
        top: -280px; }
        .item-child-item--col .child-item-container:before {
          content: none; } }
    @media screen and (max-width: 767px) {
      .item-child-item--col .child-item-container {
        width: 100%;
        position: initial;
        margin-top: 15px; } }
  .item-child-item--col .child-item-content .child-item__link {
    margin-top: 10px; }

.item-child-item--cols .child-items-container {
  width: 75%;
  margin: 35px auto 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  @media screen and (max-width: 1240px) {
    .item-child-item--cols .child-items-container {
      width: 100%; } }
  @media screen and (max-width: 767px) {
    .item-child-item--cols .child-items-container {
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; } }

.item-child-item--cols .child-item-container {
  position: relative;
  margin: 0 15px;
  padding-bottom: 30px;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch; }
  .item-child-item--cols .child-item-container:first-child:nth-last-child(2),
  .item-child-item--cols .child-item-container:first-child:nth-last-child(2) ~ div {
    width: 50%; }
  .item-child-item--cols .child-item-container:first-child:nth-last-child(3),
  .item-child-item--cols .child-item-container:first-child:nth-last-child(3) ~ div {
    width: 33.33333%; }
  .item-child-item--cols .child-item-container:first-child:nth-last-child(4),
  .item-child-item--cols .child-item-container:first-child:nth-last-child(4) ~ div {
    width: 25%; }
  .item-child-item--cols .child-item-container:first-child:nth-last-child(5),
  .item-child-item--cols .child-item-container:first-child:nth-last-child(5) ~ div {
    width: 20%; }
  .item-child-item--cols .child-item-container:first-child:nth-last-child(6),
  .item-child-item--cols .child-item-container:first-child:nth-last-child(6) ~ div {
    width: 16.66667%; }
  .item-child-item--cols .child-item-container:first-child:nth-last-child(7),
  .item-child-item--cols .child-item-container:first-child:nth-last-child(7) ~ div {
    width: 14.28571%; }
  .item-child-item--cols .child-item-container:first-child:nth-last-child(8),
  .item-child-item--cols .child-item-container:first-child:nth-last-child(8) ~ div {
    width: 12.5%; }
  .item-child-item--cols .child-item-container:first-child:nth-last-child(9),
  .item-child-item--cols .child-item-container:first-child:nth-last-child(9) ~ div {
    width: 11.11111%; }
  @media screen and (max-width: 767px) {
    .item-child-item--cols .child-item-container:first-child:nth-last-child(2),
    .item-child-item--cols .child-item-container:first-child:nth-last-child(2) ~ div {
      width: 100%;
      margin-bottom: 10px; }
    .item-child-item--cols .child-item-container:first-child:nth-last-child(3),
    .item-child-item--cols .child-item-container:first-child:nth-last-child(3) ~ div {
      width: 100%;
      margin-bottom: 10px; }
    .item-child-item--cols .child-item-container:first-child:nth-last-child(4),
    .item-child-item--cols .child-item-container:first-child:nth-last-child(4) ~ div {
      width: 100%;
      margin-bottom: 10px; }
    .item-child-item--cols .child-item-container:first-child:nth-last-child(5),
    .item-child-item--cols .child-item-container:first-child:nth-last-child(5) ~ div {
      width: 100%;
      margin-bottom: 10px; }
    .item-child-item--cols .child-item-container:first-child:nth-last-child(6),
    .item-child-item--cols .child-item-container:first-child:nth-last-child(6) ~ div {
      width: 100%;
      margin-bottom: 10px; }
    .item-child-item--cols .child-item-container:first-child:nth-last-child(7),
    .item-child-item--cols .child-item-container:first-child:nth-last-child(7) ~ div {
      width: 100%;
      margin-bottom: 10px; }
    .item-child-item--cols .child-item-container:first-child:nth-last-child(8),
    .item-child-item--cols .child-item-container:first-child:nth-last-child(8) ~ div {
      width: 100%;
      margin-bottom: 10px; }
    .item-child-item--cols .child-item-container:first-child:nth-last-child(9),
    .item-child-item--cols .child-item-container:first-child:nth-last-child(9) ~ div {
      width: 100%;
      margin-bottom: 10px; } }
  .item-child-item--cols .child-item-container .child-item-arrow {
    position: absolute;
    bottom: 0; }
    @media screen and (max-width: 767px) {
      .item-child-item--cols .child-item-container .child-item-arrow {
        left: 46%; } }

/* END Style .page-item-search  */
/* ------------------------------------------ */
/* Style for .page-item-slider */
/* ------------------------------------------ */
.item-sliders {
  position: relative; }
  @media screen and (max-width: 1240px) {
    .item-sliders {
      margin-top: 35px; } }
  @media screen and (max-width: 767px) {
    .item-sliders {
      text-align: initial; } }
  .item-sliders .item-sliders-container {
    position: absolute;
    left: 52%;
    top: -190px;
    bottom: 0;
    width: 40%; }
    @media screen and (max-width: 1240px) {
      .item-sliders .item-sliders-container {
        position: initial;
        left: 0;
        top: 0;
        width: 90%;
        margin: 0 auto; }
        .item-sliders .item-sliders-container .item-slider-container .item-slider-text {
          max-width: initial; } }
    @media screen and (max-width: 480px) {
      .item-sliders .item-sliders-container {
        width: 100%; } }
  .item-sliders .item-slider-container {
    position: relative; }
    .item-sliders .item-slider-container .item-slider__img {
      display: inline-block;
      margin-right: 10px; }
      @media screen and (max-width: 480px) {
        .item-sliders .item-slider-container .item-slider__img {
          margin-right: 0px; } }
    @media screen and (max-width: 480px) {
      .item-sliders .item-slider-container {
        text-align: center; } }
    .item-sliders .item-slider-container .item-slider-text {
      margin-left: 40px;
      display: inline-block;
      max-width: 340px;
      position: absolute;
      top: 55%;
      transform: translate(0%, -50%); }
      .item-sliders .item-slider-container .item-slider-text .item-slider__title--white {
        color: #ffffff; }
      .item-sliders .item-slider-container .item-slider-text .item-slider__desc--white {
        color: #ffffff; }
      .item-sliders .item-slider-container .item-slider-text .item-slider__href--white {
        color: #ffffff; }
      .item-sliders .item-slider-container .item-slider-text .item-slider__title--orange {
        color: #fb5004; }
      .item-sliders .item-slider-container .item-slider-text .item-slider__desc--orange {
        color: #fb5004; }
      .item-sliders .item-slider-container .item-slider-text .item-slider__href--orange {
        color: #fb5004; }
      .item-sliders .item-slider-container .item-slider-text .item-slider__title {
        margin-bottom: 20px;
        font-weight: 300; }
      .item-sliders .item-slider-container .item-slider-text .item-slider__desc {
        margin-bottom: 10px; }
        @media screen and (max-width: 480px) {
          .item-sliders .item-slider-container .item-slider-text .item-slider__desc {
            text-align: left; } }
      .item-sliders .item-slider-container .item-slider-text .item-slider__href {
        border-bottom: 1px solid #fff; }
      @media screen and (max-width: 767px) {
        .item-sliders .item-slider-container .item-slider-text {
          margin-left: 10px; } }
      @media screen and (max-width: 480px) {
        .item-sliders .item-slider-container .item-slider-text {
          margin-top: 10px;
          margin-left: 0px;
          position: initial;
          transform: translate(0%, 0%); } }
  .item-sliders .item-slider__btn {
    width: 36px;
    height: 36px;
    border: 1px solid #fff;
    display: block;
    border-radius: 50%;
    position: absolute;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    top: 60%;
    margin-top: -18px; }
    @media screen and (max-width: 1240px) {
      .item-sliders .item-slider__btn {
        top: 40%; } }
    .item-sliders .item-slider__btn--prev {
      left: -60px;
      transform: rotate(225deg); }
      .item-sliders .item-slider__btn--prev:after {
        content: '';
        position: absolute;
        display: block;
        width: 7.2px;
        height: 7.2px;
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -2.736px;
        margin-left: -5.4px; }
      @media screen and (max-width: 1240px) {
        .item-sliders .item-slider__btn--prev {
          left: -35px; } }
      @media screen and (max-width: 767px) {
        .item-sliders .item-slider__btn--prev {
          display: none; } }
    .item-sliders .item-slider__btn--next {
      right: -50px;
      transform: rotate(45deg); }
      .item-sliders .item-slider__btn--next:after {
        content: '';
        position: absolute;
        display: block;
        width: 7.2px;
        height: 7.2px;
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -2.736px;
        margin-left: -5.4px; }
      @media screen and (max-width: 1240px) {
        .item-sliders .item-slider__btn--next {
          right: 0px; } }
      @media screen and (max-width: 767px) {
        .item-sliders .item-slider__btn--next {
          display: none; } }
  .item-sliders .item-slider__pagination {
    text-align: center;
    margin-top: 75px; }
    .item-sliders .item-slider__pagination .swiper-pagination-bullet {
      cursor: pointer;
      width: 20px;
      height: 3px;
      border: 0;
      border-radius: 0;
      margin: 0 3px;
      background: #fff;
      opacity: 1;
      vertical-align: top; }
      .item-sliders .item-slider__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background: #fb5004;
        height: 4px; }
    @media screen and (max-width: 480px) {
      .item-sliders .item-slider__pagination {
        margin-top: 35px; } }

/* END Style .page-item-slider  */
/* ------------------------------------------ */
/* Style for .page-success-story */
/* ------------------------------------------ */
.sec-page-story {
  max-width: 1920px;
  margin: 0 auto; }

.story-filter {
  background-color: #242655;
  text-align: center; }

.story-filter__title {
  font-weight: 400;
  display: inline-block;
  color: #fff;
  padding: 25px 60px;
  border-right: 1px solid rgba(255, 255, 255, 0.2); }
  @media screen and (max-width: 767px) {
    .story-filter__title {
      display: block; } }

.story-filter-condList {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0; }

.story-filter-cat-container {
  display: inline-block;
  position: relative; }
  @media screen and (max-width: 767px) {
    .story-filter-cat-container {
      display: block; } }
  .story-filter-cat-container .filter-cat__title {
    font-weight: 300;
    color: #fff;
    padding: 25px 120px 25px 40px;
    border-right: 1px solid rgba(255, 255, 255, 0.2); }
    @media screen and (max-width: 767px) {
      .story-filter-cat-container .filter-cat__title {
        font-size: 16px;
        padding: 25px; } }
    .story-filter-cat-container .filter-cat__title:after {
      content: '';
      display: inline-block;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #fff;
      margin-left: 10px; }

.story-list {
  max-width: 1240px;
  margin: 0 auto;
  margin-top: 70px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  .story-list .story-container {
    display: inline-block;
    width: 33.33333%;
    padding: 0 20px;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    line-height: 1.5em; }
    @media screen and (max-width: 767px) {
      .story-list .story-container {
        width: 100%;
        text-align: center; } }
  .story-list .story-img {
    width: 100%; }
  .story-list .story-title {
    margin-top: 15px; }
  .story-list .story-desc {
    width: 85%;
    margin-top: 6px;
    text-align: left; }
    @media screen and (max-width: 767px) {
      .story-list .story-desc {
        margin-left: auto;
        margin-right: auto; } }
  .story-list .story-tags {
    margin-top: 30px;
    margin-bottom: 70px; }
  .story-list .story-tag {
    display: inline-block;
    border: 1px solid #dcdcdc;
    margin-right: 5px;
    padding: 2px 10px; }

/* END Style .page-success-story  */
/* ------------------------------------------ */
/* Style for .page-hktdc-events */
/* ------------------------------------------ */
.page-hktdc-events {
  max-width: 1920px;
  margin: 0 auto; }
  .page-hktdc-events .items-row--cols {
    border-bottom: none; }
    .page-hktdc-events .items-row--cols .item-container {
      padding: 60px 0; }
      .page-hktdc-events .items-row--cols .item-container:last-of-type {
        border-left: 1px solid #fff; }
    .page-hktdc-events .items-row--cols .item-arrow {
      width: 10%;
      cursor: pointer; }
      .page-hktdc-events .items-row--cols .item-arrow a {
        display: block; }

.item-arrow.item-arrow--al-center {
  text-align: center; }
  .item-arrow.item-arrow--al-center a {
    display: inline-block; }

.page-hktdc-events__title {
  font-weight: 300;
  color: #fc5e08;
  text-align: center; }

.page-hktdc-events__filters {
  width: 50%;
  margin: 40px auto 60px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  text-align: center; }
  @media screen and (max-width: 1240px) {
    .page-hktdc-events__filters {
      width: 80%; } }
  @media screen and (max-width: 768px) {
    .page-hktdc-events__filters {
      width: calc(100% - 10px); } }

.page-hktdc-events__filter {
  display: inline-block;
  width: 33.33333%;
  font-weight: 300;
  padding: 25px 0;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  @media screen and (max-width: 566px) {
    .page-hktdc-events__filter {
      padding: 15px 0; }
      .page-hktdc-events__filter:first-child {
        width: calc((100% - 26%)/2); }
      .page-hktdc-events__filter:nth-child(2) {
        width: calc((100% - 26%)/2); }
      .page-hktdc-events__filter:last-child {
        width: 26%; } }
  @media screen and (max-width: 407px) {
    .page-hktdc-events__filter {
      padding: 15px 0; }
      .page-hktdc-events__filter:first-child {
        width: 38%; }
      .page-hktdc-events__filter:nth-child(2) {
        width: 26%; }
      .page-hktdc-events__filter:last-child {
        width: 36%; } }
  .page-hktdc-events__filter.is-active {
    background-color: #fc5e08;
    color: #fff;
    border-top: none;
    border-bottom: none;
    position: relative; }
    .page-hktdc-events__filter.is-active:after {
      content: '';
      display: block;
      position: absolute;
      width: 15px;
      height: 15px;
      background: #fc5e08;
      border-right: 1px solid #fc5e08;
      border-bottom: 1px solid #fc5e08;
      top: 50%;
      left: 50%;
      margin-top: 25px;
      margin-left: -10px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg); }
      @media screen and (max-width: 566px) {
        .page-hktdc-events__filter.is-active:after {
          top: 35px; } }
  .page-hktdc-events__filter:first-child {
    border-left: 1px solid #e5e5e5;
    border-top-left-radius: 45px;
    border-bottom-left-radius: 45px; }
  .page-hktdc-events__filter:last-child {
    border-right: 1px solid #e5e5e5;
    border-top-right-radius: 45px;
    border-bottom-right-radius: 45px; }

/* END Style .page-hktdc-events  */
/* ------------------------------------------ */
/* Style for .page-item-multi-slider */
/* ------------------------------------------ */
.page-hktdc-events-slider {
  position: relative; }
  .page-hktdc-events-slider:before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    right: 0;
    top: 10%;
    height: 90%;
    background: #242655; }
  .page-hktdc-events-slider .item-sliders {
    position: relative;
    width: 70%;
    margin: 0 auto; }
    @media screen and (max-width: 1240px) {
      .page-hktdc-events-slider .item-sliders {
        width: 80%; } }

.multi-sliders-container {
  width: 100%;
  overflow: hidden;
  padding: 0 30px; }
  .multi-sliders-container .item-slider__btn {
    top: 45%; }
  .multi-sliders-container .item-slider__btn--prev {
    left: -30px; }
  .multi-sliders-container .item-slider__btn--next {
    right: -30px; }
  .multi-sliders-container .item-slider__pagination {
    position: absolute; }
    .multi-sliders-container .item-slider__pagination .swiper-pagination-bullet {
      background: #3a3c66; }
      .multi-sliders-container .item-slider__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background: #fff; }

.multi-slider-container {
  margin-bottom: 60px;
  opacity: 0;
  -webkit-transform: translateX(60px);
  -ms-transform: translateX(60px);
  transform: translateX(60px);
  transition: 0.3s; }
  .multi-slider-container.swiper-slide-active,
  .multi-slider-container.swiper-slide-active + .multi-slider-container,
  .multi-slider-container.swiper-slide-active + .multi-slider-container + .multi-slider-container {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  .multi-slider-container.swiper-slide-active + .multi-slider-container {
    transition-delay: 0.2s; }
  .multi-slider-container.swiper-slide-active + .multi-slider-container + .multi-slider-container {
    transition-delay: 0.4s; }
  .multi-slider-container .slider__img a:hover {
    opacity: 1; }
  .multi-slider-container .slider__img img {
    width: 100%; }
  .multi-slider-container .slider__title {
    color: #fff;
    margin-top: 15px;
    width: 75%;
    font-weight: bold;
    font-size: 18px; }
    .multi-slider-container .slider__title a {
      color: #fff; }
      .multi-slider-container .slider__title a:hover {
        opacity: 1; }
  .multi-slider-container .slider__desc {
    color: #fff;
    margin-top: 5px;
    width: 80%;
    font-size: 12px; }
  .multi-slider-container .slider-location-date {
    margin-top: 10px; }
  .multi-slider-container .slider__location {
    display: inline-block;
    color: #fc5e08;
    padding-right: 15px; }
  .multi-slider-container .slider__date {
    display: inline-block;
    color: #fff;
    border-left: 1px solid #fff;
    padding-left: 15px; }

/* END Style .page-item-multi-slider  */
/* ------------------------------------------ */
/* Style for .page-events-list */
/* ------------------------------------------ */
.events-filter {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #ededed;
  text-align: center; }
  @media screen and (max-width: 1062px) {
    .events-filter {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; } }
  .events-filter .events-filter__title {
    display: inline-block;
    font-weight: 400;
    display: inline-block;
    padding: 25px 60px;
    border-right: 1px solid rgba(190, 190, 190, 0.2); }
    @media screen and (max-width: 768px) {
      .events-filter .events-filter__title {
        width: 100%;
        font-size: 24px;
        font-weight: bold;
        padding-bottom: 10px; } }

.events-filter-condList {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0; }

.events-filter-cat-container {
  display: inline-block;
  position: relative; }
  @media screen and (max-width: 768px) {
    .events-filter-cat-container {
      width: 100%; } }
  .events-filter-cat-container .filter-cat__title {
    font-weight: 300;
    padding: 25px 40px 25px 40px;
    border-right: 1px solid rgba(190, 190, 190, 0.2); }
    @media screen and (max-width: 767px) {
      .events-filter-cat-container .filter-cat__title {
        font-size: 16px;
        padding: 10px 25px; } }
    .events-filter-cat-container .filter-cat__title:after {
      content: '';
      display: inline-block;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #bebebe;
      margin-left: 10px; }

.events-search__input-holder {
  position: relative;
  display: inline-block;
  padding: 25px 0 25px 40px;
  max-width: 300px;
  width: 100%; }
  @media screen and (max-width: 1240px) {
    .events-search__input-holder {
      max-width: 240px; } }
  @media screen and (max-width: 768px) {
    .events-search__input-holder {
      max-width: initial;
      width: 80%;
      padding-left: 0;
      padding-top: 10px; } }
  .events-search__input-holder input {
    background-color: #ededed;
    border-top: none;
    border-right: none;
    border-bottom-color: rgba(190, 190, 190, 0.5);
    border-left: none;
    width: 100%;
    padding-right: 20px; }
    .events-search__input-holder input:focus {
      outline: none; }
  .events-search__input-holder input[type="search"]::-webkit-search-decoration,
  .events-search__input-holder input[type="search"]::-webkit-search-cancel-button,
  .events-search__input-holder input[type="search"]::-webkit-search-results-button,
  .events-search__input-holder input[type="search"]::-webkit-search-results-decoration {
    display: none; }
  .events-search__input-holder .events-search__input-btn {
    position: absolute;
    right: 0;
    top: 35%;
    background: transparent;
    border: 0;
    cursor: pointer; }
    .events-search__input-holder .events-search__input-btn:focus {
      outline: none; }
    .events-search__input-holder .events-search__input-btn:before {
      content: '';
      width: 12px;
      height: 12px;
      background: url("./assets/images/common/icons/svg/black/icon-search.svg") no-repeat center center;
      background-size: 100%;
      display: inline-block;
      vertical-align: middle; }

.events-list-title {
  text-align: center;
  font-weight: 300;
  color: #fb5004;
  margin-top: 55px; }

.events-list-conatiner {
  width: 70%;
  margin: 40px auto; }
  @media screen and (max-width: 1240px) {
    .events-list-conatiner {
      width: 90%; } }
  @media screen and (max-width: 768px) {
    .events-list-conatiner {
      width: 95%; } }

.events-list-header {
  border-bottom: 1px solid rgba(190, 190, 190, 0.2); }
  .events-list-header .events-list-header__elem {
    display: inline-block;
    vertical-align: middle;
    padding: 20px 8px; }
    .events-list-header .events-list-header__elem:first-child {
      width: 15%; }
    .events-list-header .events-list-header__elem:nth-child(2) {
      width: 10%;
      padding-left: 12px; }
      @media screen and (max-width: 1240px) {
        .events-list-header .events-list-header__elem:nth-child(2) {
          width: 12%; } }
    .events-list-header .events-list-header__elem:nth-child(3) {
      width: 25%;
      padding-left: 16px; }
      @media screen and (max-width: 1240px) {
        .events-list-header .events-list-header__elem:nth-child(3) {
          width: 18%; } }
    .events-list-header .events-list-header__elem:nth-child(n+4) {
      width: 10%;
      text-align: center; }
      @media screen and (max-width: 1240px) {
        .events-list-header .events-list-header__elem:nth-child(n+4) {
          width: 11%;
          word-wrap: break-word; } }
  @media screen and (max-width: 768px) {
    .events-list-header {
      display: none; } }

.list-event-container {
  border-bottom: 1px solid rgba(190, 190, 190, 0.2); }
  @media screen and (max-width: 768px) {
    .list-event-container {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
      align-content: flex-start;
      -webkit-align-items: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      padding: 30px 0px; } }
  .list-event-container .list-event__elem {
    display: inline-block;
    vertical-align: middle;
    padding: 10px; }
  @media screen and (max-width: 768px) {
    .list-event-container .list-event__desktop-elem {
      display: none; } }
  .list-event-container .list-event__mobile-elem {
    display: none; }
    @media screen and (max-width: 768px) {
      .list-event-container .list-event__mobile-elem {
        display: initial; } }
  .list-event-container .list-event__elem--title {
    color: #fb5004;
    cursor: default; }
    .list-event-container .list-event__elem--title a {
      color: #fb5004; }
  .list-event-container .list-event-left-container {
    display: inline-block;
    width: 15%; }
    .list-event-container .list-event-left-container .list-event__elem--img {
      padding-left: 0;
      width: 100%; }
      @media screen and (max-width: 768px) {
        .list-event-container .list-event-left-container .list-event__elem--img {
          padding-top: 0; } }
      .list-event-container .list-event-left-container .list-event__elem--img img {
        width: 100%; }
    @media screen and (max-width: 768px) {
      .list-event-container .list-event-left-container {
        width: 35%; } }
  .list-event-container .list-event-right-container {
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 85%; }
    @media screen and (max-width: 768px) {
      .list-event-container .list-event-right-container {
        width: 65%;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch; } }
  .list-event-container .list-event-header-container {
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 40%; }
    @media screen and (max-width: 1240px) {
      .list-event-container .list-event-header-container {
        width: 35%; } }
    @media screen and (max-width: 768px) {
      .list-event-container .list-event-header-container {
        -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        width: 100%; } }
    .list-event-container .list-event-header-container .list-event__elem--date {
      width: 30%; }
      @media screen and (max-width: 1240px) {
        .list-event-container .list-event-header-container .list-event__elem--date {
          width: 40%; } }
      @media screen and (max-width: 768px) {
        .list-event-container .list-event-header-container .list-event__elem--date {
          width: 100%; } }
      @media screen and (max-width: 768px) {
        .list-event-container .list-event-header-container .list-event__elem--date .list-event__mobile-elem {
          display: block; } }
    .list-event-container .list-event-header-container .list-event__elem--title {
      width: 70%; }
      @media screen and (max-width: 1240px) {
        .list-event-container .list-event-header-container .list-event__elem--title {
          width: 60%; } }
      @media screen and (max-width: 768px) {
        .list-event-container .list-event-header-container .list-event__elem--title {
          width: 100%;
          font-weight: bold;
          font-size: 18px;
          padding-top: 0;
          padding-bottom: 0; } }
      @media screen and (max-width: 768px) {
        .list-event-container .list-event-header-container .list-event__elem--title .list-event__mobile-elem {
          display: block;
          color: #000; } }
  .list-event-container .list-event-detail-container {
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 60%; }
    @media screen and (max-width: 1240px) {
      .list-event-container .list-event-detail-container {
        width: 65%; } }
    @media screen and (max-width: 768px) {
      .list-event-container .list-event-detail-container {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        width: 100%; } }
    .list-event-container .list-event-detail-container .list-event__elem {
      display: -ms-inline-flexbox;
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
      align-content: flex-start;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      width: 20%; }
      .list-event-container .list-event-detail-container .list-event__elem img {
        margin-right: 5px; }
      @media screen and (max-width: 768px) {
        .list-event-container .list-event-detail-container .list-event__elem {
          width: 50%; }
          .list-event-container .list-event-detail-container .list-event__elem img {
            width: 16px; }
          .list-event-container .list-event-detail-container .list-event__elem .elem__desc {
            width: calc(80% - 5px); } }
      @media screen and (max-width: 560px) {
        .list-event-container .list-event-detail-container .list-event__elem.list-event__elem--Download img {
          width: 11px; } }
      @media screen and (max-width: 560px) {
        .list-event-container .list-event-detail-container .list-event__elem.list-event__elem--exhibit img {
          width: 14px; } }
    @media screen and (max-width: 768px) {
      .list-event-container .list-event-detail-container .list-event__empty-elem {
        display: none; } }

.more-events-list-title {
  text-align: center;
  font-weight: 300;
  color: #fb5004;
  margin-top: 55px; }

.more-events-list-conatiner {
  width: 70%;
  margin: 40px auto; }
  @media screen and (max-width: 1240px) {
    .more-events-list-conatiner {
      width: 90%; } }
  @media screen and (max-width: 768px) {
    .more-events-list-conatiner {
      width: 95%; } }

.more-events-list-header {
  border-bottom: 1px solid rgba(190, 190, 190, 0.2); }
  .more-events-list-header .more-events-list-header__elem {
    display: inline-block;
    vertical-align: middle;
    padding: 20px 10px 20px 0; }
    .more-events-list-header .more-events-list-header__elem:first-child {
      width: 10%; }
    .more-events-list-header .more-events-list-header__elem:nth-child(2) {
      width: 29%; }
    .more-events-list-header .more-events-list-header__elem:nth-child(3) {
      width: 16%; }
    .more-events-list-header .more-events-list-header__elem:nth-child(4) {
      width: 14.5%;
      text-align: left; }
  @media screen and (max-width: 768px) {
    .more-events-list-header {
      display: none; } }

.more-event-list-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(190, 190, 190, 0.2);
  padding: 20px 0; }
  @media screen and (max-width: 768px) {
    .more-event-list-container {
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; } }
  .more-event-list-container .more-event-list__elem {
    display: inline-block;
    vertical-align: middle;
    padding: 10px 15px 10px 0; }
  .more-event-list-container .more-list-event__mobile-elem {
    display: none; }
    @media screen and (max-width: 768px) {
      .more-event-list-container .more-list-event__mobile-elem {
        display: block;
        font-weight: 500; } }
  .more-event-list-container .more-event-list-left-container {
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 85%; }
    @media screen and (max-width: 768px) {
      .more-event-list-container .more-event-list-left-container {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        width: 100%; } }
    .more-event-list-container .more-event-list-left-container .more-event-list__elem--date {
      width: 12%; }
    .more-event-list-container .more-event-list-left-container .more-event-list__elem--title {
      color: #fb5004;
      width: 34%; }
      @media screen and (max-width: 768px) {
        .more-event-list-container .more-event-list-left-container .more-event-list__elem--title .more-list-event__mobile-elem {
          color: #000; } }
    .more-event-list-container .more-event-list-left-container .more-event-list__elem--details {
      width: 19%; }
    .more-event-list-container .more-event-list-left-container .more-event-list__elem--area {
      width: 17%; }
    .more-event-list-container .more-event-list-left-container .more-event-list__elem--organiser {
      width: 18%; }
    @media screen and (max-width: 768px) {
      .more-event-list-container .more-event-list-left-container .more-event-list__elem {
        -webkit-order: 10;
        -ms-flex-order: 1;
        order: 1;
        width: 50%; } }
    @media screen and (max-width: 768px) {
      .more-event-list-container .more-event-list-left-container .more-event-list__elem.more-event-list__elem--title {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        width: 100%; } }
  .more-event-list-container .more-event-list-right-container {
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 15%; }
    @media screen and (max-width: 768px) {
      .more-event-list-container .more-event-list-right-container {
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
        align-content: flex-start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        margin-top: 3%;
        width: 100%; } }
    @media screen and (max-width: 480px) {
      .more-event-list-container .more-event-list-right-container {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; } }
    .more-event-list-container .more-event-list-right-container .more-event-list__elem {
      display: -ms-inline-flexbox;
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
      align-content: flex-start;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      padding-top: 0;
      padding-bottom: 3px; }
      .more-event-list-container .more-event-list-right-container .more-event-list__elem .elem-img {
        margin-right: 5px;
        width: 12%; }
        @media screen and (max-width: 480px) {
          .more-event-list-container .more-event-list-right-container .more-event-list__elem .elem-img {
            width: 20%; } }
      .more-event-list-container .more-event-list-right-container .more-event-list__elem .elem__desc {
        width: calc(88% - 5px); }
        @media screen and (max-width: 480px) {
          .more-event-list-container .more-event-list-right-container .more-event-list__elem .elem__desc {
            width: calc(80% - 5px); } }
      @media screen and (max-width: 768px) {
        .more-event-list-container .more-event-list-right-container .more-event-list__elem {
          width: 33.33333%; } }
      @media screen and (max-width: 480px) {
        .more-event-list-container .more-event-list-right-container .more-event-list__elem {
          width: initial;
          -webkit-align-items: flex-start;
          -ms-flex-align: start;
          align-items: flex-start; } }

/* END Style .page-events-list  */
/* ------------------------------------------ */
/* Style for sample */
/* ------------------------------------------ */
.career-focus {
  padding-bottom: 80px; }
  .career-focus .content-wrapper, .career-focus .app-sec__inner, .career-focus .header__wrapper, .career-focus .search-nores, .career-focus .sec-intro__detail-wrapper, .career-focus .submenu__wrapper {
    justify-content: center;
    align-items: center; }
  .career-focus .market-focus__item {
    display: block;
    opacity: 1 !important;
    width: calc(30% - 10px);
    margin-right: 10px;
    border-radius: 0px; }
    .career-focus .market-focus__item .market-focus__info {
      color: white; }
      .career-focus .market-focus__item .market-focus__info.top {
        top: 0;
        bottom: auto;
        padding-top: 60px; }
        .career-focus .market-focus__item .market-focus__info.top h3 {
          font-size: 32px; }
    .career-focus .market-focus__item:before {
      padding-top: 0px !important; }
  @media screen and (max-width: 767px) {
    .career-focus {
      padding: 0; }
      .career-focus .content-wrapper, .career-focus .app-sec__inner, .career-focus .header__wrapper, .career-focus .search-nores, .career-focus .sec-intro__detail-wrapper, .career-focus .submenu__wrapper {
        margin: 0;
        width: 100%; }
        .career-focus .content-wrapper .market-focus__item, .career-focus .app-sec__inner .market-focus__item, .career-focus .header__wrapper .market-focus__item, .career-focus .search-nores .market-focus__item, .career-focus .sec-intro__detail-wrapper .market-focus__item, .career-focus .submenu__wrapper .market-focus__item {
          width: 100%;
          margin: auto; }
          .career-focus .content-wrapper .market-focus__item:before, .career-focus .app-sec__inner .market-focus__item:before, .career-focus .header__wrapper .market-focus__item:before, .career-focus .search-nores .market-focus__item:before, .career-focus .sec-intro__detail-wrapper .market-focus__item:before, .career-focus .submenu__wrapper .market-focus__item:before {
            padding-bottom: 50%; } }

/* END Style sample  */
/* ------------------------------------------ */
.gn-split-container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  /* Adjust height as needed */
  color: white; }
  @media (max-width: 767px) {
    .gn-split-container {
      flex-direction: column;
      height: auto; } }
  .gn-split-container > div:not(:last-child) {
    border-bottom: 1px solid #ccc;
    padding-bottom: 50px; }
  @media (min-width: 768px) {
    .gn-split-container > div {
      padding: 0 50px; }
    .gn-split-container > div:not(:last-child) {
      border-bottom: none;
      border-right: 1px solid #ccc; } }

.gn-left-section {
  flex: 1;
  height: 100%; }

.gn-right-section {
  flex: 1;
  height: 100%; }

.gn-divider {
  width: 2px;
  /* Adjust thickness of line */
  background-color: white;
  margin: 0 20px;
  /* Adjust spacing around line */ }

.globalNetworkWrapper {
  text-align: left; }
  .globalNetworkWrapper.globalNetworkWrapperBottom {
    background: none; }
    .globalNetworkWrapper.globalNetworkWrapperBottom .globalNetworkTitle {
      color: #242753; }
    .globalNetworkWrapper.globalNetworkWrapperBottom .committeDesc,
    .globalNetworkWrapper.globalNetworkWrapperBottom a,
    .globalNetworkWrapper.globalNetworkWrapperBottom .committeLink {
      color: #1a1a1a; }
    .globalNetworkWrapper.globalNetworkWrapperBottom .gn-moreLinks {
      border: 1px solid #e3e3e3;
      border-left: none;
      border-right: none;
      margin: 30px 0;
      padding: 20px 0; }
      .globalNetworkWrapper.globalNetworkWrapperBottom .gn-moreLinks a.gn-button {
        border-color: #fff;
        font-weight: bolder;
        color: #1a1a1a; }
        .globalNetworkWrapper.globalNetworkWrapperBottom .gn-moreLinks a.gn-button:hover {
          background-color: transparent; }
  @media (min-width: 768px) {
    .globalNetworkWrapper .globalNetworkTitle {
      padding: 0 50px; } }
  .globalNetworkWrapper .wrapper {
    text-align: center;
    max-width: 100%;
    padding-top: 50px;
    padding-bottom: 50px; }
    .globalNetworkWrapper .wrapper .gn-right-section .committeDesc {
      margin: 50px 0 15px; }
      @media (min-width: 768px) {
        .globalNetworkWrapper .wrapper .gn-right-section .committeDesc:first-child {
          margin-top: 30px; } }
      .globalNetworkWrapper .wrapper .gn-right-section .committeDesc img {
        vertical-align: middle; }
  .globalNetworkWrapper .desktopFilter {
    display: flex;
    color: white;
    list-style: none;
    justify-content: center;
    margin: 40px 0 20px;
    flex-wrap: wrap;
    align-items: stretch; }
    .globalNetworkWrapper .desktopFilter li {
      margin: 5px 15px; }
    @media (max-width: 767px) {
      .globalNetworkWrapper .desktopFilter {
        display: none; } }
  @media (max-width: 767px) {
    .globalNetworkWrapper .filter.mobileFilter {
      margin: 40px 0 0; } }
  .globalNetworkWrapper .gn-divider {
    margin: 0 50px; }
  .globalNetworkWrapper div.committeLinks {
    margin-top: 20px;
    justify-content: flex-start; }

.gn-button {
  color: #fb5004;
  background-color: initial;
  padding: 7px 40px;
  border: 1px solid #fb5004;
  border-radius: 23px;
  outline: none;
  margin-right: 20px;
  display: inline-block; }
  .gn-button:hover {
    color: #fff;
    background-color: #fb5004; }

p.globalNetworkSubtitle {
  font-size: 100%;
  margin: 30px 0; }
  p.globalNetworkSubtitle img {
    vertical-align: middle; }

.pin-image {
  vertical-align: middle;
  width: 18px;
  margin-right: 15px;
  margin-top: -5px; }

.descWithPin {
  display: flex;
  align-items: flex-start;
  color: #1a1a1a;
  justify-content: center; }

/* remove google map */
.filter.desktopFilter,
.filter.mobileFilter,
#globalNetworkMap {
  display: none; }

/* ------------------------------------------ */
/* Override Style   */
/* ------------------------------------------ */
/* Style for Smart banner*/
.smartbanner-show {
  margin-top: 0; }

.smartbanner-icon {
  background-image: url("./assets/images/common/icon-mobile-app-1.png") !important; }

.smartbanner {
  position: relative; }

/* Style for Executive Trainee Scheme Page */
.etsPageContent .static-page-intro-container,
.etsPageContent .static-page-body-container,
.etsPageContent .static-page-footer-container {
  margin-bottom: 20px; }

.etsPageContent .row {
  margin-top: 10px;
  width: 100%; }

.etsPageContent .content-wrapper, .etsPageContent .app-sec__inner, .etsPageContent .header__wrapper, .etsPageContent .search-nores, .etsPageContent .sec-intro__detail-wrapper, .etsPageContent .submenu__wrapper {
  max-width: 1024px;
  padding: 15px 0px; }

.etsPageContent .app__main {
  padding-top: 110px; }

.etsPageContent .static-page-intro-container {
  background: #242655;
  color: #fff; }

.subportal-footer {
  margin-top: 40px; }

/* ------------------------------------------ */
/* END Override Style  */
/* ------------------------------------------ */
